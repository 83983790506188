import React from "react";

import { useTooltip } from "@shared/hooks/useTooltip";

import { Inline } from "@fermions";

import { Icon, IconFillStyle, IconSize } from "@atoms/Icon";

import OTTooltip from "@components/atoms/OTTooltip";

import "./IconWithText.scss";

interface IconWithTextProps {
  text?: string;
  iconOnly?: boolean;
  showHover?: boolean;
  hoverText?: string;
  hoverTextPrefix?: string;
  iconName?: string;
  filled?: boolean;
  color?: "text" | "text-sub" | "text-sub-sub" | "text-primary";
  size?: "s" | "m";
  className?: string;
}

export const IconWithText = ({
  text = "",
  iconOnly = false,
  showHover = true,
  hoverText = text,
  hoverTextPrefix = "",
  iconName = "",
  filled = false,
  size = "s",
  color = "text-sub-sub",
  className = ""
}: IconWithTextProps) => {
  const { showTooltip, elementProps } = useTooltip();
  return (
    <Inline
      className={[
        className,
        "icon-with-text",
        `icon-with-text--size-${size}`,
        `icon-with-text--color-${color}`
      ]}
      {...(showHover ? elementProps : {})}
    >
      {iconName && (
        <Icon
          name={iconName}
          size={IconSize.S}
          fillStyle={filled ? IconFillStyle.FILLED : IconFillStyle.OUTLINED}
        />
      )}
      {!iconOnly && text}
      {showTooltip && (
        <OTTooltip
          content={`${
            hoverTextPrefix ? `${hoverTextPrefix}: ` : ""
          }${hoverText}`}
          visible={showTooltip}
        />
      )}
    </Inline>
  );
};

import { useMemo } from "react";

import { systemConstants } from "@shared/constants";
import { milestoneUtilities } from "@shared/helpers/milestoneUtilities";
import { useGetFeaturesQuery } from "@shared/services/featuresService";

export const useProjectFormConfig = ({
  engagementTypeConfig,
  t,
  selectedProjectTemplate
}) => {
  const { data: features } = useGetFeaturesQuery();

  const projectYearEnabled = useMemo(
    () => !!engagementTypeConfig?.project?.yearField?.enabled,
    [engagementTypeConfig?.project?.yearField?.enabled]
  );

  const projectExternalIdEnabled = useMemo(
    () => !!engagementTypeConfig?.project?.visibleExternalIdField?.enabled,
    [engagementTypeConfig?.project?.visibleExternalIdField?.enabled]
  );

  const entitiesEnabled = useMemo(
    () => !!engagementTypeConfig?.entities?.enabled,
    [engagementTypeConfig?.entities?.enabled]
  );

  const externalEntitiesEnabled = useMemo(
    () => !!features?.[systemConstants.features.externalEntities],
    [features]
  );

  const projectManagerEnabled = useMemo(
    () => !!features?.[systemConstants.features.projectManagerField],
    [features]
  );

  const startDateLabel = useMemo(
    () => milestoneUtilities.renderStartLabel(engagementTypeConfig, t) ?? "",
    [engagementTypeConfig, t]
  );

  const endDateLabel = useMemo(
    () => milestoneUtilities.renderEndLabel(engagementTypeConfig, t) ?? "",
    [engagementTypeConfig, t]
  );

  const projectQueriesEnabled = useMemo(
    () =>
      !!selectedProjectTemplate?.id &&
      !!selectedProjectTemplate.queries?.length,
    [selectedProjectTemplate]
  );

  return {
    entitiesEnabled,
    externalEntitiesEnabled,
    projectManagerEnabled,
    projectYearEnabled,
    projectExternalIdEnabled,
    startDateLabel,
    endDateLabel,
    projectQueriesEnabled
  };
};

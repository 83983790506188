import { User } from ".";

export enum QueryStatus {
  DRAFT = "DRAFT",
  OPEN = "OPEN",
  RESPONDED = "RESPONDED",
  CLOSED = "CLOSED",
  ERROR = "ERROR",
  // OTHER
  NOTE = "NOTE",
  ATTACHMENT = "ATTACHMENT"
}

export enum QueryAction {
  CREATE = "CREATE",
  // Available through config
  VIEW = "VIEW",
  EDIT = "EDIT",
  DELETE = "DELETE",
  REOPEN = "REOPEN",
  CLOSE = "CLOSE",
  REASSIGN = "REASSIGN",
  ADD_RESPONSE = "ADD_RESPONSE",
  CLOSE_REQUEST = "CLOSE_REQUEST",
  CLOSE_PAGE = "CLOSE_PAGE",
  DOWNLOAD = "DOWNLOAD",
  SEND_TO_HOST = "SEND_TO_HOST",
  TRANSITION = "TRANSITION",
  APPROVE = "APPROVE",
  REJECT = "REJECT",
  REVIEW = "REVIEW"
}

export enum Perspective {
  MY_REQUESTS = "myRequests",
  CLIENT_REQUESTS = "clientRequests",
  DELIVERY_TEAM_REQUESTS = "deliveryTeamRequests"
}

export enum DueDate {
  OVERDUE = "overdue",
  UPCOMING = "upcoming"
}

export interface DocumentRevision {
  id: number;
  name: string;
  revision: number;
  documentId: number;
  current: boolean;
  filePath: string;
}

export interface QueryDocuments {
  createdAt: string;
  updatedAt: string;
  documentId: number;
  queryId: number;
}

export interface QueryAttachment {
  id: number;
  name: string;
  projectId: number;
  projectFolderId?: number;
  clientId?: number;
  status: string;
  externalId?: number;
  properties: {
    type: string;
    queryId: number;
    queryType: string;
    questionId?: number;
    actionItemTypeKey: string;
  };
  createdAt: string;
  updatedAt: string;
  queryDocuments: QueryDocuments;
  documentRevisions: DocumentRevision[];
}

export interface QueryAnswer {
  value: unknown;
  entities: string[];
  updatedBy: string;
  complete?: boolean;
}

export type QueryAnswers = { [questionId: number]: QueryAnswer[] };

export interface QueryTag {
  id: number;
  name: string;
  projectId: number;
  engagementTypeId?: number;
  clientId?: number;
}

export interface QueryDocument {
  id: number;
  name: string;
  projectId: number;
  projectFolderId?: number;
  clientId?: number;
  status: string;
  externalId?: number;
  properties?: {
    entities?: number[];
  };
  createdAt: string;
  updatedAt: string;
  queryDocuments: QueryDocuments;
  documentRevisions: DocumentRevision[];
  documentRevisionId: number;
}

export enum QueryIndicator {
  NOTE = "note",
  OPEN = "open",
  CLOSED = "closed",
  OVERDUE = "overdue",
  UPCOMING = "upcoming",
  RESPONDED = "responded",
  ATTACHMENT = "attachment"
}

export type QueryIndicatorConfig = {
  [key in QueryIndicator]: {
    key: string;
    label: string;
    shape?: string;
    colorScheme: string;
  };
};

export interface Query {
  id: number;
  refNo: number;
  projectId: number;
  project: {
    id: number;
    name: string;
    startDate: string;
    plannedCompletionDate: string;
  };
  description: string;
  requiredBy: string;
  status: QueryStatus;
  queryType: string;
  assignedTo: User;
  copiedTo: User[];
  requestedBy: User;
  hostUserId: number;
  attachments?: QueryAttachment[];
  documentQueryRequestDocumentRevision?: unknown;
  document?: QueryDocument;
  createdAt: string;
  tags?: QueryTag[];
  entities?: string[];
  milestone: number;
  configType: "CONVERSATION" | "SMARTFORM" | "WEBSHEET"; // used for icon
  reminderDate: string;
  isUserOnRequestorTeam: boolean;
  statusDisplay: string;
  // smartform
  answers?: QueryAnswers;
  deletedAnswers?: QueryAnswers;
  lockedQuestions?: { [questionId: number]: boolean };
}

export type QueryActionHandlers = {
  [key in QueryAction]: (query?: Query) => void;
};

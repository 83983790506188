import React, { useCallback, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants";

import { roleNameDisplay } from "@shared/helpers/roleHelper";
import { useAuthUser, useFeatures, useGetClientByIdQuery } from "@shared/hooks";
import { useClientUserProfile } from "@shared/hooks/useClientUserProfile";
import { useToasts } from "@shared/hooks/useToasts";
import { useGetUserByIdQuery, useUpdateUserMutation } from "@shared/services";

import { getErrorMessage } from "@app/helpers/error";

import UserForm from "@components/organisms/UserForm/UserForm";

const UpdateClientUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { clientId, userId } = useParams();
  const { data: client, isError: clientError } =
    useGetClientByIdQuery(clientId);
  const { data: clientUser, isError: userError } = useGetUserByIdQuery(userId);

  const { user } = useAuthUser();

  const {
    roles,
    projectsList,
    clientUserFields,
    error: roleError
  } = useClientUserProfile({
    client,
    updateUserId: userId
  });

  const [
    updateClientUser,
    {
      data: updatedUserData,
      isSuccess: isClientUserUpdated,
      isLoading,
      error: updateError
    }
  ] = useUpdateUserMutation();

  const { showExternalUserDeactivatedToast } = useToasts();

  const error = useMemo(
    () => updateError || roleError,
    [updateError, roleError]
  );

  const updateUser = useCallback(
    profile => {
      updateClientUser({ user: profile });
    },
    [updateClientUser]
  );

  const { isExternalClientUsersEnabled } = useFeatures();

  useEffect(() => {
    if (!clientId || userError || clientError) {
      navigate(routeConstants.notFound);
    }
  }, [clientError, clientId, navigate, userError]);

  useEffect(() => {
    if (isClientUserUpdated) {
      if (updatedUserData?.toast) {
        const message = t(updatedUserData.toast, {
          firstName: clientUser.firstname,
          lastName: clientUser.lastname
        });
        showExternalUserDeactivatedToast(message);
      } else {
        navigate(`/admin/clients/${client.id}/users`);
      }
    }
  }, [client?.id, isClientUserUpdated, navigate, updatedUserData?.toast]);

  const breadcrumbs = () => {
    const result = [];
    if (user.isHostUser) {
      result.push({
        linkTo: routeConstants.manageClients,
        label: t("common:ui.manageClients.title")
      });
    }
    result.push({
      linkTo: `/admin/clients/${client?.id}/users`,
      label: !user.isHostUser
        ? t("common:ui.manageUsers.title", { context: "CLIENT" })
        : client &&
          t("common:ui.manageUsers.titleWithClientName", {
            clientName: client.name,
            context: "CLIENT"
          })
    });

    return result;
  };

  const addConfigKey = key =>
    clientUserFields?.find(field => field.key === key) ?? {};

  const addRole = () => {
    if (roles?.length > 1) {
      return {
        key: "role",
        path: "role.name",
        type: "dropdown",
        required: true,
        translate: true,
        items: roles?.map(role => ({
          ...role,
          name: roleNameDisplay({ role, t }),
          value: role.name
        }))
      };
    }
    return {};
  };

  const formConfig = [
    {
      key: "firstname",
      type: "text",
      required: true,
      editable: true
    },
    {
      key: "lastname",
      type: "text",
      required: true,
      editable: true
    },
    {
      key: "email",
      type: "text",
      required: true,
      editable: true
    },
    addConfigKey("jobTitle"),
    addRole(),
    {
      key: "projects",
      type: "multiselect",
      required: false,
      editable: true,
      items: projectsList,
      defaultValue: projectsList?.filter(p => p.added),
      placeholder: t("common:user.configured.fields.projects.recommended")
    },
    ...(isExternalClientUsersEnabled
      ? [addConfigKey("EXTERNAL_USERNAME")]
      : []),
    addConfigKey("MOBILE_NUMBER"),
    addConfigKey("BUSINESS_NUMBER"),
    {
      key: "NOTIFICATION",
      type: "radio",
      defaultValue: "digest",
      items: ["digest", "each"],
      path: "properties.notification.email"
    }
  ];
  return (
    <UserForm
      title={t("common:ui.manageUsers.updateTitle_client")}
      breadcrumbs={breadcrumbs()}
      handleSubmit={updateUser}
      user={clientUser}
      isHostUserUpdated={isClientUserUpdated}
      isExternalUsersEnabled={isExternalClientUsersEnabled}
      formConfig={formConfig}
      submitLabel={t("common:ui.manageUsers.updateSubmit_CLIENT")}
      loading={isLoading}
      other={{
        error: error ? getErrorMessage(error, t) : ""
      }}
    />
  );
};

export default UpdateClientUser;

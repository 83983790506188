import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { routeConstants } from "@constants/routeConstants";

import { systemConstants } from "@shared/constants/systemConstants";
import {
  useAuthUser,
  useGetClientByIdQuery,
  useLazyGetClientMainEntitiesQuery,
  useUpdateClientMutation
} from "@shared/hooks";
import { useFeatures } from "@shared/hooks";

import { getErrorMessage } from "@app/helpers/error";
import { useGoBack } from "@app/hooks";

import { Button, ButtonVariant } from "@atoms/Button";

import { ClientForm } from "@organisms/ClientForm";

import FormPageTemplate from "@components/templates/FormPageTemplate";

export const UpdateClient = () => {
  const { clientId } = useParams();
  const goBack = useGoBack();
  const { data: client, isLoading } = useGetClientByIdQuery(clientId, {
    skip: !clientId
  });
  const { isEnabled } = useFeatures();
  const [
    updateClient,
    { isSuccess, isLoading: isSubmitting, error: updateClientError }
  ] = useUpdateClientMutation();

  const externalEntitiesEnabled = useMemo(
    () => isEnabled(systemConstants.features.externalEntities),
    [isEnabled]
  );

  const { user } = useAuthUser();
  const [error, setError] = useState("");
  const isHostUser = useMemo(() => user?.isHostUser, [user]);

  const [
    fetchClientEntities,
    {
      data: clientEntities,
      isSuccess: fetchClientEntitiesSuccess,
      error: fetchClientEntitiesError
    }
  ] = useLazyGetClientMainEntitiesQuery();

  const defaultValue = useMemo(() => {
    if (!client) {
      return;
    }
    const clientCopy = structuredClone(client);
    return clientCopy;
  }, [client]);

  const [clientLogoModal, setClientLogoModal] = useState(<></>);
  const [logo, setLogo] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    if (externalEntitiesEnabled && isHostUser && client) {
      fetchClientEntities({ origin: client?.id });
    }
  }, [externalEntitiesEnabled, isHostUser, fetchClientEntities, client]);

  useEffect(() => {
    if (fetchClientEntitiesError) {
      setError(getErrorMessage(fetchClientEntitiesError, t));
    }
  }, [fetchClientEntitiesError, t]);

  useEffect(() => {
    if (updateClientError) {
      setError(getErrorMessage(updateClientError, t));
    }
  }, [t, updateClientError]);

  useEffect(() => {
    if (fetchClientEntitiesSuccess && !clientEntities.length) {
      setError("No client entities found");
    }
  }),
    [fetchClientEntitiesSuccess, clientEntities];

  const handleUpdate = useCallback(
    data => {
      const updatedClient = (() => {
        if (!externalEntitiesEnabled) {
          return {
            id: client.id,
            name: data.name?.trim(),
            acn: data.acn?.trim(),
            abn: data.abn?.trim(),
            logo
          };
        } else {
          return {
            id: client.id,
            name: data.name?.value?.trim(),
            externalId: data.name?.id,
            acn: data.acn?.trim(),
            abn: data.abn?.trim(),
            logo
          };
        }
      })();

      updateClient({ client: updatedClient })
        .unwrap()
        .then(() => {
          goBack();
        });
    },
    [client, goBack, logo, externalEntitiesEnabled, updateClient]
  );

  const yupSchema = useMemo(() => {
    if (!externalEntitiesEnabled) {
      return yup.object({
        name: yup.string().required(t("common:ui.client.field.nameError"))
      });
    }
    return yup.object({
      name: yup.object().shape({
        name: yup.string().required(t("common:ui.client.field.nameError")),
        value: yup.string().required(),
        id: yup.number().required()
      })
    });
  }, [externalEntitiesEnabled, t]);

  const formContents = useMemo(
    () => (
      <ClientForm
        client={defaultValue}
        setClientLogoModal={setClientLogoModal}
        logo={logo}
        setLogo={setLogo}
        clientEntities={clientEntities}
        externalEntitiesEnabled={externalEntitiesEnabled}
      />
    ),
    [defaultValue, externalEntitiesEnabled, clientEntities, logo]
  );

  const renderUpdateClientActions = isValid => {
    return (
      <>
        <Button
          label={t("common:ui.forms.cancel.label")}
          variant={ButtonVariant.TEXT}
          onClick={goBack}
        />
        <Button
          disabled={!isValid}
          label={t("common:ui.updateClient.submitLabel")}
          type="submit"
        />
      </>
    );
  };

  return (
    <>
      <FormPageTemplate
        title={t("common:ui.updateClient.title")}
        sticky
        form={{
          contents: formContents,
          yupSchema,
          handleSubmit: handleUpdate,
          handleCancel: goBack,
          disabled: isSubmitting || isSuccess,
          renderPageActions: renderUpdateClientActions
        }}
        isLoading={isLoading}
        renderRawForm={true}
        breadcrumbs={[
          {
            linkTo: routeConstants.manageClients,
            label: t("common:ui.manageClients.title")
          }
        ]}
        other={{
          error: error ? getErrorMessage(error, t) : ""
        }}
      />
      {clientLogoModal}
    </>
  );
};

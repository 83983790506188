import React, { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { formatDateOnly } from "@shared/helpers/dateFormatUtils";

import {
  smartFormActions,
  smartFormResponseType
} from "@app/helpers/smartForm";
import {
  getLabelAndIconForOpenModalButton,
  openSmartFormWebSheet
} from "@app/helpers/smartForm/smartFormHelper";
import { SmartFormQuestionType } from "@app/helpers/smartForm/smartFormTypes";

import { Attachment } from "@atoms/Attachment";
import { Button, ButtonSize, ButtonVariant } from "@atoms/Button";

import DatePicker from "@components/atoms/DatePicker";
import SmartFormRadioButtons from "@components/molecules/SmartFormRadioButtons";
import SmartFormSingleChoice from "@components/molecules/SmartFormSingleChoice";
import SmartFormTextArea from "@components/molecules/SmartFormTextArea";

interface SmartFormResponseProps {
  responseType: string;
  question: SmartFormQuestionType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  globalAnswer?: any;
  disableAnswerSubmissions: boolean;
  handlers: { [key: string]: () => void };
  relevantEntitiesTotal: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relevantEntitiesById: { [key: string]: any };
}

export const SmartFormResponse = ({
  responseType,
  question,
  globalAnswer,
  disableAnswerSubmissions,
  handlers,
  relevantEntitiesTotal,
  relevantEntitiesById
}: SmartFormResponseProps) => {
  const { t } = useTranslation();

  const getEntities = useMemo(
    () => () =>
      Object.entries(relevantEntitiesById)
        .filter(([id]) => question?.relevantEntities?.includes(id))
        .map(([id]) => id),
    [question?.relevantEntities, relevantEntitiesById]
  );

  const renderDocumentResponse = useCallback(() => {
    const answerValue = question.answers[0].value;
    return (
      <Attachment
        type={responseType}
        file={answerValue}
        disabled={question.answers[0]?.locked}
        onClick={openSmartFormWebSheet({
          projectId: answerValue.projectId,
          queryId: answerValue.properties?.queryId,
          id: answerValue.id
        })}
      />
    );
  }, [question.answers, responseType]);

  const answerSuppliedHandler = useCallback(
    (questionId, value) => {
      handlers[smartFormActions.SUPPLY_ANSWER]?.(
        questionId,
        value,
        getEntities()
      );
    },
    [getEntities, handlers]
  );

  if (
    responseType === smartFormResponseType.TEXT ||
    responseType === smartFormResponseType.NUMBER
  ) {
    return (
      <SmartFormTextArea
        maxLength={question.maxLength}
        isLineBreaksAllowed={question.lineBreaks}
        onAnswerSupplied={answerSuppliedHandler}
        value={globalAnswer}
        questionId={question.questionId}
        disabled={!question?.isAnsweredGlobally || disableAnswerSubmissions}
        responseType={responseType}
        min={question.min}
        max={question.max}
        enableGlobalOpenState={true}
      />
    );
  } else if (responseType === smartFormResponseType.SINGLE_CHOICE) {
    return (
      <SmartFormSingleChoice
        options={question.options}
        layout={"vertical"}
        value={globalAnswer}
        handleOnChange={value => {
          handlers[smartFormActions.SUPPLY_ANSWER]?.(
            question.questionId,
            value,
            getEntities()
          );
        }}
        questionId={question.questionId}
        disabled={!question?.isAnsweredGlobally || disableAnswerSubmissions}
      />
    );
  } else if (responseType === smartFormResponseType.BOOLEAN) {
    const booleanOptions = [
      {
        name: `${t(
          "requests:requests.ui.smartForm.responseType.boolean.yes.label"
        )}`,
        value: true
      },
      {
        name: `${t(
          "requests:requests.ui.smartForm.responseType.boolean.no.label"
        )}`,
        value: false
      }
    ];

    return (
      <SmartFormRadioButtons
        onChange={answerSuppliedHandler}
        value={globalAnswer}
        allowUnselect={false}
        options={booleanOptions}
        questionId={question.questionId}
        disabled={!question?.isAnsweredGlobally || disableAnswerSubmissions}
      />
    );
  } else if (responseType === smartFormResponseType.DATE) {
    return (
      <DatePicker
        id={`${question.questionId}`}
        value={globalAnswer ? formatDateOnly(globalAnswer) : null}
        showPlaceholder
        enableGlobalOpenState={true}
        // Setting min/max dates to the earliest/latest possible dates to prevent the user from selecting a date outside of the range
        minDate={new Date("1000-01-01")}
        maxDate={new Date("9999-12-31")}
        // Debouncing prevents the onChange from triggering whilst the user is typing as doing so triggers a re-render causing the input to lose focus whilst typing
        useDebouncedOnChange={true}
        onChange={value => {
          handlers[smartFormActions.SUPPLY_ANSWER]?.(
            question.questionId,
            formatDateOnly(value),
            getEntities()
          );
        }}
        disabled={!question?.isAnsweredGlobally || disableAnswerSubmissions}
      />
    );
  } else if (
    [smartFormResponseType.DOCUMENT, smartFormResponseType.WEBSHEET].includes(
      responseType
    )
  ) {
    if (relevantEntitiesTotal <= 1) {
      const { label, icon } = getLabelAndIconForOpenModalButton(
        responseType,
        question?.answers?.length,
        disableAnswerSubmissions,
        t
      );
      return (
        <div className="smart-form-table__action-button">
          <Button
            iconName={icon}
            iconOutlined={true}
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.SMALL}
            skipTextTransform
            label={label}
            onClick={() =>
              handlers[smartFormActions.OPEN_ENTITIES_MODAL](question)
            }
            disabled={
              question?.answers?.length === 0 && disableAnswerSubmissions
            }
          />
        </div>
      );
    }
    if (
      responseType === smartFormResponseType.WEBSHEET &&
      // Has 1 answer and answered all entities in that 1 answer
      question.answers.length === 1 &&
      question.assignedEntities?.length === relevantEntitiesTotal
    ) {
      return renderDocumentResponse();
    }
  }
  return <></>;
};

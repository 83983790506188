import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const clientService = createApi({
  reducerPath: "clientServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: [
    "Client",
    "ClientForMenu",
    "ClientEntities",
    "ClientForExternalEntities"
  ],
  endpoints: builder => ({
    getClientById: builder.query({
      query: clientId => `/api/clients/${clientId}`,
      providesTags: ["Client"]
    }),
    getClients: builder.query({
      query: () => "/api/clients",
      providesTags: ["Client"]
    }),
    addClient: builder.mutation({
      query: ({ client }) => ({
        url: "/api/clients",
        method: "POST",
        body: client
      }),
      invalidatesTags: ["Client", "ClientForMenu", "ClientForExternalEntities"]
    }),
    updateClient: builder.mutation({
      query: ({ client }) => ({
        url: `/api/clients/${client.id}`,
        method: "PUT",
        body: client
      }),
      invalidatesTags: ["Client", "ClientForMenu", "ClientForExternalEntities"]
    }),
    getClientsForMenu: builder.query({
      query: () => `/api/clients?for=menu`,
      providesTags: ["ClientForMenu"]
    }),
    getClientMainEntities: builder.query({
      query: ({ origin }) => `/api/clients?for=entities:${origin}`,
      providesTags: ["ClientEntities"]
    }),
    getEntities: builder.query({
      query: clientId => ({
        url: `/api/clients/${clientId}/entities`
      }),
      providesTags: ["ClientForExternalEntities"]
    })
  })
});

export const {
  useGetClientByIdQuery,
  useGetClientsQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useGetClientsForMenuQuery,
  useLazyGetClientMainEntitiesQuery,
  useGetEntitiesQuery
} = clientService;

import React, { useCallback, useEffect, useMemo } from "react";

import { groupBy } from "lodash";
import { useFormContext } from "react-hook-form";

import {
  AITFormatConfig,
  ActionItemType,
  CrudFormMode
} from "@app/types/ActionItemType.ts";

import RequestPermissionsMatrix from "@organisms/RequestPermissionMatrix/RequestPermissionMatrix.tsx";

import { normaliseAvailableTo } from "./actionItemType.tsx";

interface ActionItemTypeFormPermissions {
  actionItemType?: ActionItemType;
  formatConfig?: AITFormatConfig;
  crudFormMode: CrudFormMode;
}

const AVAILABLE_TO_ALL = "ALL";

const ActionItemTypeFormPermissions = (
  props: ActionItemTypeFormPermissions
) => {
  const {
    actionItemType,
    formatConfig,
    crudFormMode = CrudFormMode.READ
  } = props;

  const { setValue, getValues, unregister } = useFormContext();

  const availableToOptions = useMemo(() => {
    return formatConfig?.availableTo ?? [];
  }, [formatConfig]);

  const permissions = useMemo(() => {
    if (!formatConfig?.permissions) {
      return null;
    }
    const internalOnly = actionItemType?.configuration.internalOnly;
    const groupedPermissionsConfig = groupBy(formatConfig?.permissions, "type");

    const createPermissionConfig = groupedPermissionsConfig["create"][0];
    const createPermission = {
      ...createPermissionConfig,
      availableTo: internalOnly ? ["HOST"] : createPermissionConfig.availableTo,
      defaultSelection: normaliseAvailableTo(
        createPermissionConfig.defaultSelection
      ),
      selection: normaliseAvailableTo([actionItemType?.configuration.creators])
    };

    const actionMenu = actionItemType?.configuration.menus.find(
      m => m.name === "ACTIONS"
    );

    const actionPermissions = groupedPermissionsConfig["action"]
      .filter(p => p.key !== "EDIT")
      .map(actionPermission => {
        const isView = actionPermission.key === "VIEW";
        const selectedAvailableTo = normaliseAvailableTo([
          actionMenu?.actions?.find(a => a.action === actionPermission.key)
            ?.availableTo
        ]);
        const defaultSelection = normaliseAvailableTo(
          actionPermission.defaultSelection
        );

        return {
          key: actionPermission.key,
          type: actionPermission.type,
          availableTo: internalOnly ? ["HOST"] : actionPermission.availableTo,
          defaultSelection,
          selection: isView ? defaultSelection : selectedAvailableTo
        };
      });
    return [createPermission, ...actionPermissions];
  }, [formatConfig, actionItemType]);

  useEffect(() => {
    unregister("permissions");

    if (!permissions) {
      return;
    }

    const setFormValuesForPermission = (
      permissionKey: string,
      activeChoices: string[]
    ) => {
      activeChoices.forEach(availableTo => {
        setValue(`permissions.${permissionKey}.${availableTo}`, true);
      });

      const availableToSet = new Set(activeChoices);
      if (availableToSet.has(AVAILABLE_TO_ALL)) {
        const options =
          permissions.find(p => p.key === permissionKey)?.availableTo ??
          availableToOptions;
        options.forEach(availableTo => {
          setValue(`permissions.${permissionKey}.${availableTo}`, true);
        });
      }
    };

    if (crudFormMode === CrudFormMode.CREATE) {
      permissions.forEach(p => {
        const actionKey = p.key;
        setFormValuesForPermission(actionKey, p.defaultSelection);
      });
      return;
    }

    if ([CrudFormMode.READ, CrudFormMode.UPDATE].includes(crudFormMode)) {
      permissions.forEach(p => {
        setFormValuesForPermission(p.key, p.selection);
      });
    }
  }, [availableToOptions, crudFormMode, permissions, setValue, unregister]);

  const onChange = useCallback(
    (permissionKey: string, availableTo: string) => (value: boolean) => {
      const getOtherPermissions = () => {
        const options =
          permissions?.find(p => p.key === permissionKey)?.availableTo ??
          availableToOptions;
        return options.filter(h => h !== AVAILABLE_TO_ALL);
      };

      // Toggling ALL should toggles all other permissions
      if (availableTo === AVAILABLE_TO_ALL) {
        getOtherPermissions().forEach(otherAvailableTo => {
          setValue(`permissions.${permissionKey}.${otherAvailableTo}`, value);
        });
        return;
      }

      // ALL get automatically toggled based on other permissions
      const allSelected = getOtherPermissions().every(otherAvailableTo =>
        getValues(`permissions.${permissionKey}.${otherAvailableTo}`)
      );
      setValue(`permissions.${permissionKey}.${AVAILABLE_TO_ALL}`, allSelected);
    },
    [availableToOptions, getValues, permissions, setValue]
  );

  return (
    <RequestPermissionsMatrix
      permissions={permissions ?? []}
      availableToOptions={availableToOptions}
      crudFormMode={crudFormMode}
      onChange={onChange}
    />
  );
};

export default ActionItemTypeFormPermissions;

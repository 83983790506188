import React, { useCallback, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Inline, Stack } from "@fermions";

import { InputContainer } from "@templates/InputContainer";

import Form from "@components/atoms/Form";
import BoxTemplate from "@components/templates/BoxTemplate";

import { ClientLogo } from "./ClientLogo/ClientLogo";

type logoType = {
  data?: string;
};

type clientEntityType = {
  entityId: number;
  entityName: string;
  entityCode: string;
};

interface ClientFormProps {
  client?: {
    acn: string;
    abn: string;
    name:
      | string
      | {
          name: string;
          entity: {
            entityId: number;
            entityName: string;
            entityCode: string;
          };
        };
    logo: unknown;
    externalId: number;
  };
  setClientLogoModal: (modal: unknown) => void;
  logo?: logoType;
  setLogo: (logo: logoType) => void;
  externalEntitiesEnabled?: boolean;
  clientEntities?: Array<clientEntityType>;
}

const maxEntityNameLength = 150;
const maxEntityCodeLength = 20;

export const ClientForm = ({
  client,
  setClientLogoModal,
  logo,
  setLogo,
  externalEntitiesEnabled,
  clientEntities
}: ClientFormProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (client?.logo) {
      setLogo(client?.logo);
    }
  }, [client?.logo, setLogo]);

  const entitiesDropdownItems = useMemo(() => {
    if (externalEntitiesEnabled && clientEntities?.length) {
      return clientEntities?.map(entity => {
        const isEntityNameLong =
          entity.entityName?.length > maxEntityNameLength;
        const entityName = isEntityNameLong
          ? entity.entityName.substring(0, maxEntityNameLength)
          : entity.entityName;
        const entityCode =
          entity.entityCode?.length > maxEntityCodeLength
            ? entity.entityCode.substring(0, maxEntityCodeLength)
            : entity.entityCode;

        const label = `${entityName}${entityCode ? ` (${entityCode})` : ""}`;
        return {
          value: entity.entityName,
          name: label,
          id: entity.entityId
        };
      });
    }
    return [];
  }, [clientEntities, externalEntitiesEnabled]);

  const renderClientNameField = useCallback(() => {
    if (!externalEntitiesEnabled) {
      return (
        <Form.TextField
          name="name"
          label={t("common:ui.client.field.name")}
          maxLength={50}
          required
          defaultValue={client?.name}
        />
      );
    }

    let defaultValue;
    if (client && Object.prototype.hasOwnProperty.call(client, "externalId")) {
      const defaultEntity = entitiesDropdownItems?.find(
        item => item.id === client?.externalId
      );
      defaultValue = defaultEntity;
    }

    return (
      <Form.Dropdown
        name="name"
        label={t("common:ui.client.field.name")}
        required={true}
        items={entitiesDropdownItems}
        showSearch={false}
        defaultValue={defaultValue}
      />
    );
  }, [externalEntitiesEnabled, client, t, entitiesDropdownItems]);

  return (
    <BoxTemplate>
      <Inline gap="500">
        <ClientLogo
          logo={logo}
          setClientLogoModal={setClientLogoModal}
          setLogo={setLogo}
        />
        <Stack gap="300" width="100">
          {renderClientNameField()}
          <InputContainer>
            <Form.TextField
              name="acn"
              label={t("common:ui.client.field.acn")}
              maxLength={11}
              defaultValue={client?.acn}
            />
            <Form.TextField
              name="abn"
              label={t("common:ui.client.field.abn")}
              maxLength={14}
              defaultValue={client?.abn}
            />
          </InputContainer>
        </Stack>
      </Inline>
    </BoxTemplate>
  );
};

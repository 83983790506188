import React, { useRef } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { formatBusinessNumber, formatMobileNumber } from "@app/helpers/users";

import Text, { FontColor } from "@atoms/Text/Text.tsx";

import EntitiesListIconCell from "@molecules/EntitiesListIconCell";

import "./ContactDetails.scss";

const ContactDetails = props => {
  const { t } = useTranslation();

  const { user } = props;
  const contactName = useRef(null);
  const contactJobDetail = useRef(null);
  const formattedBusinessNumber = formatBusinessNumber(user);
  const formattedMobileNumber = formatMobileNumber(user);

  const contactNameOverflow =
    contactName.current?.scrollWidth > contactName.current?.clientWidth;

  const contactJobTitleOverflow =
    contactJobDetail.current?.scrollWidth >
    contactJobDetail.current?.clientWidth;

  return (
    <div className="contact__details">
      <div className="contact__details__left">
        <span className="contact-name" ref={contactName}>
          {contactNameOverflow ? (
            <abbr title={user.name}>{user.name}</abbr>
          ) : (
            user.name
          )}
        </span>
        <div className="contact-jobDetail">
          {user.jobTitle && (
            <span
              className="contact-jobDetail__label contact-jobDetail__jobTitle"
              ref={contactJobDetail}
            >
              {contactJobTitleOverflow ? (
                <abbr title={contactJobTitleOverflow && user.jobTitle}>
                  {user.jobTitle}
                </abbr>
              ) : (
                user.jobTitle
              )}
            </span>
          )}
        </div>
        {props.isEngagementTypeSupportEntities &&
          !user.hostId &&
          user.isEntityRestricted && (
            <div className="contact-entities">
              <Text
                color={FontColor.SECONDARY}
                text={t("roles:ui.role.section_EntityAccessRestricted")}
              />
            </div>
          )}
        {formattedBusinessNumber && (
          <span data-testid={"test-business-number"} className="contact-label">
            {formattedBusinessNumber}
          </span>
        )}
        {formattedMobileNumber && (
          <span data-testid={"test-mobile-number"} className="contact-label">
            {formattedMobileNumber}
          </span>
        )}
      </div>
      {props.isEngagementTypeSupportEntities &&
        !user.hostId &&
        user.isEntityRestricted && (
          <div className="contact__details__right">
            <EntitiesListIconCell
              entities={user.entities?.map(e => e.name) ?? []}
              color="accent"
              onlyShowEntities={true}
              optionalContent={t("roles:ui.role.section_EntityNoAccessGranted")}
            />
          </div>
        )}
    </div>
  );
};

ContactDetails.propTypes = {
  user: PropTypes.object.isRequired,
  isEngagementTypeSupportEntities: PropTypes.bool
};

export default ContactDetails;

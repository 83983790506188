import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";
import {
  useAuthUser,
  useGetClientByIdQuery,
  useToasts,
  useUpdateUserStatus
} from "@shared/hooks";
import {
  useDeactivateUserMutation,
  useGetClientUsersQuery,
  useUploadBulkClientUsersMutation
} from "@shared/services";

import { routeConstants } from "@app/constants";
import { getErrorMessage } from "@app/helpers/error";
import { AccessLevel, ResourceName } from "@app/types";

import { Box } from "@fermions";

import { Button } from "@atoms/Button";

import DropdownPagination from "@components/molecules/DropdownPagination";
import UploadUsers from "@components/molecules/UploadUsers";
import SearchTextInput from "@components/organisms/SearchTextInput";
import UsersDataTable from "@components/organisms/UserDataTable";
import PageTemplate from "@components/templates/PageTemplate";

const ManageClientUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuthUser();
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    countPerPage: systemConstants.pagination.itemCountPerPage,
    pageCount: 1,
    currentPage: "Page 1",
    currentPageIndex: 0,
    pages: []
  });
  const { clientId } = useParams();
  const {
    data: clientUsers = [],
    isLoading,
    error
  } = useGetClientUsersQuery(clientId, { skip: !clientId });
  const { isKeywordInclude } = utilities;
  const [
    deactivateUser,
    { isSuccess: deactivateSuccess, error: deactivateError }
  ] = useDeactivateUserMutation();
  const { data: client, isError } = useGetClientByIdQuery(clientId);
  const {
    activateUser,
    isSuccess: activateSuccess,
    error: activateError
  } = useUpdateUserStatus();

  const [filter, setFilter] = useState({ string: "", users: [] });
  const [
    bulkUpdateClientUsers,
    {
      isSuccess: isUploadSuccess,
      data,
      error: uploadError,
      isLoading: uploading
    }
  ] = useUploadBulkClientUsersMutation();
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const { showBulkUpdateSuccess } = useToasts();
  const [errorState, setErrorState] = useState("");

  useEffect(() => {
    if (!clientId || isError) {
      navigate(routeConstants.notFound);
    }
  }, [clientId, isError, navigate]);

  useEffect(() => {
    if (activateSuccess) {
      setErrorState("");
    } else if (activateError) {
      setErrorState(activateError.data?.message);
    }
  }, [activateSuccess, activateError]);

  useEffect(() => {
    if (deactivateSuccess) {
      setErrorState("");
    } else if (deactivateError) {
      setErrorState(deactivateError.data?.message);
    }
  }, [deactivateSuccess, deactivateError]);

  useEffect(() => {
    if (clientUsers.length) {
      setFilter(prev => ({ ...prev, users: clientUsers }));
    }
  }, [clientUsers]);

  useEffect(() => {
    if (filter.string) {
      createPagination(filter.users);
    } else {
      createPagination(clientUsers);
    }
    // eslint-disable-next-line
  }, [filter.users]);

  useEffect(() => {
    if (!filter.string) {
      createPagination(clientUsers);
    }
    // eslint-disable-next-line
  }, [filter.string]);

  useEffect(() => {
    if (pagination.pages.length) {
      setUsers(pagination.pages[pagination.currentPageIndex].data);
    } else {
      setUsers([]);
    }
  }, [pagination]);

  useEffect(() => {
    if (uploading) {
      setIsUploadDisabled(true);
      return;
    }
    if (isUploadSuccess) {
      setIsUploadDisabled(false);
      const { created, updated, deactivated } = data;
      showBulkUpdateSuccess({
        created,
        updated,
        deactivated
      });
      return;
    }
    if (uploadError) {
      setIsUploadDisabled(false);
      if (uploadError?.data?.key === "Invalid Data") {
        const errors = uploadError?.data?.message ?? [];
        const errorMessages = errors.map(
          error =>
            `${t("common:ui.label.error.bulkUser.row.message")} ${error.row}, ${t(error.message)}`
        );
        setErrorState(
          `${t("common:ui.label.error.bulkUser.error")}\n ${errorMessages.join("\n")}`
        );
      } else {
        setErrorState(uploadError.data?.message);
      }
    }
  }, [isUploadSuccess, uploadError, data, showBulkUpdateSuccess, uploading, t]);

  const createPagination = allUsers => {
    const count = Math.ceil(allUsers.length / pagination.countPerPage);
    const items = [];
    for (let number = 0; number < count; number++) {
      const data = allUsers.slice(
        number * pagination.countPerPage,
        number * pagination.countPerPage + pagination.countPerPage
      );
      items.push({ number: number + 1, name: `Page ${number + 1}`, data });
    }

    setPagination({
      ...pagination,
      currentPage: "Page 1",
      currentPageIndex: 0,
      pageCount: count,
      pages: items
    });
  };

  const handleFilterChange = searchInput => {
    if (!searchInput) {
      setFilter({ ...filter, string: "", users: clientUsers });
    } else {
      const filteredUsers = clientUsers.filter(
        user =>
          isKeywordInclude(user.name, searchInput) ||
          (user.email && isKeywordInclude(user.email, searchInput))
      );
      setFilter({
        ...filter,
        string: searchInput,
        users: filteredUsers
      });
    }
  };

  const handleAddUser = () => {
    if (!user.checkAccess(ResourceName.CLIENT_USERS, AccessLevel.CREATE)) {
      return;
    }
    navigate(`/admin/clients/${clientId}/add-user`);
  };

  const handleUpdateUser = clientUser => {
    if (!user.checkAccess(ResourceName.CLIENT_USERS, AccessLevel.UPDATE)) {
      return;
    }
    navigate(`/admin/clients/${clientId}/users/${clientUser.id}/edit`);
  };

  const headerActions = () => {
    if (!user.checkAccess(ResourceName.CLIENT_USERS, AccessLevel.CREATE)) {
      return <></>;
    }
    return (
      <Button
        label={t("common:ui.manageUsers.addLabel", { context: "CLIENT" })}
        iconName="add"
        onClick={handleAddUser}
      />
    );
  };

  const handleUploadClientUsers = useCallback(
    data => {
      bulkUpdateClientUsers({ clientId, data });
    },
    [clientId, bulkUpdateClientUsers]
  );

  const secondaryContent = () => {
    return (
      <UploadUsers
        bulkUpdateUsers={handleUploadClientUsers}
        handleErrorState={setErrorState}
        user={user}
        isUploading={uploading}
        isUploadDisabled={isUploadDisabled}
        downloadUrl={`clients/${clientId}/users/download`}
      />
    );
  };

  return (
    <PageTemplate
      header={{
        title: !user.isHostUser
          ? t("common:ui.manageUsers.title", { context: "CLIENT" })
          : client &&
            t("common:ui.manageUsers.titleWithClientName", {
              clientName: client.name,
              context: "CLIENT"
            }),
        breadcrumbs: user.isHostUser
          ? [
              {
                linkTo: routeConstants.manageClients,
                label: t("common:ui.manageClients.title")
              }
            ]
          : null,
        alwaysShowBreadcrumbs: true,
        actions: headerActions()
      }}
      other={{
        error: getErrorMessage(error, t) ?? errorState,
        whiteSpaceStyle: "pre-line",
        help:
          clientUsers.length === 0
            ? `${t("common:ui.manageUsers.noUsersPresent")}\n${t(
                "common:ui.manageUsers.noUsersPresentCallToAction"
              )}`
            : undefined,
        loading: isLoading
      }}
      body={{
        header: (
          <SearchTextInput
            label={t("common:ui.manageUsers.searchLabel")}
            handleChange={handleFilterChange}
          />
        ),
        primary: (
          <>
            <Box className="client-user-manager__table" width="100">
              <UsersDataTable
                users={users}
                onUpdateUser={handleUpdateUser}
                onActivateUser={activateUser}
                onDeactivateUser={deactivateUser}
                canUpdate={user.checkAccess(
                  ResourceName.CLIENT_USERS,
                  AccessLevel.UPDATE
                )}
                canDelete={user.checkAccess(
                  ResourceName.CLIENT_USERS,
                  AccessLevel.DELETE
                )}
                type="CLIENT"
              />
            </Box>
            <DropdownPagination
              pagination={pagination}
              setPagination={setPagination}
            />
          </>
        ),
        secondary: secondaryContent()
      }}
    />
  );
};

export default ManageClientUsers;

import React, { useMemo } from "react";

import PropTypes from "prop-types";

import { Inline } from "@fermions";

import { ActionDropdown } from "@molecules/ActionDropdown";

import Avatar from "@components/atoms/Avatar/Avatar";
import ContactDetails from "@components/atoms/ContactDetails";

import "./Contact.scss";

const Contact = props => {
  const {
    user,
    handleUserActionClick,
    isDragging,
    enableDrag,
    menuItems,
    isEngagementTypeSupportEntities
  } = props;

  const userAction = useMemo(() => {
    if (!menuItems.length) {
      return <></>;
    }
    return (
      <ActionDropdown
        positionRelative
        menuItems={menuItems}
        onMenuItemClick={handleUserActionClick}
      />
    );
  }, [handleUserActionClick, menuItems]);

  return (
    <>
      <div
        className={`contact-container ${
          enableDrag ? "contact-container--draggable" : ""
        }${isDragging ? "contact-container--dragging" : ""}`}
        key={user.id}
      >
        <Inline className="contact-details" gap="150">
          <Avatar size={"medium"} user={user} />
          <ContactDetails
            user={user}
            isEngagementTypeSupportEntities={isEngagementTypeSupportEntities}
          />
        </Inline>
        {userAction}
      </div>
    </>
  );
};

Contact.defaultProps = {
  isShowIcon: false,
  enableDrag: false
};

Contact.propTypes = {
  user: PropTypes.object.isRequired,
  handleUserActionClick: PropTypes.func,
  isShowIcon: PropTypes.bool,
  enableDrag: PropTypes.bool,
  showMenuItems: PropTypes.bool,
  isEngagementTypeSupportEntities: PropTypes.bool
};

export default Contact;

import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from "react";

import { useTranslation } from "react-i18next";

import { actionTypeConstants } from "@shared/constants/actionTypeConstants.js";
import {
  useAuthUser,
  useGetCopyFromFieldsQuery,
  useGetProjectMembers
} from "@shared/hooks";

import { getQueryValidationSchema } from "@app/helpers/actionItems";

import Form from "@components/atoms/Form";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import EntitiesField from "@components/molecules/EntitiesField";

const CopyUserInputFields = (props, fwdRef) => {
  const { project, actionItems, entities, error } = props;
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const { members, membersLoading } = useGetProjectMembers(project);
  const [assignUsers, setAssignUsers] = useState([]);
  const { data: fields } = useGetCopyFromFieldsQuery(
    {
      queryIds: actionItems?.map(a => a.id),
      projectId: project.id
    },
    { skip: !actionItems?.length }
  );

  useEffect(() => {
    if (members && !membersLoading) {
      const hasInternalOnlyItem =
        actionItems.some(({ internalOnly }) => internalOnly) ?? false;
      const allUsers = (hasInternalOnlyItem ? [] : (members.clientUsers ?? []))
        .concat(members.hostUsers || [])
        .filter(member => member.id !== user.id)
        .map(member => ({ name: member.name, value: member }));
      setAssignUsers(allUsers);
    } else {
      setAssignUsers([]);
    }
  }, [members, membersLoading, user.id, actionItems]);

  const getSchema = useCallback(() => {
    const additionalFields = {};

    if (fields?.find(f => f.key === "overrideMessage")) {
      additionalFields.overrideMessage = { type: "string", required: true };
    }

    const c = getQueryValidationSchema({
      configFields: fields,
      additionalFields,
      i18nText: t,
      userTypes: [user?.xRole?.type],
      actionType: actionTypeConstants.CREATE
    });
    return c;
  }, [fields, t, user?.xRole?.type]);

  useImperativeHandle(fwdRef, () => {
    return {
      getSchema
    };
  }, [getSchema]);

  const renderMilestoneWarningMessage = f => {
    return (
      <>
        {t("requests:requests.ui.populateRequestForm.milestoneWarning.text1")}
        {
          <ul>
            {f.value.map((v, i) => (
              <li style={{ listStylePosition: "inside" }} key={i}>
                {t(v)}
              </li>
            ))}
          </ul>
        }
        {t("requests:requests.ui.populateRequestForm.milestoneWarning.text2")}
      </>
    );
  };

  const getFieldLabel = f => {
    return t(`requests:requests.configured.fields.${f.key}.label`);
  };
  return (
    <>
      {(fields ?? []).map(f => {
        switch (f.type) {
          case "user":
            return (
              <Form.Dropdown
                key={f.key}
                name={f.key}
                label={getFieldLabel(f)}
                required={f.required}
                items={assignUsers}
              />
            );
          case "copiedTo":
            return (
              <Form.Multiselect
                key={f.key}
                name={f.key}
                label={getFieldLabel(f)}
                required={f.required}
                items={assignUsers}
              />
            );
          case "date":
            return (
              <Form.DateField
                key={f.key}
                name={f.key}
                required={f.required}
                label={getFieldLabel(f)}
              />
            );
          case "entity": {
            return <EntitiesField entities={entities ?? []} field={f} />;
          }
          case "overrideMessage": {
            return (
              <>
                <InlineAlert
                  type="warning"
                  message={t(
                    "requests:requests.ui.populateRequestForm.copyRequest.overrideMessageAlert"
                  )}
                ></InlineAlert>
                <Form.TextArea
                  label={t(
                    "requests:requests.ui.populateRequestForm.labelOverrideMessage"
                  )}
                  name="overrideMessage"
                  maxLength="255"
                  data-testid="overrideMessage"
                  required={true}
                />
              </>
            );
          }
          case "milestoneWarning": {
            return (
              <InlineAlert
                type="warning"
                message={renderMilestoneWarningMessage(f)}
              ></InlineAlert>
            );
          }
        }
      })}
      {error && (
        <InlineAlert
          type="error"
          message={t(
            error.message ??
              "requests:requests.ui.populateRequestForm.copyRequest.genericErrorMessage"
          )}
        ></InlineAlert>
      )}
    </>
  );
};

export default React.forwardRef(CopyUserInputFields);

/**
 * ! This file has been copied from BE repo and should be kept in sync with the original file
 * * Path to the original file: oneteam-be/src/services/project/template.milestones.ts
 */
import {
  calculateDays,
  calculateFixedDay,
  calculateNthDay,
  calculateWorkDays
} from "./template.milestones.calculations";
import {
  FixedDaysIntervalCalculatorType,
  FixedDaysIntervalDay,
  FixedDaysNoIntervalCalculatorType,
  MilestoneCalculatorType,
  MilestoneType,
  NthOccurrenceDayCalculatorType,
  Project,
  ProjectTemplateMilestone
} from "./template.milestones.types";

type CalculatedMilestone = {
  key: string;
  date: Date;
};

interface IMilestoneDateCalculator {
  calculateMilestoneDate(
    milestones: ProjectTemplateMilestone[],
    currentMilestone: ProjectTemplateMilestone,
    projectYear: string
  ): CalculatedMilestone;
}

export class MilestoneGenerator {
  createMilestone(
    milestones: ProjectTemplateMilestone[],
    currentMilestone: ProjectTemplateMilestone,
    projectYear?: string
  ) {
    const milestoneCalculator =
      MilestoneDateCalculatorFactory.getCalculator(currentMilestone);
    const calculatedMilestone = milestoneCalculator.calculateMilestoneDate(
      milestones,
      currentMilestone,
      projectYear
    );
    currentMilestone.date = calculatedMilestone.date;
  }

  private milestoneCalculated = false;
  getAllMilestones(
    milestones: ProjectTemplateMilestone[],
    project?: Project
  ): ProjectTemplateMilestone[] {
    const uncalculatedMilestones = milestones.filter(
      milestone => !milestone.date
    );

    if (uncalculatedMilestones.length === 0) {
      return milestones;
    }

    uncalculatedMilestones.forEach(currentMilestone => {
      if (project?.startDate && currentMilestone.type === MilestoneType.START) {
        currentMilestone.date = new Date(project.startDate);
        this.milestoneCalculated = true;
        return;
      }
      if (
        project?.plannedCompletionDate &&
        currentMilestone.type === MilestoneType.END
      ) {
        currentMilestone.date = new Date(project.plannedCompletionDate);
        this.milestoneCalculated = true;
        return;
      }
      const calculator = currentMilestone.calculator;
      if ("referencePoint" in calculator) {
        const referenceMilestone = milestones.find(
          milestone => milestone.key === calculator.referencePoint
        );

        if (referenceMilestone && !referenceMilestone.date) {
          return;
        }
      }

      this.createMilestone(
        milestones,
        currentMilestone,
        project?.properties?.year
      );
      this.milestoneCalculated = true;
    });

    if (!this.milestoneCalculated) {
      throw new Error(
        "Unable to calculate all milestones due to missing or circular references"
      );
    }

    return this.getAllMilestones(milestones, project).sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  }
}

class MilestoneDateCalculatorFactory {
  static getCalculator(milestone: ProjectTemplateMilestone) {
    switch (milestone.calculator.type?.toLowerCase()) {
      case MilestoneCalculatorType.FIXED_DAYS_NO_INTERVAL.toLowerCase():
        return new FixedDaysNoIntervalCalculator();
      case MilestoneCalculatorType.FIXED_DAYS_INTERVAL.toLowerCase():
        return new FixedDaysIntervalCalculator();
      case MilestoneCalculatorType.NTH_OCCURRENCE_DAY.toLowerCase():
        return new NthOccurrenceDayCalculator();
      default:
        throw new Error("Invalid Milestone calculator type");
    }
  }
}

class FixedDaysNoIntervalCalculator implements IMilestoneDateCalculator {
  calculateMilestoneDate(
    _milestones: ProjectTemplateMilestone[],
    currentMilestone: ProjectTemplateMilestone,
    projectYear?: string
  ): CalculatedMilestone {
    const year = projectYear ? +projectYear : undefined;
    const date = calculateFixedDay({
      milestoneCalculator:
        currentMilestone.calculator as FixedDaysNoIntervalCalculatorType,
      projectYear: year
    });
    return { key: currentMilestone.key, date };
  }
}

class FixedDaysIntervalCalculator implements IMilestoneDateCalculator {
  calculateMilestoneDate(
    milestones: ProjectTemplateMilestone[],
    currentMilestone: ProjectTemplateMilestone
  ): CalculatedMilestone {
    const calculator =
      currentMilestone.calculator as FixedDaysIntervalCalculatorType;
    const referenceMilestone = milestones.find(
      milestone => milestone.key === calculator.referencePoint
    );

    if (!referenceMilestone) {
      throw new Error("Invalid reference point");
    }

    const date = (() => {
      if (calculator.dayType === FixedDaysIntervalDay.WORKDAY) {
        return calculateWorkDays({
          startDate: referenceMilestone.date,
          noOfDays: calculator.quantity,
          direction: calculator.direction
        });
      }
      return calculateDays({
        startDate: referenceMilestone.date,
        noOfDays: calculator.quantity,
        direction: calculator.direction
      });
    })();

    return { key: currentMilestone.key, date };
  }
}

class NthOccurrenceDayCalculator implements IMilestoneDateCalculator {
  calculateMilestoneDate(
    milestones: ProjectTemplateMilestone[],
    currentMilestone: ProjectTemplateMilestone
  ): CalculatedMilestone {
    const calculator =
      currentMilestone.calculator as NthOccurrenceDayCalculatorType;
    const referenceMilestone = milestones.find(
      milestone => milestone.key === calculator.referencePoint
    );
    if (!referenceMilestone) {
      throw new Error("Invalid reference point");
    }
    const date = calculateNthDay({
      date: referenceMilestone.date,
      targetDay: calculator.dayOfWeek,
      occurrence: calculator.quantity,
      direction: calculator.direction
    });

    return { key: currentMilestone.key, date };
  }
}

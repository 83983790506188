import {
  Days,
  FixedDaysNoIntervalCalculatorType,
  MilestoneCalculatorDirection
} from "./template.milestones.types";

export function calculateDays({
  startDate,
  noOfDays,
  direction
}: {
  startDate: Date;
  noOfDays: number;
  direction: MilestoneCalculatorDirection;
}) {
  const newDate = new Date(startDate);

  if (direction === MilestoneCalculatorDirection.BEFORE) {
    newDate.setDate(new Date(startDate).getDate() - noOfDays);
  } else {
    newDate.setDate(new Date(startDate).getDate() + noOfDays);
  }

  return newDate;
}

export function calculateWorkDays({
  startDate,
  noOfDays,
  direction
}: {
  startDate: Date;
  noOfDays: number;
  direction: MilestoneCalculatorDirection;
}) {
  let counter = noOfDays;
  let endDate = new Date(startDate);

  while (counter > 0) {
    endDate = calculateDays({
      startDate: endDate,
      noOfDays: 1,
      direction
    });
    if (![Days.SATURDAY, Days.SUNDAY].includes(endDate.getDay())) {
      counter--;
    }
  }
  return endDate;
}

function parseDay(day: string): Days {
  return Days[day.toUpperCase() as keyof typeof Days];
}

function getDaysToTargetDay({
  targetDayNumber,
  day,
  direction
}: {
  targetDayNumber: number;
  day: number;
  direction: MilestoneCalculatorDirection;
}): number {
  if (direction === MilestoneCalculatorDirection.BEFORE) {
    return (day + 7 - targetDayNumber) % 7 || 7; // If the day is the same as the target day, return 7
  } else {
    return (targetDayNumber + 7 - day) % 7 || 7;
  }
}

export function calculateNthDay({
  date,
  targetDay,
  occurrence,
  direction
}: {
  date: Date;
  targetDay: string;
  occurrence: number;
  direction: MilestoneCalculatorDirection;
}): Date {
  const targetDayNumber = parseDay(targetDay);
  const day = date.getDay();
  const daysToTargetDay = getDaysToTargetDay({
    targetDayNumber,
    day,
    direction
  });
  const daysToNthTargetDay = daysToTargetDay + 7 * (occurrence - 1);
  const nthTargetDay = new Date(date.getTime());

  if (direction === MilestoneCalculatorDirection.BEFORE) {
    nthTargetDay.setDate(date.getDate() - daysToNthTargetDay);
  } else {
    nthTargetDay.setDate(date.getDate() + daysToNthTargetDay);
  }

  return nthTargetDay;
}

export function calculateFixedDay({
  milestoneCalculator,
  projectYear = undefined
}: {
  milestoneCalculator: FixedDaysNoIntervalCalculatorType;
  projectYear?: number;
}): Date {
  const year = projectYear ?? new Date().getFullYear();
  const date = new Date(year, 0, 1);

  const monthDays = new Date(
    date.getFullYear(),
    milestoneCalculator.month,
    0
  ).getDate();

  date.setFullYear(date.getFullYear() + milestoneCalculator.yearAdjustment);
  date.setMonth(milestoneCalculator.month - 1);
  if (milestoneCalculator.day > monthDays) {
    date.setDate(monthDays);
  } else {
    date.setDate(milestoneCalculator.day);
  }

  return date;
}

import React, { useRef } from "react";

import { PillFillStyle, PillVariant } from "@ions-interface";

import { returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Inline } from "@fermions";

import { Icon, IconColor, IconFillStyle, IconSize } from "@atoms/Icon";

import "./Pill.scss";

export { PillVariant, PillFillStyle } from "@ions-interface";

export enum PillFontWeight {
  BOLD = "bold",
  SEMI_BOLD = "semi-bold",
  NORMAL = "normal"
}

export enum PillSize {
  DEFAULT = "default",
  SMALL = "small",
  SMALL_FILL = "small-fill",
  FILL = "fill"
}

export enum PillStatus {
  OPEN = "open",
  CLOSED = "closed",
  RESPONDED = "responded",
  ATTACHMENT = "attachment",
  NOTE = "note",
  DRAFT = "draft"
}

interface PillProps {
  label: string;
  variant?: PillVariant;
  fontWeight?: PillFontWeight;
  fillStyle?: PillFillStyle;
  size?: PillSize;
  status?: PillStatus;
  iconName?: string;
  style?: React.CSSProperties;
  // NB: will be removed when <FilterPill/> is added
  isFilter?: boolean;
  className?: string;
}

export const Pill = ({
  label,
  variant = PillVariant.STANDARD,
  fontWeight,
  fillStyle = PillFillStyle.OUTLINE,
  size = PillSize.DEFAULT,
  status,
  iconName,
  isFilter = false,
  style = {},
  className
}: PillProps) => {
  const pillLabelRef = useRef();
  return (
    <Inline
      data-testid="test-pill"
      className={[
        "ot-pill",
        `ot-pill--fill-${fillStyle}`,
        `ot-pill--variant-${variant}`,
        returnStringIfTrue(status, `ot-pill--status-${status}`),
        returnStringIfTrue(isFilter, `ot-pill--filter`),
        `ot-pill--size-${size} ${className}`
      ]}
      alignment="center"
      style={style}
    >
      {iconName && (
        <Icon
          name={iconName}
          color={IconColor.INHERIT}
          size={IconSize.XS}
          fillStyle={IconFillStyle.FILLED}
        />
      )}
      <span
        className={`pill-label pill-label-${fontWeight}`}
        ref={pillLabelRef}
        title={label}
      >
        {label}
      </span>
    </Inline>
  );
};

import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import { useAuthUser } from "@shared/hooks/index";
import {
  useGetAllConversationsQuery,
  useGetAnswerCommentsQuery,
  useGetGroupedAnswerCommentQuery,
  useLazyGetQuestionWithCommentsQuery
} from "@shared/services/smartformService";

import { getActionItemTypeDisplayName } from "@app/helpers";

import ActionItemCommentsThread from "@components/molecules/ActionItemCommentsThread";
import LeftPanel from "@components/molecules/SmartFormCommentModalPanel/LeftPanel";
import SmartFormCommentsModalTemplate from "@components/templates/SmartFormCommentsModalTemplate";

import CommentThreadModalActions from "../CommentThreadModal/CommentThreadModalActions";

function SmartFormCommentsModal(props) {
  const { onClose, query, question, handleFileDownload, smartFormConfig } =
    props;
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const [resetComposer, setResetComposer] = useState(false);

  const { data: allConversations, refetch: refetchConversations } =
    useGetAllConversationsQuery(
      { queryId: query?.id, questionId: question?.questionId },
      { skip: !query?.id || !question?.questionId }
    );
  const [getQuestionWithCommentsQuery] = useLazyGetQuestionWithCommentsQuery();
  const [conversationId, setConversationId] = useState();

  const [isNewComment, setIsNewComment] = useState(false);
  const {
    data: comments,
    isLoading: isLoadingComments,
    refetch
  } = useGetAnswerCommentsQuery(
    { queryId: query?.id, questionId: question?.questionId },
    { skip: !query?.id || !question?.questionId }
  );

  const { data: groupedComments, refetch: refetchGroupComments } =
    useGetGroupedAnswerCommentQuery(
      {
        queryId: query?.id,
        questionId: question?.questionId,
        conversationId: conversationId
      },
      {
        skip: !query?.id || !question?.questionId || !conversationId,
        refetchOnMountOrArgChange: true
      }
    );

  const handleResponseSend = useCallback(() => {
    setResetComposer(true);
    refetch();
    if (!allConversations?.length) {
      getQuestionWithCommentsQuery({ queryId: query?.id });
    }
    if (conversationId) {
      refetchGroupComments();
    }
    if (isNewComment) {
      refetchConversations();
    }
  }, [
    refetch,
    conversationId,
    allConversations,
    isNewComment,
    refetchGroupComments,
    query?.id,
    getQuestionWithCommentsQuery,
    refetchConversations
  ]);

  useEffect(() => {
    setResetComposer(prevState => (prevState ? false : prevState));
  }, [resetComposer]);

  useEffect(() => {
    if (allConversations?.length > 0 && !conversationId && !isNewComment) {
      setConversationId(allConversations?.[0].conversationId);
    }
  }, [allConversations, conversationId, isNewComment]);

  useEffect(() => {
    setIsNewComment(!allConversations?.length);
  }, [allConversations]);

  const getComposer = () => {
    if (resetComposer) {
      return <></>;
    }

    const mostRecentComment = groupedComments?.[0];
    return (
      <CommentThreadModalActions
        query={query}
        actionItemTypeConfig={{
          type: systemConstants.actionItemTypes.smartForm
        }}
        hiddenData={{
          questionId: question?.questionId,
          secondaryId: question?.secondaryId,
          ...(isNewComment ? null : { threadId: mostRecentComment?.threadId })
        }}
        conversationId={isNewComment ? null : conversationId}
        isNewComment={isNewComment}
        onSuccess={handleResponseSend}
        customClassName={"smart-form-comments-modal-template__footer-template"}
      />
    );
  };

  const onClickCreate = useCallback(() => {
    setIsNewComment(true);
    setConversationId(null);
    setResetComposer(true);
  }, []);

  const onClickConversation = useCallback(
    id => {
      if (id !== conversationId) {
        setConversationId(id);
        setIsNewComment(false);
        setResetComposer(true);
      }
    },
    [conversationId]
  );

  const renderLeftPanel = useCallback(() => {
    return (
      <LeftPanel
        onClickCreate={onClickCreate}
        onClickConversation={onClickConversation}
        allConversations={allConversations}
        conversationId={conversationId}
      />
    );
  }, [onClickCreate, onClickConversation, allConversations, conversationId]);

  const loadingOrNoContentMessage = useCallback(() => {
    if (isLoadingComments) {
      return t("common:loading");
    } else if (isNewComment) {
      return t(
        "requests:requests.responses.newMessageCallToAction.messageLabel"
      );
    } else if (!comments || comments?.length === 0) {
      return t(
        "requests:requests.ui.requestConversationBox.requestHasNoResponses",
        {
          requestName: getActionItemTypeDisplayName(
            smartFormConfig?.useCustomName,
            smartFormConfig?.queryName,
            t("requests:requests.configured.shortName", {
              context: query?.queryType
            })
          )
        }
      );
    }
    return "";
  }, [
    isLoadingComments,
    isNewComment,
    comments,
    t,
    smartFormConfig?.useCustomName,
    smartFormConfig?.queryName,
    query?.queryType
  ]);

  const getConversationTitle = useCallback(() => {
    if (isNewComment) {
      return null;
    }
    return groupedComments?.[0]?.conversationTitle || null;
  }, [groupedComments, isNewComment]);

  return (
    <SmartFormCommentsModalTemplate
      onClose={onClose}
      title={question?.text}
      commentComposer={getComposer()}
      conversationTitle={getConversationTitle()}
      isNewComment={isNewComment}
      commentsThread={
        <ActionItemCommentsThread
          projectId={query?.projectId}
          comments={isNewComment ? [] : groupedComments}
          authUserId={user?.id}
          actionItemTypeKey={query?.queryType}
          onFileDownload={handleFileDownload}
        />
      }
      loadingOrNoContentMessage={loadingOrNoContentMessage()}
      leftSidePanel={renderLeftPanel()}
    />
  );
}

SmartFormCommentsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
  handleFileDownload: PropTypes.func,
  smartFormConfig: PropTypes.object
};

export default SmartFormCommentsModal;

import React, { MouseEventHandler, PropsWithChildren, useMemo } from "react";

import { classNames, returnStringIfTrue } from "@app/helpers/componentHelpers";

import { ContainerWithTooltip } from "@atoms/ContainerWithTooltip";

import "./Icon.scss";

export enum IconFillStyle {
  FILLED = "filled",
  OUTLINED = "outlined"
}

export enum IconColor {
  INHERIT = "inherit",
  // Font colors
  TEXT_PRIMARY = "text-primary", // text e.g. black in light-mode
  TEXT_SECONDARY = "text-secondary", // sub-text
  TEXT_TERTIARY = "text-tertiary", // description
  TEXT_INVERT = "text-invert", // invert color of text e.g. white in light-mode
  TEXT_INVERT_DISABLED = "text-invert-disabled",

  // Brand theme
  PRIMARY = "primary",
  SECONDARY = "secondary",
  OTHER = "other",
  ACCENT = "accent-primary",
  DISABLED = "disabled",
  MUTED = "muted",
  ERROR = "error",
  WARNING = "warning",
  SUCCESS = "success"
}

export enum IconSize {
  INHERIT = "INHERIT",
  XS = "xs", // 12px - prev small 16px
  S = "s", // 18px - prev medium 20px
  M = "m", // 24px -- common - prev normal 24px
  L = "l" // 28px - prev 32px
}

export enum IconDesignStyle {
  // https://fonts.google.com/icons
  MATERIAL_SYMBOLS = "material-symbols",
  // https://m3.material.io/styles/icons
  MATERIAL_ICONS = "material-icons"
}

export interface IconProps {
  name: string;
  color?: IconColor;
  fillStyle?: IconFillStyle;
  size?: IconSize;
  designStyle?: IconDesignStyle;
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  hoverElement?: string | React.JSX.Element;
}

export const Icon = ({
  name,
  color = IconColor.INHERIT,
  fillStyle = IconFillStyle.OUTLINED,
  size = IconSize.M,
  designStyle = IconDesignStyle.MATERIAL_SYMBOLS,
  className,
  onClick,
  hoverElement
}: IconProps) => {
  const designStyleClass = () => {
    if (designStyle === IconDesignStyle.MATERIAL_ICONS) {
      return fillStyle === IconFillStyle.FILLED
        ? `material-icons`
        : "material-icons-outlined";
    }

    return `material-symbols-${fillStyle}`;
  };

  const iconClassNames = classNames([
    className ?? "",
    "ot-icon",
    `ot-icon--size-${size}`,
    designStyleClass(),
    `ot-icon--color-${color}`,
    returnStringIfTrue(!!onClick, "ot-icon--clickable")
  ]);

  return (
    <Wrapper size={size} hoverElement={hoverElement}>
      <i onClick={onClick} className={iconClassNames}>
        {name}
      </i>
    </Wrapper>
  );
};

const Wrapper = ({
  size,
  hoverElement,
  children
}: PropsWithChildren<{
  size: IconProps["size"];
  hoverElement: IconProps["hoverElement"];
}>) => {
  const className = useMemo(
    () =>
      classNames(["ot-icon__container", `ot-icon__container--size-${size}`]),
    [size]
  );

  if (!hoverElement) {
    return <div className={className}>{children}</div>;
  }
  return (
    <ContainerWithTooltip
      className={className}
      hideTooltip={!hoverElement}
      tooltipContent={hoverElement}
    >
      {children}
    </ContainerWithTooltip>
  );
};
export default Icon;

import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { authActions } from "@shared/actions";
import { useGetProjectForSelectionQuery } from "@shared/hooks";
import { useGetClientRolesQuery } from "@shared/services/roleService";

export function useClientUserProfile({ updateUserId, client }) {
  const authentication = useSelector(state => state.authentication);
  const dispatch = useDispatch();
  const { data: roles, error } = useGetClientRolesQuery();
  const { data: allProjects } = useGetProjectForSelectionQuery({
    userId: updateUserId
  });

  const [projectsList, setProjectsList] = useState();
  const [clientUserFields, setClientUserFields] = useState([]);

  useEffect(() => {
    // call hostBrandApi to get the latest host details with formConfig
    if (!authentication.host?.host?.properties?.formsConfig) {
      dispatch(authActions.getHostWithBrandDetails());
    }
  }, [authentication.host?.host?.properties?.formsConfig, dispatch]);

  useEffect(() => {
    setClientUserFields(
      authentication.host?.host?.properties?.formsConfig?.teamUserFields ?? []
    );
  }, [authentication.host?.host?.properties?.formsConfig?.teamUserFields]);

  useEffect(() => {
    if (allProjects?.length > 0) {
      const clientProjects = allProjects.filter(
        project => project.clientId === client?.id
      );
      setProjectsList(clientProjects);
    }
  }, [allProjects, client?.id]);

  return {
    error,
    projectsList,
    roles,
    clientUserFields
  };
}

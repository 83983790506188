import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import dateFormatter from "@shared/helpers/dateHelper";
import { utilities } from "@shared/helpers/utilities";
import { useAuthUser, useFeatures, useLocaleDate } from "@shared/hooks";

import ions from "@ions";

import { documentActions } from "@app/helpers/documents";
import { useDataTable } from "@app/hooks";

import { Pill, PillSize } from "@atoms/Pill";

import DataTable from "@components/molecules/DataTable";

import "./DocumentsTable.scss";

const DocumentsTable = props => {
  const {
    folder,
    handleFolderSelection,
    handleDownloadDocument,
    handleRestoreDocument,
    handleOpenDocumentForEdit,
    handleEditDocumentTags,
    handleDeleteDocument,
    handleReviewReviewHistory,
    handleUpdateDocumentProperties,
    handleUploadDocumentRevision,
    handleViewInRequest,
    downloadingErrorDocumentId,
    t
  } = props;
  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();
  const { user } = useAuthUser();
  const { isEnabled } = useFeatures();
  const [downloadingRowIds, setDownloadingRowIds] = useState([]);

  const editXlsxEnabled = useMemo(
    () => isEnabled(systemConstants.features.editXlsx),
    [isEnabled]
  );

  const filterDocumentIdFromDownloadingRows = useCallback(
    documentId =>
      setDownloadingRowIds(prev => {
        return prev.filter(id => id !== documentId);
      }),
    []
  );

  useEffect(() => {
    if (downloadingErrorDocumentId != -1) {
      filterDocumentIdFromDownloadingRows(downloadingErrorDocumentId);
    }
  }, [downloadingErrorDocumentId, filterDocumentIdFromDownloadingRows]);

  const directoryListing = useMemo(() => {
    if (folder.documents?.length > 0) {
      return folder.documents;
    }

    if (
      (!folder.documents || folder.documents.length === 0) &&
      folder.folders?.length > 0
    ) {
      return folder.folders;
    }

    return [];
  }, [folder.documents, folder.folders]);

  const directoryViewMode = useMemo(() => {
    if (folder.documents?.length > 0) {
      return "documents";
    }

    if (
      (!folder.documents || folder.documents.length === 0) &&
      folder.folders?.length > 0
    ) {
      return "folders";
    }

    return "unknown";
  }, [folder.documents, folder.folders]);

  const {
    findItem,
    createColumn,
    createColumnForDropdownMenu,
    createColumnForDownloading,
    caseInsensitiveSortType,
    caseInsensitiveEmptyLastSortType
  } = useDataTable(directoryListing);

  const validateXlsxFile = useCallback(
    document => document.name.split(".").pop() == "xlsx" && editXlsxEnabled,
    [editXlsxEnabled]
  );

  const handleAction = useCallback(
    ({ action, documentOrFolderId }) => {
      const item = findItem(documentOrFolderId);
      switch (action) {
        case documentActions.viewFolder: {
          if (handleFolderSelection) {
            if (item?.type === "folder" || item?.type === "query") {
              handleFolderSelection(item);
            }
          }
          return;
        }
        case documentActions.restoreDocument: {
          handleRestoreDocument({
            action,
            document: item,
            projectFolder: folder
          });
          return;
        }
        case documentActions.editDocument: {
          handleOpenDocumentForEdit?.(item);
          return;
        }
        case documentActions.viewInRequest: {
          handleViewInRequest?.(item);
          return;
        }
        case documentActions.downloadDocument: {
          setDownloadingRowIds(prev => [...prev, item.id]);
          handleDownloadDocument({
            action,
            document: item,
            projectFolder: folder,
            cb: document => filterDocumentIdFromDownloadingRows(document.id)
          });
          return;
        }
        case documentActions.editTags:
          if (handleEditDocumentTags) {
            handleEditDocumentTags({
              action,
              document: item,
              projectFolder: folder
            });
          }
          return;
        case documentActions.deleteDocument:
          handleDeleteDocument({
            action,
            document: item,
            projectFolder: folder
          });
          return;
        case documentActions.reviewRevisionHistory:
          handleReviewReviewHistory({
            action,
            document: item,
            projectFolder: folder
          });
          return;
        case documentActions.updateDocumentProperties:
          handleUpdateDocumentProperties({
            action,
            document: item,
            projectFolder: folder
          });
          return;
        case documentActions.uploadDocument:
          handleUploadDocumentRevision({
            action,
            document: item,
            projectFolder: folder
          });
          return;
        default:
      }
    },
    [
      findItem,
      handleEditDocumentTags,
      handleDeleteDocument,
      folder,
      handleReviewReviewHistory,
      handleUpdateDocumentProperties,
      handleUploadDocumentRevision,
      handleFolderSelection,
      handleRestoreDocument,
      handleOpenDocumentForEdit,
      handleViewInRequest,
      handleDownloadDocument,
      filterDocumentIdFromDownloadingRows
    ]
  );

  const singleActionHandler = useCallback(
    action =>
      ({ cell }) => {
        const documentOrFolderId = cell.row.original.id;
        handleAction({ action, documentOrFolderId });
      },
    [handleAction]
  );

  const dropdownMenuActionHandler = useCallback(
    ({ menuItem, cell }) => {
      const documentOrFolderId = cell.row.original.id;
      const action = menuItem.key;
      handleAction({ action, documentOrFolderId });
    },
    [handleAction]
  );

  const iconAbbreviation = iconType => {
    if (iconType !== "description") {
      if (iconType === "insert_drive_file") {
        return "A single version of this document exists";
      } else if (iconType === "file_copy") {
        return "Multiple versions of this document exist";
      }
    }
    return "";
  };

  const getIconType = useCallback(
    item => {
      if (folder.id === "archivedFolder") {
        return "delete";
      } else if (item.revisionLength) {
        if (item.revisionLength === 1) {
          return "insert_drive_file";
        } else {
          return "file_copy";
        }
      } else {
        return "description";
      }
    },
    [folder]
  );

  const rowHandler = useMemo(() => {
    return {
      isClickable: documentOrFolderId => {
        if (!handleFolderSelection) {
          return false;
        }
        const item = findItem(documentOrFolderId);
        return item?.type === "folder" || item?.type === "query";
      },
      handler: documentOrFolderId => {
        handleAction({
          action: documentActions.viewFolder,
          documentOrFolderId
        });
      }
    };
  }, [findItem, handleAction, handleFolderSelection]);

  const getInternalOnlyColumn = useCallback(() => {
    return createColumn({
      Header: t("requests:requests.internalOnlyLabel"),
      accessor: "internalOnly",
      width: 90,
      Cell: ({ cell }) =>
        cell.value ? (
          <Pill
            variant={ions.components?.atoms?.draft_pill?.pill_variant}
            fillStyle={ions.components?.atoms?.draft_pill?.pill_fill_style}
            label={t("requests:requests.internalOnly.label")}
            size={PillSize.SMALL}
          />
        ) : (
          ""
        ),
      isHidden:
        user.isClientUser ||
        !directoryListing.some(dir => dir.properties?.internalOnly),
      disableSortBy: false,
      sortType: caseInsensitiveEmptyLastSortType
    });
  }, [
    caseInsensitiveEmptyLastSortType,
    createColumn,
    t,
    user.isClientUser,
    directoryListing
  ]);

  const columns = useMemo(() => {
    if (directoryViewMode === "folders") {
      return [
        {
          accessor: "iconType",
          Cell: ({ cell }) => (
            <i className="material-icons-outlined">{cell.value}</i>
          ),
          fixedWidth: true,
          width: 55
        },
        createColumn({
          Header: "Name",
          accessor: "displayName",
          width: 300
        }),
        createColumn({
          Header: "Last Uploaded",
          accessor: "uploadedAt",
          Cell: ({ cell }) => dateFormatter(cell.value, locale, shortFormat),
          width: 200
        })
      ];
    }
    // Permanent files page
    if (!handleEditDocumentTags) {
      return [
        {
          accessor: "iconType",
          Cell: ({ cell }) => (
            <i className="material-icons-outlined">{cell.value}</i>
          ),
          fixedWidth: true,
          width: 55
        },
        createColumn({
          Header: t("common:documents.fileName"),
          accessor: "displayName",
          minWidth: 150,
          disableSortBy: false,
          sortType: caseInsensitiveSortType
        }),
        createColumn({
          Header: t("common:documents.uploadedBy"),
          accessor: "displayUploadedBy",
          width: 130,
          disableSortBy: false,
          sortType: caseInsensitiveSortType
        }),
        createColumn({
          Header: t("common:documents.lastUploaded"),
          accessor: "uploadedAt",
          Cell: ({ cell }) => dateFormatter(cell.value, locale, shortFormat),
          width: 150,
          disableSortBy: false
        }),
        createColumnForDownloading({
          accessor: "actionIcon",
          className: "visible-on-hover",
          fixedWidth: true,
          width: 55,
          onClickHandler: singleActionHandler(documentActions.downloadDocument),
          downloadingRowIds
        }),
        createColumnForDropdownMenu({
          accessor: "actionsMenu",
          onClickHandler: dropdownMenuActionHandler,
          fixedWidth: true,
          width: 55,
          className: "visible-on-hover"
        })
      ];
    }

    return [
      {
        accessor: "iconType",
        Cell: ({ cell }) => (
          <abbr title={`${iconAbbreviation(cell.value)}`}>
            <i className="material-icons-outlined file-icon">{cell.value}</i>
          </abbr>
        ),
        fixedWidth: true,
        width: 55
      },
      getInternalOnlyColumn(),
      createColumn({
        Header: t("common:documents.labels"),
        accessor: "labels",
        Cell: ({ cell }) =>
          cell.value ? (
            cell.value
          ) : (
            <span className="unlabelled">
              {t("stringFormat.uppercase", {
                key: "common:unlabelled"
              })}
            </span>
          ),
        minWidth: 150
      }),
      createColumn({
        Header: t("common:documents.fileName"),
        accessor: "displayName",
        minWidth: 150,
        disableSortBy: false,
        sortType: caseInsensitiveSortType
      }),
      createColumn({
        Header: t("common:documents.uploadedBy"),
        accessor: "displayUploadedBy",
        width: 130,
        disableSortBy: false,
        sortType: caseInsensitiveSortType
      }),
      createColumn({
        Header: t("common:documents.lastUploaded"),
        accessor: "uploadedAt",
        Cell: ({ cell }) => dateFormatter(cell.value, locale, shortFormat),
        width: 150,
        disableSortBy: false
      }),
      createColumnForDownloading({
        accessor: "actionIcon",
        className: "visible-on-hover",
        fixedWidth: true,
        width: 55,
        onClickHandler: singleActionHandler(documentActions.downloadDocument),
        visibleOnHover: true,
        downloadingRowIds
      }),
      createColumnForDropdownMenu({
        accessor: "actionsMenu",
        className: "visible-on-hover",
        onClickHandler: dropdownMenuActionHandler,
        visibleOnHover: true,
        fixedWidth: true,
        width: 55
      })
    ];
  }, [
    caseInsensitiveSortType,
    createColumn,
    createColumnForDownloading,
    createColumnForDropdownMenu,
    directoryViewMode,
    downloadingRowIds,
    dropdownMenuActionHandler,
    getInternalOnlyColumn,
    handleEditDocumentTags,
    locale,
    shortFormat,
    singleActionHandler,
    t
  ]);

  const actionsMenuForDocument = useCallback(
    document => {
      const actionItems = [];
      const isSmartFormDocument =
        document?.properties?.actionItemTypeKey ===
        systemConstants.actionItemTypes.smartForm;
      if (user.isHostUser && handleEditDocumentTags) {
        actionItems.push({
          name: t("common:documents.documentActions", {
            context: documentActions.editTags
          }),
          key: documentActions.editTags
        });
      }
      if (!document.status) {
        return [];
      }

      if (validateXlsxFile(document) && !isSmartFormDocument) {
        actionItems.push({
          name: t("common:documents.documentActions", {
            context: documentActions.editDocument
          }),
          key: documentActions.editDocument
        });
      }

      if (
        document.status === systemConstants.project.document.status.archived
      ) {
        if (isSmartFormDocument) {
          return [];
        }
        const archivedActions = [];
        archivedActions.push({
          name: t("common:documents.documentActions", {
            context: documentActions.restoreDocument
          }),
          key: documentActions.restoreDocument
        });

        if (user.isHostUser) {
          archivedActions.push({
            name: t("common:documents.documentActions", {
              context: documentActions.deleteDocument
            }),
            key: documentActions.deleteDocument
          });
        }
        return archivedActions;
      }
      if (document.projectId) {
        if (!document.locked) {
          actionItems.push(
            {
              name: t("common:documents.documentActions", {
                context: documentActions.updateDocumentProperties
              }),
              key: documentActions.updateDocumentProperties
            },
            {
              name: t("common:documents.documentActions", {
                context: documentActions.reviewRevisionHistory
              }),
              key: documentActions.reviewRevisionHistory
            }
          );
        }
        if (!isSmartFormDocument) {
          actionItems.push(
            {
              name: t("common:documents.documentActions", {
                context: documentActions.uploadDocument
              }),
              key: documentActions.uploadDocument
            },
            {
              name: t("common:documents.documentActions", {
                context: documentActions.deleteDocument
              }),
              key: documentActions.deleteDocument
            }
          );
        }
        if (isSmartFormDocument) {
          actionItems.push({
            name: t("common:documents.documentActions", {
              context: documentActions.viewInRequest
            }),
            key: documentActions.viewInRequest
          });
        }
      } else {
        actionItems.push(
          {
            name: t("common:documents.documentActions", {
              context: documentActions.updateDocumentProperties
            }),
            key: documentActions.updateDocumentProperties
          },
          {
            name: t("common:documents.documentActions", {
              context: documentActions.reviewRevisionHistory
            }),
            key: documentActions.reviewRevisionHistory
          },
          {
            name: t("common:documents.documentActions", {
              context: documentActions.deleteDocument
            }),
            key: documentActions.deleteDocument
          }
        );
      }
      return actionItems;
    },
    [user, validateXlsxFile, handleEditDocumentTags, t]
  );

  const data = useMemo(
    () =>
      directoryListing
        .map(item => {
          let status;
          if (item.type) {
            status = "-";
          } else {
            status =
              item.status &&
              item.status === systemConstants.document_status_uploaded
                ? ""
                : item.status;
          }

          // it's a folder/request/query
          if (item.type) {
            return {
              ...item,
              labels: "",
              displayName: item.pathName || item.name,
              displayStatus: status,
              iconType: "folder_open",
              actionIcon: "",
              actionsMenu: actionsMenuForDocument(item)
            };
          }

          return {
            ...item,
            labels: item.tags
              ? utilities
                  .sortBy("name")(structuredClone(item.tags))
                  .map(t => t.name)
                  .join(", ")
              : "",
            displayName: item.pathName || item.name,
            displayStatus: status,
            displayUploadedBy: item.uploadedBy?.name,
            iconType: getIconType(item),
            internalOnly: item.properties?.internalOnly,
            actionsMenu: actionsMenuForDocument(item)
          };
        })
        .sort((a, b) => Number(a.locked) - Number(b.locked)),
    [directoryListing, actionsMenuForDocument, getIconType]
  );

  return (
    <DataTable
      className="documents-table"
      columns={columns}
      data={data}
      rowClickHandler={rowHandler}
      virtualScrollOptions={{
        itemHeight: 64,
        visibleItems: 20,
        tolerance: 50
      }}
      fitContentWidth
    />
  );
};

DocumentsTable.defaultProps = {};

DocumentsTable.propTypes = {
  folder: PropTypes.shape({
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired
      })
    ),
    folders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired
      })
    )
  }),
  handleFolderSelection: PropTypes.func,
  handleOpenDocumentForEdit: PropTypes.func,
  handleRestoreDocument: PropTypes.func,
  handleDownloadDocument: PropTypes.func,
  handleEditDocument: PropTypes.func,
  handleUploadDocumentRevision: PropTypes.func,
  handleUpdateDocumentProperties: PropTypes.func,
  handleReviewRevisionHistory: PropTypes.func,
  handleDeleteDocument: PropTypes.func,
  handleEditDocumentTags: PropTypes.func,
  handleReviewReviewHistory: PropTypes.func
};

export default withTranslation()(DocumentsTable);

import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import { roleNameDisplay } from "@shared/helpers/roleHelper";

import { useDataTable } from "@app/hooks";

import { Pill, PillSize } from "@atoms/Pill";
import { Text } from "@atoms/Text";

import DataTable from "@components/molecules/DataTable";

const statusesToIndicatorColorScheme = {
  [systemConstants.user.status.active]: "success",
  [systemConstants.user.status.inactive]: "disabled"
};

const actions = {
  deactivateUser: "deactivate",
  activateUser: "activate",
  updateUser: "update"
};

const UserDataTable = props => {
  const {
    users = [],
    onActivateUser,
    onDeactivateUser,
    onUpdateUser,
    canUpdate,
    canDelete,
    type
  } = props;
  const {
    findItem,
    createColumnForStatus,
    createColumn,
    createColumnForDropdownMenu
  } = useDataTable(users);

  const { t } = useTranslation();
  const handleAction = useCallback(
    (action, clientUserId) => {
      const clientUser = findItem(clientUserId);
      if (action === actions.deactivateUser) {
        onDeactivateUser(clientUser.id);
      } else if (action === actions.activateUser) {
        onActivateUser(clientUser);
      } else if (action === actions.updateUser) {
        onUpdateUser(clientUser);
      }
    },
    [findItem, onActivateUser, onDeactivateUser, onUpdateUser]
  );

  const dropdownMenuActionHandler = useCallback(
    ({ menuItem, cell }) => {
      const clientUserId = cell.row.original.id;
      const action = menuItem.value;
      handleAction(action, clientUserId);
    },
    [handleAction]
  );

  const columns = useMemo(() => {
    const result = [
      createColumnForStatus({
        Header: t("common:ui.user.field.active"),
        accessor: "statusIndicatorColorScheme",
        width: 60,
        fixedWidth: true
      }),
      createColumn({
        Header: t("common:ui.user.field.name"),
        accessor: "name",
        width: 250,
        Cell: ({ value }) => <Text text={value} truncate />
      }),
      createColumn({
        Header: t("common:ui.user.field.email"),
        accessor: "email",
        width: 250
      }),
      createColumn({
        Header: t("common:ui.user.field.role"),
        accessor: "role",
        width: 150
      }),
      createColumnForDropdownMenu({
        accessor: "actionsMenu",
        onClickHandler: dropdownMenuActionHandler,
        className: "visible-on-hover"
      })
    ];
    return result.filter(col => col);
  }, [
    createColumnForStatus,
    t,
    createColumn,
    createColumnForDropdownMenu,
    dropdownMenuActionHandler
  ]);

  const data = React.useMemo(
    () =>
      users.map(user => {
        const statusIndicatorColorScheme =
          statusesToIndicatorColorScheme[user.status] || "dark";
        const role = user.role?.name ? (
          <Pill
            label={roleNameDisplay({ role: user.role, t })}
            size={PillSize.SMALL}
          />
        ) : (
          ""
        );

        const getActionsMenu = () => {
          const menuItems = [];
          if (canUpdate) {
            menuItems.push({
              name: t("ui.user.action.update", { context: type }),
              value: actions.updateUser
            });
            if (user.status === systemConstants.user.status.inactive) {
              menuItems.push({
                name: t("ui.user.action.activate", { context: type }),
                value: actions.activateUser
              });
            }
          }

          if (canDelete && user.status === systemConstants.user.status.active) {
            menuItems.push({
              name: t("ui.user.action.deactivate", { context: type }),
              value: actions.deactivateUser
            });
          }

          return menuItems;
        };

        return {
          id: user.id,
          statusIndicatorColorScheme,
          name: user.name,
          email: user.email,
          role,
          action: actions.updateUser,
          actionsMenu: getActionsMenu()
        };
      }),
    [users, t, canUpdate, canDelete, type]
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      onRowClick={rowId => handleAction(actions.updateUser, rowId)}
    />
  );
};

UserDataTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      role: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired,
      groups: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number
        })
      )
    })
  ),
  onUpdateUser: PropTypes.func.isRequired,
  onActivateUser: PropTypes.func.isRequired,
  onDeactivateUser: PropTypes.func.isRequired,
  canUpdate: PropTypes.bool,
  canDelete: PropTypes.bool,
  type: PropTypes.oneOf(["HOST", "CLIENT"])
};

export default UserDataTable;

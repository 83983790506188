import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { routeConstants } from "@constants/routeConstants";

import { systemConstants } from "@shared/constants/systemConstants";
import {
  useAddClientMutation,
  useAuthUser,
  useFeatures,
  useLazyGetClientMainEntitiesQuery,
  useToasts
} from "@shared/hooks";

import { getErrorMessage } from "@app/helpers/error";
import { useGoBack } from "@app/hooks";

import { Button, ButtonVariant } from "@atoms/Button";

import { ClientForm } from "@organisms/ClientForm";

import FormPageTemplate from "@components/templates/FormPageTemplate";

export const AddClient = () => {
  const { t } = useTranslation();
  const goBack = useGoBack();
  const navigate = useNavigate();
  const { showSuccess } = useToasts();
  const [
    addClient,
    { isSuccess, isLoading: isSubmitting, error: addClientError }
  ] = useAddClientMutation();
  const { isEnabled } = useFeatures();
  const [clientLogoModal, setClientLogoModal] = useState(<></>);
  const [logo, setLogo] = useState();
  const { user } = useAuthUser();
  const [error, setError] = useState("");
  const isHostUser = useMemo(() => user?.isHostUser, [user]);

  const externalEntitiesEnabled = useMemo(
    () => isEnabled(systemConstants.features.externalEntities),
    [isEnabled]
  );

  const [
    fetchClientEntities,
    {
      data: clientEntities,
      isSuccess: fetchClientEntitiesSuccess,
      error: fetchClientEntitiesError
    }
  ] = useLazyGetClientMainEntitiesQuery();

  useEffect(() => {
    if (fetchClientEntitiesSuccess && !clientEntities.length) {
      setError("No client entities found");
    }
  }, [fetchClientEntitiesSuccess, clientEntities]);

  useEffect(() => {
    if (fetchClientEntitiesError) {
      setError(getErrorMessage(fetchClientEntitiesError, t));
    }
  }, [fetchClientEntitiesError, t]);

  useEffect(() => {
    if (addClientError) {
      setError(getErrorMessage(addClientError, t));
    }
  }, [t, addClientError]);

  useEffect(() => {
    if (externalEntitiesEnabled && isHostUser) {
      fetchClientEntities({});
    }
  }, [externalEntitiesEnabled, isHostUser, fetchClientEntities]);

  const formatClient = useCallback(
    data => {
      if (!externalEntitiesEnabled) {
        return {
          name: data.name?.trim(),
          acn: data.acn?.trim(),
          abn: data.abn?.trim(),
          logo
        };
      } else {
        return {
          name: data.name?.value?.trim(),
          externalId: data.name?.id,
          acn: data.acn?.trim(),
          abn: data.abn?.trim(),
          logo
        };
      }
    },
    [externalEntitiesEnabled, logo]
  );

  const handleAddClient = useCallback(
    data => {
      const client = formatClient(data);
      addClient({ client })
        .unwrap()
        .then(addedClient => {
          showSuccess({
            key: "common:ui.client.added.message",
            actions: (
              <Button
                label={t("admin:ui.navigation.manageUsers.label", {
                  context: "CLIENT"
                })}
                variant={ButtonVariant.TEXT}
                onClick={() => {
                  navigate(`/admin/clients/${addedClient?.id}/users`);
                }}
              />
            )
          });
          goBack();
        });
    },
    [addClient, goBack, navigate, showSuccess, formatClient, t]
  );

  const yupSchema = useMemo(() => {
    if (!externalEntitiesEnabled) {
      return yup.object({
        name: yup.string().required(t("common:ui.client.field.nameError"))
      });
    }
    return yup.object({
      name: yup.object().shape({
        name: yup.string().required(t("common:ui.client.field.nameError")),
        value: yup.string().required(),
        id: yup.number().required()
      })
    });
  }, [externalEntitiesEnabled, t]);

  const formContents = useMemo(
    () => (
      <ClientForm
        setClientLogoModal={setClientLogoModal}
        logo={logo}
        setLogo={setLogo}
        clientEntities={clientEntities}
        externalEntitiesEnabled={externalEntitiesEnabled}
      />
    ),
    [logo, externalEntitiesEnabled, clientEntities]
  );

  const renderAddClientActions = isValid => {
    return (
      <>
        <Button
          label={t("common:ui.forms.cancel.label")}
          variant={ButtonVariant.TEXT}
          onClick={goBack}
        />
        <Button
          disabled={!isValid}
          label={t("common:ui.addClient.submitLabel")}
          type="submit"
        />
      </>
    );
  };

  return (
    <>
      <FormPageTemplate
        title={t("common:ui.addClient.title")}
        sticky
        form={{
          contents: formContents,
          yupSchema,
          handleSubmit: handleAddClient,
          handleCancel: goBack,
          disabled: isSubmitting || isSuccess,
          renderPageActions: renderAddClientActions
        }}
        isLoading={isSubmitting}
        renderRawForm={true}
        breadcrumbs={[
          {
            linkTo: routeConstants.manageClients,
            label: t("common:ui.manageClients.title")
          }
        ]}
        other={{
          error
        }}
      />
      {clientLogoModal}
    </>
  );
};

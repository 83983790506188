export enum MilestoneCalculatorDirection {
  BEFORE = "before",
  AFTER = "after"
}

export enum MilestoneCalculatorType {
  FIXED_DAYS_NO_INTERVAL = "fixedDaysNoInterval",
  FIXED_DAYS_INTERVAL = "fixedDaysInterval",
  NTH_OCCURRENCE_DAY = "nthOccurrenceDay"
}

export type FixedDaysNoIntervalCalculatorType = {
  type: MilestoneCalculatorType.FIXED_DAYS_NO_INTERVAL;
  yearAdjustment: number;
  month: number;
  day: number;
};

export enum Days {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6
}

export enum FixedDaysIntervalDay {
  WORKDAY = "workDay",
  DAY = "calendarDay"
}

export type FixedDaysIntervalCalculatorType = {
  type: MilestoneCalculatorType.FIXED_DAYS_INTERVAL;
  quantity: number;
  dayType: FixedDaysIntervalDay;
  direction: MilestoneCalculatorDirection;
  referencePoint: string;
};

export const enum MilestoneType {
  START = "START",
  STANDARD = "STANDARD",
  END = "END"
}

export type NthOccurrenceDayCalculatorType = {
  type: MilestoneCalculatorType.NTH_OCCURRENCE_DAY;
  quantity: number;
  dayOfWeek: string;
  direction: MilestoneCalculatorDirection;
  referencePoint: string;
};

export interface Project {
  startDate?: string;
  plannedCompletionDate?: string;
  properties: {
    year?: string;
  };
}

export type ProjectTemplateMilestone = {
  key: string;
  order: number;
  type: MilestoneType;
  calculator:
    | FixedDaysNoIntervalCalculatorType
    | FixedDaysIntervalCalculatorType
    | NthOccurrenceDayCalculatorType;
  date?: Date;
};

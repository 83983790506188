import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAuthUser } from "@shared/hooks";
import { useGetProjectDocumentsWithSameNamePresentQuery } from "@shared/services/documentService";

type DocumentNameCheck = {
  present: boolean;
  name: string;
  actionItemTypeKey: string;
};

type DocumentNameCheckResults = {
  checkList: DocumentNameCheck[];
  isActionTypeSmartForm: boolean;
  numberOfNameClashes: number;
  namesExistForBothInternalAndExternal: boolean;
  isOverrideMessageRequired: boolean;
};

type CheckDocumentRevisionCommentsRequiredParams = {
  projectId: number;
  fileNames: string[];
  internalOnly?: boolean;
};
export const useCheckDocumentRevisionCommentRequired = ({
  projectId,
  fileNames,
  internalOnly = false
}: CheckDocumentRevisionCommentsRequiredParams) => {
  const { user } = useAuthUser();
  const [check, setCheck] = useState<DocumentNameCheckResults | null>();
  const { t } = useTranslation();

  const {
    data: checkFilesResponse,
    isFetching,
    isLoading
  } = useGetProjectDocumentsWithSameNamePresentQuery(
    {
      projectId,
      documentNames: fileNames,
      includeInternalOnly: user.isHostUser
    },
    {
      skip: !projectId || !fileNames?.length || !user,
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    if (check && !fileNames?.length) {
      setCheck(null);
    }
  }, [check, fileNames]);

  useEffect(() => {
    if (!isFetching && checkFilesResponse) {
      const externalFilesWithSameNameExist = checkFilesResponse.present?.some(
        p => p.present
      );
      const checkList: DocumentNameCheck[] = internalOnly
        ? checkFilesResponse.internalDocumentsPresent
        : checkFilesResponse.present;
      const isActionTypeSmartForm: boolean = checkList?.some(
        c => c.actionItemTypeKey === "SMARTFORM"
      );

      const internalFilesWithSameNameExist =
        internalOnly &&
        checkFilesResponse.internalDocumentsPresent?.some(p => p.present);

      const clashingFiles = checkList?.filter(c => c.present);
      const numberOfNameClashes = clashingFiles?.length ?? 0;
      const namesExistForBothInternalAndExternal =
        user.isHostUser &&
        clashingFiles?.some(c => {
          const otherList: DocumentNameCheck[] = internalOnly
            ? checkFilesResponse.present
            : checkFilesResponse.internalDocumentsPresent;
          return (
            otherList
              ?.filter(o => o.present)
              .some((p: DocumentNameCheck) => p.name === c.name) ?? false
          );
        });
      const isOverrideMessageRequired = internalOnly
        ? internalFilesWithSameNameExist
        : externalFilesWithSameNameExist;
      const result: DocumentNameCheckResults = {
        checkList,
        isActionTypeSmartForm,
        numberOfNameClashes,
        isOverrideMessageRequired,
        namesExistForBothInternalAndExternal
      };
      setCheck(result);
    }
  }, [checkFilesResponse, internalOnly, isFetching, user.isHostUser]);

  const getMessageContext = useCallback(
    (
      namesExistForBothInternalAndExternal: boolean,
      uploadingInternalFiles: boolean
    ) => {
      if (!user?.isHostUser) {
        return "";
      }

      if (!namesExistForBothInternalAndExternal) {
        return uploadingInternalFiles ? "forInternal" : "forExternal";
      }

      return uploadingInternalFiles
        ? "forInternalAndExternalAlsoExists"
        : "forExternalAndInternalAlsoExists";
    },
    [user?.isHostUser]
  );

  const getCannotOverrideMessage = useCallback(() => {
    if (!check) {
      return "";
    }
    return t("common:ui.upload.error.fileName.cannotOverwrite", {
      count: check.numberOfNameClashes,
      context: getMessageContext(
        check.namesExistForBothInternalAndExternal,
        internalOnly
      )
    });
  }, [check, getMessageContext, internalOnly, t]);

  const getFilenameAlreadyExistedMessage = useCallback(() => {
    if (!check) {
      return "";
    }
    return t("common:ui.upload.error.fileName.existed", {
      count: check.numberOfNameClashes,
      context: getMessageContext(
        check.namesExistForBothInternalAndExternal,
        internalOnly
      )
    });
  }, [check, getMessageContext, internalOnly, t]);

  const getFilenameOnRequestAlreadyExistedMessage = useCallback(
    (entitiesEnabled: boolean) => {
      if (!check) {
        return "";
      }
      const key = entitiesEnabled
        ? "requests:requests.ui.populateRequestForm.withEntities.overrideMessageAlert"
        : "requests:requests.ui.populateRequestForm.withoutEntities.overrideMessageAlert";

      return t(key, {
        count: check.numberOfNameClashes,
        context: getMessageContext(
          check.namesExistForBothInternalAndExternal,
          internalOnly
        )
      });
    },
    [check, getMessageContext, internalOnly, t]
  );

  useEffect(() => {
    if (isFetching) {
      setCheck(null);
    }
  }, [isFetching, isLoading]);

  return {
    isCheckingDocumentNames: isLoading || isFetching,
    checkResult: check,
    getCannotOverrideMessage,
    getFilenameAlreadyExistedMessage,
    getFilenameOnRequestAlreadyExistedMessage
  };
};

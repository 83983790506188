import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { systemConstants } from "@shared/constants/systemConstants";
import { useCheckDocumentRevisionCommentRequired } from "@shared/hooks";
import { useAddQueryResponse } from "@shared/hooks/useAddQueryResponse";
import { useGetSupportedMimesQuery } from "@shared/services/documentMimesService";
import { useSendCommentMutation } from "@shared/services/smartformService";

import ActionItemCommentComposer from "@components/molecules/ActionItemCommentComposer/ActionItemCommentComposer";

const addFilesState = systemConstants.addFiles.state;
const responseTypes = systemConstants.project.queries.responses.responseTypes;

const CommentThreadModalActions = ({
  query,
  actionItemTypeConfig,
  onSuccess,
  hiddenData,
  customClassName,
  isNewComment,
  conversationId
}) => {
  const { t } = useTranslation();
  const { data: supportedDocumentMimes } = useGetSupportedMimesQuery({
    type: systemConstants.mimeDocumentType.document
  });
  const [uploadState, setUploadState] = useState(addFilesState.add);

  const [fileNames, setFileNames] = useState([]);
  const {
    checkResult: check,
    isCheckingDocumentNames: isCheckLoading,
    getCannotOverrideMessage,
    getFilenameAlreadyExistedMessage
  } = useCheckDocumentRevisionCommentRequired({
    projectId: query?.projectId,
    fileNames: fileNames,
    internalOnly: query?.internalOnly
  });
  const { isAdded, resetQuery, clearError } = useAddQueryResponse();
  const [errorMsg, setErrorMsg] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState(null);
  const [addResponse, { error: queryResponseError }] = useSendCommentMutation();
  const placeholderText = useMemo(() => {
    return isNewComment
      ? t("requests:requests.responses.addNewResponse.messageLabel")
      : t("requests:requests.responses.addResponse.messageLabel");
  }, [isNewComment, t]);

  useEffect(() => {
    if (!queryResponseError && isAdded) {
      return () => {
        resetQuery();
        setIsSaving(false);
      };
    } else if (queryResponseError) {
      setErrorMsg(queryResponseError?.data?.message);
      setIsSaving(false);
      setUploadState(addFilesState.add);
    }

    return () => {
      clearError();
    };
  }, [isAdded, queryResponseError, resetQuery, clearError]);

  const validationSchema = useMemo(() => {
    const responseMissingError = t(
      "requests:requests.responses.addResponse.messageLabel"
    );

    if (check?.isOverrideMessageRequired) {
      const overrideMessageError = t(
        "requests:requests.ui.populateRequestForm.validation.fieldRequired.errorMessage",
        { context: "overrideMessage" }
      );

      return yup.object().shape({
        response: yup.string().required(responseMissingError),
        overrideMessage: yup.string().required(overrideMessageError)
      });
    }

    return yup.object().shape({
      response: yup.string().required(responseMissingError)
    });
  }, [check?.isOverrideMessageRequired, t]);

  const doAddResponse = useCallback(
    uploadCompletedFiles => {
      if (isSaving) {
        return;
      }
      setIsSaving(true);
      resetQuery();
      clearError();
      const submitReqData = {
        ...formData,
        files: uploadCompletedFiles || [],
        responseType: responseTypes.message,
        isDraft: false,
        queryId: query.id,
        queryResponseConversationId: isNewComment ? null : conversationId,
        properties: { ...hiddenData }
      };

      addResponse(submitReqData)
        .unwrap()
        .then(data => {
          if (data) {
            onSuccess();
          }
        });
    },
    [
      addResponse,
      clearError,
      onSuccess,
      formData,
      hiddenData,
      isSaving,
      query.id,
      resetQuery,
      conversationId,
      isNewComment
    ]
  );

  const canAttachFiles = useMemo(() => {
    return [
      systemConstants.actionItemTypes.conversation,
      systemConstants.actionItemTypes.smartForm
    ].includes(actionItemTypeConfig?.type);
  }, [actionItemTypeConfig?.type]);

  const onFileUploadsCompleted = useCallback(
    uploadedFiles => {
      setUploadState(addFilesState.finished);
      const newFiles = Object.keys(uploadedFiles).map(key => {
        const file = uploadedFiles[key];
        return {
          filePathId: file.filePathId,
          name: file.name,
          projectId: query?.projectId
        };
      });
      doAddResponse(newFiles);
    },
    [doAddResponse, query?.projectId]
  );

  const onFileUploadsFailed = useCallback(error => {
    setIsSaving(false);
    setFormData(null);
    setErrorMsg(error);
    setUploadState(addFilesState.finished);
  }, []);

  const uploadFiles = () => {
    setUploadState(addFilesState.upload);
  };

  const shouldDisableSubmit = useMemo(() => {
    return isSaving || isCheckLoading || uploadState !== addFilesState.add;
  }, [isSaving, isCheckLoading, uploadState]);

  const handleSubmit = useCallback(
    data => {
      if (isCheckLoading) {
        return;
      }
      if (check?.isOverrideMessageRequired) {
        setFormData({
          response: data.response,
          overrideMessage: data.overrideMessage
        });
        canAttachFiles ? uploadFiles() : doAddResponse();
      } else {
        const files = Object.values(data.files);
        if (files.length && files.length !== check?.checkList?.length) {
          setFileNames(files.map(f => f.name));
        } else {
          setFormData({
            response: data.response
          });
          canAttachFiles ? uploadFiles() : doAddResponse();
        }
      }
    },
    [
      isCheckLoading,
      check?.isOverrideMessageRequired,
      check?.checkList?.length,
      canAttachFiles,
      doAddResponse
    ]
  );

  return (
    <ActionItemCommentComposer
      projectId={query?.projectId}
      internalOnly={query?.internalOnly}
      validationSchema={validationSchema}
      supportedDocumentMimes={supportedDocumentMimes ?? []}
      onSubmit={handleSubmit}
      disableSubmit={shouldDisableSubmit}
      canAttachFiles={canAttachFiles}
      onFileUploadsCompleted={onFileUploadsCompleted}
      onFileUploadsFailed={onFileUploadsFailed}
      errorMessage={t(errorMsg)}
      cannotOverrideFileErrorMessage={getCannotOverrideMessage()}
      fileNameAlreadyExistsErrorMessage={getFilenameAlreadyExistedMessage()}
      uploadState={uploadState}
      requiresOverrideMessage={check?.isOverrideMessageRequired}
      isActionTypeSmartForm={check?.isActionTypeSmartForm}
      customClassName={customClassName}
      placeholderText={placeholderText}
    />
  );
};

CommentThreadModalActions.defaultProps = {};

CommentThreadModalActions.propTypes = {
  query: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  hiddenData: PropTypes.object,
  customClassName: PropTypes.string,
  isNewComment: PropTypes.bool,
  conversationId: PropTypes.number
};

export default CommentThreadModalActions;

import React, { useCallback, useMemo, useRef } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { queryConstants } from "@constants/queryConstants";

import { systemConstants } from "@shared/constants/systemConstants";

import { classNames } from "@app/helpers/componentHelpers";
import { useDataTable } from "@app/hooks/useDataTable";

import { Icon, IconDesignStyle, IconFillStyle } from "@atoms/Icon";
import { Pill, PillFillStyle, PillVariant } from "@atoms/Pill";

import DataTable from "@components/molecules/DataTable";
import DeleteConfirm from "@components/molecules/DeleteConfirm/index";
import SmartFormEntitiesSelector from "@components/molecules/SmartFormModal/SmartFormEntitiesSelector";

const addAttachedFileState = systemConstants.addFiles.attachedFile.state;

const SmartFormModalTable = props => {
  const {
    data,
    header,
    onClickRemove,
    responseColumn,
    handleEntitiesChange,
    entities,
    isColumnHeaderHidden,
    showEntities,
    disableAnswerSubmissions,
    showDeletedDocuments,
    removeDeleted,
    webSheetMark,
    checkAnswerStatus,
    allowSameEntities,
    uploadState,
    uploadedFiles
  } = props;

  const { t } = useTranslation();

  const isErrorCheckStatus = data.some(d => d.errorCheckStatus);

  const tableRef = useRef();
  const { createColumn } = useDataTable();

  const renderDeleteIcon = useCallback(
    (value, index) => {
      if (disableAnswerSubmissions) {
        return <></>;
      }
      if (showDeletedDocuments) {
        return <DeleteConfirm onDelete={() => removeDeleted(value?.id)} />;
      }

      return (
        <Icon
          name={"delete"}
          designStyle={IconDesignStyle.MATERIAL_SYMBOLS}
          fillStyle={IconFillStyle.OUTLINED}
          className="document-state-cell__icon"
          onClick={() => {
            onClickRemove(value, index);
          }}
        />
      );
    },
    [
      disableAnswerSubmissions,
      onClickRemove,
      removeDeleted,
      showDeletedDocuments
    ]
  );

  const renderErrorCheckStatus = useCallback(
    ({ value }) => {
      if (value === queryConstants.queryErrorStatus.error) {
        return (
          <Pill
            variant={PillVariant.ERROR}
            fillStyle={PillFillStyle.FILLED}
            label={t("requests:requests.ui.smartForm.websheet.error")}
          />
        );
      }
      if (value === queryConstants.queryErrorStatus.noError) {
        return (
          <Pill
            variant={PillVariant.SUCCESS}
            fillStyle={PillFillStyle.FILLED}
            label={t("requests:requests.ui.smartForm.websheet.checked")}
          />
        );
      }
      return <></>;
    },
    [t]
  );

  const renderFileProgressIcon = useCallback(
    ({ value, index, locked = false }) => {
      if (value === null || value === undefined) {
        return <></>;
      }
      if (locked) {
        return (
          <Icon
            name={"lock"}
            designStyle={IconDesignStyle.MATERIAL_SYMBOLS}
            fillStyle={IconFillStyle.OUTLINED}
            className="document-state-cell__icon document-state-cell__icon--locked"
            hoverElement={t(
              "common:ui.projectSummary.messageNotAllEntitiesAccessToDocument"
            )}
          />
        );
      }
      const state = (() => {
        const uploadedFilesSet = new Set(uploadedFiles ?? []);
        if (!uploadedFilesSet.has(value?.name) && uploadState === "upload") {
          return addAttachedFileState.uploading;
        }
        return value?.state ?? null;
      })();
      switch (state) {
        case addAttachedFileState.uploading:
          return <div className="document-state-cell__uploading" />;
        case addAttachedFileState.uploaded:
          return (
            <Icon
              name={"done"}
              designStyle={IconDesignStyle.MATERIAL_SYMBOLS}
              fillStyle={IconFillStyle.OUTLINED}
              className="document-state-cell__icon"
            />
          );
        default:
          return renderDeleteIcon(value, index);
      }
    },
    [renderDeleteIcon, uploadState, uploadedFiles]
  );

  const renderEntities = useCallback(
    row => {
      if (showDeletedDocuments || row?.original?.locked) {
        const entities = row.original.entities?.map(e => e.name).join(", ");
        return (
          <div
            title={entities}
            className="smart-form-modal__table--truncated-list"
          >
            {entities}
          </div>
        );
      }

      return (
        <SmartFormEntitiesSelector
          row={row}
          allowSameEntities={allowSameEntities}
          data={data}
          disableAnswerSubmissions={disableAnswerSubmissions}
          entities={entities}
          checkAnswerStatus={checkAnswerStatus}
          handleEntitiesChange={handleEntitiesChange}
        />
      );
    },
    [
      checkAnswerStatus,
      data,
      disableAnswerSubmissions,
      entities,
      handleEntitiesChange,
      showDeletedDocuments,
      allowSameEntities
    ]
  );

  const handleFileProgressIcon = useCallback(
    ({ cell, value }) => (
      <div className="smart-form-modal__table__content-action">
        {renderFileProgressIcon({
          index: cell.row.index,
          value: value,
          locked: cell.row.original.locked
        })}
      </div>
    ),
    [renderFileProgressIcon]
  );

  const columns = useMemo(() => {
    return [
      isErrorCheckStatus &&
        createColumn({
          Header: "",
          accessor: "errorCheckStatus",
          className: "document-error-state-cell",
          id: "errorCheckStatus",
          width: 80,
          Cell: renderErrorCheckStatus
        }),
      webSheetMark &&
        !isErrorCheckStatus &&
        createColumn({
          Header: " ",
          width: 50,
          id: "websheetMark",
          accessor: ({ value }) => value?.id,
          className: "websheet-mark-cell",
          Cell: ({ cell }) => webSheetMark(cell?.value)
        }),
      createColumn({
        Header: header,
        accessor: "value",
        width: showEntities ? 215 : 380,
        id: "value",
        className: "response-cell",
        ...responseColumn
      }),
      showEntities &&
        createColumn({
          Header: t("requests:requests.configured.fields.entities.label"),
          accessor: "entities",
          className: "entities-cell",
          id: "entities",
          width: 380,
          Cell: ({ cell }) => (
            <div className="smart-form-modal__table--entities">
              {renderEntities(cell.row)}
            </div>
          )
        }),
      {
        Header: "",
        accessor: "value",
        className: "document-state-cell",
        id: "state",
        width: showDeletedDocuments ? 120 : 30,
        Cell: handleFileProgressIcon
      }
    ].filter(d => d);
  }, [
    createColumn,
    handleFileProgressIcon,
    header,
    isErrorCheckStatus,
    renderEntities,
    renderErrorCheckStatus,
    responseColumn,
    showDeletedDocuments,
    showEntities,
    t,
    webSheetMark
  ]);

  const getColumnHeaderHiddenClass = () => {
    return isColumnHeaderHidden ? "hidden-column-header" : "";
  };

  return (
    <DataTable
      ref={tableRef}
      id="smart-form-modal-table"
      className={classNames([
        "smart-form-modal__table",
        getColumnHeaderHiddenClass()
      ])}
      columns={columns}
      data={data}
      hideHeaders={false}
      height={300}
    />
  );
};

SmartFormModalTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object
      ]),
      entities: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.any
        })
      )
    })
  ),
  header: PropTypes.string,
  onClickRemove: PropTypes.func.isRequired,
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string
    })
  ),
  handleEntitiesChange: PropTypes.func,
  responseColumn: PropTypes.shape({
    Cell: PropTypes.func
  }),
  isColumnHeaderHidden: PropTypes.bool,
  disableAnswerSubmissions: PropTypes.bool,
  webSheetMark: PropTypes.func,
  uploadState: PropTypes.string,
  uploadedFiles: PropTypes.array
};

export default SmartFormModalTable;

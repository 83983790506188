import React, { useCallback, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Form from "@components/atoms/Form";
import InlineAlert from "@components/atoms/InlineAlert";

import "./ActionItemCommentComposer.scss";

function ActionItemCommentComposer(props) {
  const {
    validationSchema,
    supportedDocumentMimes,
    internalOnly,
    onSubmit,
    onFileUploadsCompleted,
    onFileUploadsFailed,
    disableSubmit,
    canAttachFiles,
    errorMessage,
    uploadState,
    requiresOverrideMessage,
    cannotOverrideFileErrorMessage,
    fileNameAlreadyExistsErrorMessage,
    projectId,
    isActionTypeSmartForm,
    customClassName,
    placeholderText
  } = props;
  const { t } = useTranslation();
  const [hasAttachments, setHasAttachements] = useState(false);
  const [attachmentsPortalRef, setAttachmentsPortalRef] = useState(null);
  const measuredAttachmentsPortalRef = useCallback(node => {
    setAttachmentsPortalRef(node);
  }, []);

  const onFileSelected = useCallback(selectedFiles => {
    setHasAttachements(Object.values(selectedFiles).length > 0);
  }, []);

  return (
    <div className="action-item-comment-composer">
      <Form
        handleSubmit={onSubmit}
        yupSchema={validationSchema}
        disabled={disableSubmit}
        customFormClassName={customClassName}
        submitLabel={""}
        submitIconName={"send"}
      >
        {errorMessage && (
          <div className="action-item-comment-composer__contents-error-box">
            <InlineAlert type="error" message={errorMessage} />
          </div>
        )}
        <div className="ot-form__add-text-document-response">
          <Form.TextArea
            label={t("requests:requests.responses.addResponse.messageLabel")}
            name="response"
            placeholder={placeholderText}
            maxLength={8192}
            data-testid="message"
            required={true}
            autoFocus={true}
          />
          {canAttachFiles && (
            <Form.UploadDocuments
              name={"files"}
              supportedDocumentMimes={supportedDocumentMimes}
              internalOnly={internalOnly}
              projectId={projectId}
              state={uploadState}
              onUploadsComplete={onFileUploadsCompleted}
              onUploadsFailed={onFileUploadsFailed}
              disableFileListActions={requiresOverrideMessage}
              disableUploads={requiresOverrideMessage}
              attachmentsPortal={attachmentsPortalRef}
              attachmentsColumnMode="flex"
              onChange={onFileSelected}
            />
          )}
        </div>
        {hasAttachments && <div ref={measuredAttachmentsPortalRef}></div>}
        {requiresOverrideMessage && (
          <div className="action-item-comment-composer__contents-version-comment">
            {isActionTypeSmartForm ? (
              <InlineAlert
                type="warning"
                message={cannotOverrideFileErrorMessage}
              ></InlineAlert>
            ) : (
              <>
                <InlineAlert
                  type="warning"
                  message={fileNameAlreadyExistsErrorMessage}
                ></InlineAlert>
                <Form.TextArea
                  label={t("common:ui.documents.overrideMessage.label")}
                  name="overrideMessage"
                  maxLength="255"
                  data-testid="overrideMessage"
                  required={true}
                />
              </>
            )}
          </div>
        )}
      </Form>
    </div>
  );
}

ActionItemCommentComposer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  supportedDocumentMimes: PropTypes.array.isRequired,
  onFileUploadsCompleted: PropTypes.func,
  onFileUploadsFailed: PropTypes.func,
  disableSubmit: PropTypes.bool,
  canAttachFiles: PropTypes.bool,
  errorMessage: PropTypes.string,
  uploadState: PropTypes.string,
  requiresOverrideMessage: PropTypes.bool,
  cannotOverrideFileErrorMessage: PropTypes.string,
  fileNameAlreadyExistsErrorMessage: PropTypes.string,
  projectId: PropTypes.number,
  isActionTypeSmartForm: PropTypes.bool,
  customClassName: PropTypes.string,
  placeholderText: PropTypes.string,
  internalOnly: PropTypes.bool
};

export default ActionItemCommentComposer;

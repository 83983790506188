import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { useFeatures } from "@shared/hooks/useFeatures.jsx";
import { useHostUserProfile } from "@shared/hooks/useHostUserProfile";
import { useAddHostUserMutation } from "@shared/services";

import { getErrorMessage } from "@app/helpers/error";

import UserForm from "@components/organisms/UserForm/UserForm";

const AddHostUser = () => {
  const { t } = useTranslation();
  const {
    teamUserFields,
    roles,
    error: roleError,
    projectsList
  } = useHostUserProfile();
  const authentication = useSelector(state => state.authentication);
  const [
    addHostUser,
    { isSuccess: isHostUserAdded, isLoading, error: addError }
  ] = useAddHostUserMutation();
  const navigate = useNavigate();
  const { isExternalHostUsersEnabled } = useFeatures();

  const error = useMemo(() => addError || roleError, [addError, roleError]);

  useEffect(() => {
    if (isHostUserAdded) {
      navigate(routeConstants.manageUsers);
    }
  }, [isHostUserAdded, navigate]);

  const getConfig = key =>
    teamUserFields?.find(field => field.key === key) ?? {};

  const addRole = () => {
    if (roles?.length > 1) {
      return {
        key: "role",
        path: "role.name",
        type: "dropdown",
        required: true,
        items: roles?.map(role => ({
          ...role,
          name: t(role.name),
          value: role.name
        })),
        translate: true
      };
    }
    return {};
  };

  const handleSubmit = user => {
    if (!user.roleId) {
      user.roleId = roles?.[0]?.id;
    }
    user.hostId = authentication.user.hostId;
    addHostUser({ user });
  };

  const formConfig = [
    {
      key: "firstname",
      type: "text",
      required: true
    },
    {
      key: "lastname",
      type: "text",
      required: true
    },
    {
      key: "email",
      type: "text",
      required: true
    },
    getConfig("jobTitle"),
    addRole(),
    {
      key: "projects",
      type: "multiselect",
      required: false,
      items: projectsList,
      defaultValue: [],
      placeholder: t("common:user.configured.fields.projects.recommended")
    },
    ...(isExternalHostUsersEnabled ? [getConfig("EXTERNAL_USERNAME")] : []),
    getConfig("MOBILE_NUMBER"),
    getConfig("BUSINESS_NUMBER"),
    {
      key: "NOTIFICATION",
      type: "radio",
      defaultValue: "digest",
      items: ["digest", "each"],
      path: "properties.notification.email"
    },
    getConfig("LICENSE")
  ];

  return (
    <UserForm
      title={t("ui.manageUsers.addTitle_team")}
      breadcrumbs={[
        {
          linkTo: routeConstants.manageUsers,
          label: t("common:ui.manageUsers.title", { context: "HOST" })
        }
      ]}
      formConfig={formConfig}
      handleSubmit={handleSubmit}
      submitLabel={t("common:ui.manageUsers.addSubmit", { context: "HOST" })}
      loading={isLoading}
      other={{
        error: error ? getErrorMessage(error, t) : ""
      }}
    />
  );
};

export default AddHostUser;

import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  manageDocumentDownloadsActions,
  manageProjectQueriesActions,
  manageQueryResponsesActions
} from "@shared/actions";
import { smartFormConstants, systemConstants } from "@shared/constants";
import documentUtilities from "@shared/helpers/documentUtilities";
import {
  useAuthUser,
  useCurrentProject,
  useGetHostObject,
  useGetProjectByIdQuery,
  useGetProjectMembers,
  useGetProjectQuery,
  useLazyGetActionItemMetadataQuery,
  useLazyGetAnswersQuery,
  useRequestPageNavigator,
  useSaveAnswerMutation,
  useToasts,
  useUIConfig
} from "@shared/hooks";
import usePubSub from "@shared/hooks/usePubSub";
import {
  useLazyGetActionItemQuery,
  useLazyGetWorkflowStepQuery,
  useUpdateActionItemFieldMutation,
  useUpdateWorkflowMutation
} from "@shared/services/actionItemService";
import { useGetActionItemTypesQuery } from "@shared/services/actionItemTypesService";

import Popup from "@shared-components/popup/Popup";

import { routeConstants } from "@app/constants/routeConstants";
import {
  getActionItemTypeDisplayName,
  getSmartFormPageHeader
} from "@app/helpers";
import { isClosed, isError } from "@app/helpers/actionItems";
import { isAllowedToUpdateProperties } from "@app/helpers/actionItems.js";
import { populateRelevantEntitiesByIdToObject } from "@app/helpers/entity";
import { smartFormActions, smartFormStructure } from "@app/helpers/smartForm";
import { smartFormUpdatedBy } from "@app/helpers/smartForm/smartFormConstants";

import { Icon, IconColor, IconFillStyle } from "@atoms/Icon";

import { ApproveRejectQueryFlag } from "@organisms/ApproveRejectQueryFlag";
import ActionConfirmation from "@organisms/QueryActions/ActionConfirmation";

import InfoMessageBox from "@components/molecules/InfoMessageBox";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal";
import RequestDetailsBox from "@components/molecules/RequestDetailsBox";
import RequestEntitiesBox from "@components/molecules/RequestEntitiesBox";
import SmartFormModal from "@components/molecules/SmartFormModal";
import EditActionTags from "@components/organisms/EditActionTags";
import QueryActions, {
  buttonActionConstants
} from "@components/organisms/QueryActions";
import AddQueryResponse from "@components/organisms/QueryActions/AddQueryResponse";
import ReassignQuery from "@components/organisms/QueryActions/ReassignQuery";
import SendToHost from "@components/organisms/QueryActions/SendToHost";
import UpdateCopiedTo from "@components/organisms/QueryActions/UpdateCopiedTo";
import SmartForm from "@components/organisms/SmartForm/SmartForm";
import CommentsModal from "@components/organisms/SmartFormCommentsModal";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./SmartFormPage.scss";

const PUBSUB_SMARTFORM_EVENTS =
  systemConstants.project.queries.events.smartform;
const statusTypes = systemConstants.project.queries.status;
const smartFormActionItemType = systemConstants.actionItemTypes.smartForm;
const workflowActionTypes =
  systemConstants.project.queries.workflow.actionTypes;
const { contentKeys } = smartFormConstants;

const SmartFormPage = () => {
  const { t } = useTranslation();
  const { errorFetchingQuery } = useGetProjectQuery();
  const location = useLocation();
  const [query, setQuery] = useState(location.state?.query);
  const { user: authUser } = useAuthUser();
  const [errorMessage, setErrorMessage] = useState("");
  const [smartFormConfig, setSmartFormConfig] = useState(null);
  const [showReminderDate, setShowReminderDate] = useState(false);
  const [pillConfig, setPillConfig] = useState();
  const dispatch = useDispatch();
  const manageQueryResponses = useSelector(state => state.manageQueryResponses);
  const { navigateToRequestPage } = useRequestPageNavigator();
  const navigate = useNavigate();
  const { projectId, queryId } = useParams();
  const { uiConfig } = useUIConfig();
  const { currentProject, onChangeCurrentProject } = useCurrentProject();

  const [responseActionType, setResponseActionType] = useState("");

  const { data: projectToSelect, error: isInvalidProject } =
    useGetProjectByIdQuery(
      { projectId },
      { skip: !projectId || currentProject?.id === projectId }
    );
  const { data: actionItemTypes } = useGetActionItemTypesQuery(
    {
      engagementTypeId: currentProject?.engagement.engagementTypeId,
      projectId: currentProject?.id
    },
    {
      skip: currentProject?.engagement.engagementTypeId === undefined
    }
  );
  const [getQueryMetadata, { data: queryMetadata }] =
    useLazyGetActionItemMetadataQuery();
  const { members } = useGetProjectMembers(currentProject);
  const [getAnswers, { data: gotAnswers, error: getAnswersError }] =
    useLazyGetAnswersQuery();
  const [saveAnswer, { data: savedAnswers }] = useSaveAnswerMutation();
  const [updateWorkflow, { isLoading: isUpdatingWorkflow }] =
    useUpdateWorkflowMutation();
  const [saveAnswerCount, setSaveAnswerCount] = useState(0);
  const [latestAnswers, setLatestAnswers] = useState(undefined);
  const { showSendToHostSuccess, showSendToHostErrorToast, showError } =
    useToasts();
  const [answers, setAnswers] = useState(undefined);

  const [isOTUpdate, setIsOTUpdate] = useState(false);
  const [answersUpdatedAt, setAnswersUpdatedAt] = useState("1970-01-01");
  const [modalOpen, setModalOpen] = useState(false);
  const [downloadError, setDownloadError] = useState();
  const [contentKey, setContentKey] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [isLoadingPageFirstTime, setIsLoadingPageFirstTime] = useState(true);
  const { hostObject } = useGetHostObject();

  const relevantEntitiesById = useMemo(() => {
    return populateRelevantEntitiesByIdToObject(currentProject, query);
  }, [currentProject, query]);

  const [isLockFinalisedBtn, setIsLockFinalisedBtn] = useState(false);
  const refreshAnswers = usePubSub();
  const [getQuery, { data: fetchedQuery, error: fetchQueryDataError }] =
    useLazyGetActionItemQuery();

  const [updateQueryField, { error: updateQueryError, data: updatedQuery }] =
    useUpdateActionItemFieldMutation();

  const [actionConfirmationModalContent, setActionConfirmationModalContent] =
    useState({ title: "", message: "", submitButtonLabel: "", action: "" });

  const [
    fetchWorkflowStep,
    { data: workFlowSteps, isFetching: isFetchingWorkflowStep }
  ] = useLazyGetWorkflowStepQuery();

  const pendingFlag = useMemo(() => {
    const pendingConfig = smartFormConfig?.flags?.find(
      flag => flag.key === "PENDING"
    );

    const q = queryMetadata ?? query;

    const pendingState = q?.pendingState ?? null;
    return {
      isPendingFlagEnabled: !!pendingConfig,
      pendingState,
      pendingConfig,
      isPending: q?.pending
    };
  }, [smartFormConfig, query, queryMetadata]);

  const handleUpdateQueryField = useCallback(
    ({ field, value }) => {
      if (queryId) {
        updateQueryField({
          queryId,
          field,
          value
        });
      }
    },
    [queryId, updateQueryField]
  );
  const handleOpenModal = useCallback((contentKey, question) => {
    setModalOpen(true);
    setCurrentQuestion(question);
    setContentKey(contentKey);
  }, []);

  const isProjectMember = useCallback(
    (action, cb) => {
      if (
        authUser?.isHostUser &&
        !members.hostUsers?.some(member => member.id === authUser.id)
      ) {
        handleOpenModal("projectAccess");
        setErrorMessage(
          t("common:project.accessRequiredToDoAction", { action })
        );
      } else {
        cb();
      }
    },
    [authUser?.isHostUser, authUser.id, members.hostUsers, handleOpenModal, t]
  );

  const maybeFetchWorkflowStep = useCallback(() => {
    const workFlowEnabled = !!smartFormConfig?.menus
      ?.find(b => b.name === "BUTTONS")
      ?.actions?.find(m => m.action === "WORKFLOW");

    if (workFlowEnabled) {
      fetchWorkflowStep({ queryId });
    }
  }, [fetchWorkflowStep, queryId, smartFormConfig?.menus]);

  const handleUpdateWorkflow = useCallback(
    action => {
      if (!queryId) {
        return;
      }
      updateWorkflow({
        queryId,
        action
      })
        .unwrap()
        .then(data => {
          const confirmationData = data?.beforeTrigger?.config.find(
            q => q.type === "confirmationDialog"
          );

          if (confirmationData) {
            setActionConfirmationModalContent({
              title: confirmationData.title,
              message: confirmationData.message,
              submitButtonLabel: confirmationData.submitButtonLabel,
              action: data?.beforeTrigger?.action
            });
            handleOpenModal(contentKeys.WORKFLOW_ACTION_CONFIRM);
          } else {
            setModalOpen(false);
            if (data.updatedQuery) {
              setQuery(data.updatedQuery);
            }

            maybeFetchWorkflowStep();
          }
        })
        .catch(e => {
          showError({
            key: e.data.key ?? e.data.message
          });
        });
    },
    [
      updateWorkflow,
      queryId,
      handleOpenModal,
      setActionConfirmationModalContent,
      maybeFetchWorkflowStep,
      showError
    ]
  );

  const membershipCheckThenHandleUpdateWorkflow = useCallback(
    action => {
      isProjectMember(
        t(
          "requests:requests.ui.requestDetailsPage.accessRequiredActionWorkflow"
        ),
        () => {
          handleUpdateWorkflow(action);
        }
      );
    },
    [handleUpdateWorkflow, isProjectMember, t]
  );

  const handleSendToHost = useCallback(
    action => {
      if (queryId) {
        updateWorkflow({
          queryId,
          action
        })
          .unwrap()
          .then(data => {
            if (data?.success) {
              setModalOpen(false);
              getQuery({ queryId });
              setIsLockFinalisedBtn(false);
              showSendToHostSuccess();
            }
          })
          .catch(error => {
            setErrorMessage(
              error?.data?.key ? t(error.data.key) : error?.data?.message
            );
            setIsLockFinalisedBtn(false);
            showSendToHostErrorToast({ host: hostObject?.host.name });
          });
      }
    },
    [
      updateWorkflow,
      showSendToHostErrorToast,
      getQuery,
      hostObject,
      t,
      showSendToHostSuccess,
      queryId
    ]
  );

  useEffect(() => {
    if (
      getAnswersError?.status === 403 ||
      fetchQueryDataError?.status === 403
    ) {
      navigate(routeConstants.notFound);
    }
  }, [getAnswersError, fetchQueryDataError]);

  useEffect(() => {
    if (!latestAnswers || savedAnswers?.updatedAt > latestAnswers?.updatedAt) {
      setLatestAnswers(savedAnswers);
    }
  }, [latestAnswers, savedAnswers]);

  useEffect(() => {
    if (!projectId && currentProject && !queryId && query) {
      navigate(`/projects/${currentProject.id}/smartforms/${query.id}`, {
        replace: true
      });
    }
  }, [navigate, currentProject, projectId, query, queryId]);

  useEffect(() => {
    refreshAnswers.subscribe(PUBSUB_SMARTFORM_EVENTS.refreshAnswers.key);
    refreshAnswers.subscribe(PUBSUB_SMARTFORM_EVENTS.refreshMetadata.key);
  }, [refreshAnswers]);

  useEffect(() => {
    if (refreshAnswers?.value?.queryId == queryId) {
      switch (refreshAnswers?.value?.type) {
        case PUBSUB_SMARTFORM_EVENTS.refreshAnswers.type:
          getAnswers({ queryId });
          break;
        case PUBSUB_SMARTFORM_EVENTS.refreshMetadata.type:
          getQueryMetadata({ queryId });
          break;
        default:
          break;
      }
    }
  }, [refreshAnswers?.value, getAnswers, queryId, getQueryMetadata]);

  useEffect(() => {
    getAnswers({ queryId });
  }, [queryId, getAnswers]);

  useEffect(() => {
    if (gotAnswers?.updatedAt > answersUpdatedAt && saveAnswerCount === 0) {
      setAnswers(gotAnswers.answers);
      setAnswersUpdatedAt(gotAnswers.updatedAt);
    }
  }, [
    answersUpdatedAt,
    gotAnswers?.answers,
    gotAnswers?.updatedAt,
    saveAnswerCount
  ]);

  useEffect(() => {
    if (latestAnswers?.updatedAt > answersUpdatedAt && saveAnswerCount === 0) {
      setAnswers(latestAnswers.answers);
      setAnswersUpdatedAt(latestAnswers.updatedAt);
    }
  }, [
    answersUpdatedAt,
    latestAnswers?.answers,
    latestAnswers?.updatedAt,
    saveAnswerCount
  ]);

  useEffect(() => {
    if (isInvalidProject || errorFetchingQuery) {
      navigate("not-found", { replace: true });
    }
  }, [errorFetchingQuery, isInvalidProject, navigate]);

  useEffect(
    () => () => dispatch(manageDocumentDownloadsActions.reset()),
    [dispatch]
  );

  useEffect(() => {
    if (projectToSelect && currentProject?.id !== projectToSelect.id) {
      onChangeCurrentProject(projectToSelect);
    }
  }, [projectToSelect, currentProject, onChangeCurrentProject]);

  useEffect(() => {
    return () => {
      dispatch(manageDocumentDownloadsActions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (uiConfig?.pills) {
      setPillConfig(uiConfig.pills);
    }
  }, [uiConfig?.pills]);

  useEffect(() => {
    if (fetchedQuery && projectId) {
      if (fetchedQuery.projectId != projectId) {
        navigate(routeConstants.login);
      } else {
        setQuery(fetchedQuery);
        setIsLoadingPageFirstTime(false);
      }
    } else if (isLoadingPageFirstTime && queryId) {
      getQuery({ queryId });
    }
  }, [
    fetchedQuery,
    isLoadingPageFirstTime,
    navigate,
    projectId,
    queryId,
    getQuery
  ]);

  useEffect(() => {
    if (query?.id) {
      dispatch(manageQueryResponsesActions.getQueryResponses(query.id));
    }
  }, [dispatch, query?.id, manageQueryResponses.isAdded]);

  useEffect(() => {
    if (
      updatedQuery &&
      query?.id &&
      updatedQuery.id === query.id &&
      currentProject
    ) {
      setQuery({ ...updatedQuery });
      dispatch(manageProjectQueriesActions.getMyQueryCount(currentProject));
    }
  }, [updatedQuery, dispatch, currentProject, query?.id]);

  useEffect(() => {
    if (!query?.queryType) {
      return;
    }
    const actionItemTypesMatchingKey = actionItemTypes?.filter(
      actionItemType => actionItemType.configuration.key === query.queryType
    );

    const filteredActionItemTypes = actionItemTypesMatchingKey?.map(q => ({
      ...q.configuration,
      buttons: currentProject?.configuration?.buttons,
      queryName: q.name
    }));

    if (filteredActionItemTypes?.length > 0) {
      setSmartFormConfig(filteredActionItemTypes[0]);
    }
  }, [actionItemTypes, query?.queryType, currentProject]);

  useEffect(() => {
    if (!smartFormConfig?.type) {
      return;
    }
    // The actual config type isn't SmartForm redirect appropriately
    if (smartFormConfig.type !== smartFormActionItemType) {
      navigateToRequestPage(query?.id, query?.projectId, smartFormConfig.type, {
        replace: true
      });
    }
  }, [navigate, navigateToRequestPage, query, smartFormConfig]);

  useEffect(
    () => () => {
      dispatch(manageDocumentDownloadsActions.reset());
    },
    [dispatch]
  );

  const handleFileDownload = useCallback(
    ({ id, name: fileName, documentRevisionId }) => {
      if (documentRevisionId) {
        dispatch(
          manageDocumentDownloadsActions.downloadDocumentRevision({
            id,
            name: documentUtilities.stripIdFromFileName({ fileName, id }),
            documentRevisionId
          })
        );
      } else if (id) {
        dispatch(
          manageDocumentDownloadsActions.downloadCurrentDocument({
            id,
            name: documentUtilities.stripIdFromFileName({ fileName, id })
          })
        );
      }
    },
    [dispatch]
  );

  const refetchQuery = useCallback(
    queryId => {
      getQuery({ queryId });
    },
    [getQuery]
  );

  const handleCloseModal = useCallback((setErrorToEmpty = false) => {
    setModalOpen(false);
    setContentKey("");
    if (setErrorToEmpty) {
      setErrorMessage("");
    }
  }, []);

  const handleModalUpdate = useCallback(() => {
    refetchQuery(queryId);
    handleCloseModal();
  }, [handleCloseModal, refetchQuery, queryId]);

  const onQueryReassigned = useCallback(
    assignedTo => {
      setQuery(prevQuery => ({
        ...prevQuery,
        assignedTo
      }));
      handleCloseModal();
    },
    [handleCloseModal]
  );

  const getProjectAccessModal = () => (
    <ProjectAccessModal
      visibility={true}
      handleClose={handleCloseModal}
      message={errorMessage}
    />
  );

  const getReassignModal = () => {
    if (
      authUser?.isHostUser &&
      !members.hostUsers?.some(member => member.id === authUser.id)
    ) {
      return getProjectAccessModal();
    } else {
      return (
        <Popup
          visibility={true}
          handleOutsideClick={handleCloseModal}
          width="50rem"
        >
          <ReassignQuery
            project={currentProject}
            query={query}
            onQueryReassigned={onQueryReassigned}
            onCancel={handleCloseModal}
          />
        </Popup>
      );
    }
  };

  const handleSubmitSmartForm = ({ action }) => {
    handleSendToHost({ action });
    setIsLockFinalisedBtn(true);
  };

  const handleActionConfirmation = () => {
    handleUpdateWorkflow({
      action: actionConfirmationModalContent.action,
      confirmed: true
    });
  };

  const getWorkflowConfirmationModal = () => {
    return (
      <Popup
        visibility={true}
        handleOutsideClick={handleCloseModal}
        width="50rem"
      >
        <ActionConfirmation
          title={actionConfirmationModalContent.title}
          message={actionConfirmationModalContent.message}
          submitButtonLabel={actionConfirmationModalContent.submitButtonLabel}
          handleSubmit={handleActionConfirmation}
          handleCancel={handleCloseModal}
          error={errorMessage}
        />
      </Popup>
    );
  };

  const getFinaliseModal = () => {
    if (
      authUser?.isHostUser &&
      !members.hostUsers?.some(member => member.id === authUser.id)
    ) {
      return getProjectAccessModal();
    } else {
      const buttonsActions = smartFormConfig?.menus?.find(
        menu => menu.name === "BUTTONS"
      );
      const sendToHostAction = buttonsActions?.actions?.find(
        a => a.action === buttonActionConstants.sendToHost
      );
      return (
        <Popup
          visibility={true}
          handleOutsideClick={handleCloseModal}
          width="50rem"
        >
          <SendToHost
            lockSubmit={isLockFinalisedBtn}
            handleSubmit={() =>
              handleSubmitSmartForm({ action: sendToHostAction.action })
            }
            handleCancel={handleCloseModal}
            error={errorMessage}
            i18nActionKey={
              sendToHostAction?.i18nActionKey ?? sendToHostAction?.action
            }
          />
        </Popup>
      );
    }
  };

  const getEditTagsModal = () => (
    <Popup
      visibility={true}
      handleOutsideClick={handleCloseModal}
      width="50rem"
    >
      <EditActionTags
        project={currentProject}
        query={query}
        queryConfig={smartFormConfig}
        onUpdate={handleModalUpdate}
        onCancel={handleCloseModal}
      />
    </Popup>
  );

  const getCopiedToModal = () => (
    <Popup
      visibility={true}
      handleOutsideClick={handleCloseModal}
      width="50rem"
    >
      <UpdateCopiedTo
        project={currentProject}
        query={query}
        queryConfig={smartFormConfig}
        onUpdate={handleModalUpdate}
        onCancel={handleCloseModal}
      />
    </Popup>
  );

  const renderSmartFormModal = useMemo(
    () => (
      <SmartFormModal
        projectId={projectId}
        queryConfig={smartFormConfig}
        queryId={queryId}
        handleClose={handleCloseModal}
        message={errorMessage}
        question={currentQuestion}
        answer={smartFormStructure.getAnswerValuesForQuestion(
          currentQuestion?.questionId,
          answers,
          currentQuestion?.relevantEntities
        )}
        setIsOTUpdate={setIsOTUpdate}
        relevantEntitiesById={relevantEntitiesById}
        disableAnswerSubmissions={
          isClosed({ status: query?.status }) ||
          isError({ status: query?.status })
        }
        handleFileDownload={handleFileDownload}
      />
    ),
    [
      projectId,
      queryId,
      handleCloseModal,
      errorMessage,
      currentQuestion,
      answers,
      relevantEntitiesById,
      query?.status,
      handleFileDownload
    ]
  );

  const getCommentsModal = () => (
    <CommentsModal
      query={query}
      question={currentQuestion}
      onClose={handleCloseModal}
      handleFileDownload={handleFileDownload}
      smartFormConfig={smartFormConfig}
    />
  );

  const handlePopupAddClick = () => {
    refetchQuery(queryId);
    handleCloseModal();
  };

  const getApproveRejectModal = () => (
    <Popup handleOutsideClick={false} width="60rem" visibility>
      <AddQueryResponse
        query={query}
        queryConfig={smartFormConfig}
        project={currentProject}
        queryType={query.queryType}
        onSuccess={handlePopupAddClick}
        onCancel={handleCloseModal}
        responseType={responseActionType}
      />
    </Popup>
  );

  const getModalContent = () => {
    switch (contentKey) {
      case contentKeys.REASSIGN:
        return getReassignModal();
      case contentKeys.FINALISE:
        return getFinaliseModal();
      case contentKeys.EDIT_TAGS:
        return getEditTagsModal();
      case contentKeys.COPIED_TO:
        return getCopiedToModal();
      case contentKeys.COMMENT:
        return getCommentsModal();
      case contentKeys.APPROVE:
      case contentKeys.REJECT:
        return getApproveRejectModal();
      case "projectAccess":
        return getProjectAccessModal();
      case "answerPerEntity":
      case "websheet":
        return renderSmartFormModal;
      case contentKeys.WORKFLOW_ACTION_CONFIRM:
        return getWorkflowConfirmationModal();
      default:
        return;
    }
  };

  const closeRequest = useCallback(() => {
    isProjectMember(
      t(
        "requests:requests.ui.requestDetailsPage.accessRequiredActionCloseRequest"
      ),
      () => {
        dispatch(manageDocumentDownloadsActions.resetError());
        membershipCheckThenHandleUpdateWorkflow({
          action: statusTypes.closed,
          actionType: workflowActionTypes.legacy
        });
      }
    );
  }, [dispatch, isProjectMember, membershipCheckThenHandleUpdateWorkflow, t]);

  const reopenRequest = useCallback(() => {
    dispatch(manageDocumentDownloadsActions.resetError());
    membershipCheckThenHandleUpdateWorkflow({
      action: statusTypes.open,
      actionType: workflowActionTypes.legacy
    });
  }, [dispatch, membershipCheckThenHandleUpdateWorkflow]);

  const handleSaveAnswers = useCallback(
    ({ questionId, answer, relevantEntities }) => {
      isProjectMember(
        t(
          "requests:requests.ui.requestDetailsPage.accessRequiredAnswerSmartForm"
        ),
        () => {
          // optimistically set the local state to the new answer & update UI
          setAnswers(prev => {
            let entities = [];
            //Get entities from previous answer state if exists
            if (prev?.[questionId]?.[0]?.entities) {
              entities = prev[questionId][0].entities;
            }
            //Use relevant entities list provided for conditional questions
            else if (relevantEntities?.length > 0) {
              entities = relevantEntities;
            }
            // Relevant entities list is not provided is question is not conditional, use the entities list from the query
            else {
              entities = query.entities ?? [];
            }
            return prev
              ? {
                  ...prev,
                  [questionId]: [
                    {
                      value: answer,
                      updatedBy: smartFormUpdatedBy.OT,
                      entities
                    }
                  ]
                }
              : {
                  [questionId]: [
                    {
                      value: answer,
                      updatedBy: smartFormUpdatedBy.OT,
                      entities
                    }
                  ]
                };
          });

          // Counting the call to save answer endpoint to prevent answer flickering due to refetch.
          setSaveAnswerCount(prev => prev + 1);
          setIsOTUpdate(true);
          saveAnswer({
            queryId,
            questionId,
            answer: { value: answer, updatedBy: smartFormUpdatedBy.OT }
          })
            .unwrap()
            .finally(() => setSaveAnswerCount(prev => prev - 1));
        }
      );
    },
    [isProjectMember, query?.entities, queryId, saveAnswer, t]
  );

  useEffect(() => {
    const reminderDateField = smartFormConfig?.fields?.find(
      f => f.key === "reminderDate"
    );

    setShowReminderDate(
      reminderDateField &&
        !(
          reminderDateField.availableTo &&
          !authUser.isMemberOfUserGroup(reminderDateField.availableTo)
        )
    );
  }, [
    authUser,
    smartFormConfig,
    smartFormConfig?.fields,
    smartFormConfig?.questionList
  ]);

  useEffect(() => {
    maybeFetchWorkflowStep();
  }, [maybeFetchWorkflowStep]);

  const headerActions = useCallback(() => {
    const requestHandlers = {
      [buttonActionConstants.reassign]: () =>
        handleOpenModal(contentKeys.REASSIGN),
      [buttonActionConstants.closeRequest]: closeRequest,
      [buttonActionConstants.reopen]: reopenRequest,
      [buttonActionConstants.download]: setDownloadError,
      [buttonActionConstants.sendToHost]: () =>
        handleOpenModal(contentKeys.FINALISE),
      [buttonActionConstants.closePage]: () => navigate(-1),
      [buttonActionConstants.approve]: () => {
        setResponseActionType(contentKeys.APPROVE);
        handleOpenModal(contentKeys.APPROVE);
      },
      [buttonActionConstants.reject]: () => {
        setResponseActionType(contentKeys.REJECT);
        handleOpenModal(contentKeys.REJECT);
      },
      [buttonActionConstants.workflow]: actionKey => {
        membershipCheckThenHandleUpdateWorkflow({ action: actionKey });
      }
    };

    const disableWorkflowAction = isFetchingWorkflowStep || isUpdatingWorkflow;
    const isValidWorkflowStep = () => {
      if (query?.status === systemConstants.project.queries.status.draft) {
        return false;
      }
      return true;
    };
    return (
      <QueryActions
        queryType={query?.queryType}
        queryId={query.id}
        queryFlag={query?.flag}
        queryConfig={smartFormConfig}
        queryStatus={query?.status}
        workFlowSteps={isValidWorkflowStep() ? workFlowSteps : []}
        disableWorkflowAction={disableWorkflowAction}
        isUserRequestor={query?.isUserOnRequestorTeam}
        requestHandlers={requestHandlers}
      />
    );
  }, [
    closeRequest,
    reopenRequest,
    query?.queryType,
    query?.id,
    query?.flag,
    query?.status,
    query?.isUserOnRequestorTeam,
    smartFormConfig,
    workFlowSteps,
    handleOpenModal,
    navigate,
    membershipCheckThenHandleUpdateWorkflow,
    isFetchingWorkflowStep,
    isUpdatingWorkflow
  ]);

  const smartFormPageContent = useMemo(
    () =>
      authUser.id && (
        <>
          {isClosed({ status: query?.status }) && (
            <InfoMessageBox
              iconProps={{
                name: "check_circle",
                fillStyle: IconFillStyle.FILLED,
                color: IconColor.SUCCESS
              }}
              message={t(
                "requests:requests.ui.smartForm.errors.closedSoChangesCannotBeMade"
              )}
            />
          )}
          {isError({ status: query?.status }) && (
            <InfoMessageBox
              iconProps={{
                name: "error",
                fillStyle: IconFillStyle.FILLED,
                color: IconColor.ERROR
              }}
              message={t(
                "requests:requests.ui.smartForm.errors.errorSoChangesCannotBeMade"
              )}
            />
          )}
          <SmartForm
            queryId={query?.id}
            smartFormConfig={smartFormConfig}
            pendingFlag={pendingFlag}
            hostObject={hostObject}
            isClosed={isClosed({ status: query?.status })}
            questions={smartFormConfig?.questionList}
            conditionals={smartFormConfig?.conditionals}
            answers={answers}
            setIsOTUpdate={setIsOTUpdate}
            isOTUpdate={isOTUpdate}
            relevantEntitiesById={relevantEntitiesById}
            refetchQuery={refetchQuery}
            handlers={{
              [smartFormActions.OPEN_COMMENT_MODAL]: question => {
                handleOpenModal(contentKeys.COMMENT, question);
              },
              [smartFormActions.OPEN_ENTITIES_MODAL]: question => {
                handleOpenModal("answerPerEntity", question);
              },
              [smartFormActions.FILL_WEBSHEET]: question => {
                handleOpenModal("websheet", question);
              },
              [smartFormActions.SAVE_ANSWERS]: handleSaveAnswers
            }}
            disableAnswerSubmissions={
              isClosed({ status: query?.status }) ||
              isError({ status: query?.status })
            }
          />
        </>
      ),
    [
      answers,
      authUser.id,
      handleOpenModal,
      handleSaveAnswers,
      hostObject,
      isOTUpdate,
      pendingFlag,
      query?.id,
      query?.status,
      refetchQuery,
      relevantEntitiesById,
      smartFormConfig,
      t
    ]
  );

  const milestoneContent = () => {
    if (!currentProject?.configuration?.milestones?.labels?.enabled) {
      return "";
    }
    const projectMilestones = currentProject.milestones;
    return projectMilestones?.find(m => m.id === query?.milestone)?.name ?? "";
  };

  const smartFormRequestDetails = () => {
    return (
      <>
        <RequestDetailsBox
          disabled={
            !isAllowedToUpdateProperties({
              aitConfig: smartFormConfig,
              query: query,
              user: authUser
            }) || query.status === statusTypes.closed
          }
          requestQuery={query}
          queryConfig={smartFormConfig}
          showReminderDate={showReminderDate}
          hideRequiredByField={
            currentProject?.configuration?.requests?.hideRequiredByField ??
            false
          }
          updateQueryField={handleUpdateQueryField}
          canEditLabels={authUser?.isHostUser}
          onClickEditLabels={() => {
            handleOpenModal(contentKeys.EDIT_TAGS);
          }}
          onClickEditCopiedTo={() => {
            handleOpenModal(contentKeys.COPIED_TO);
          }}
          title={getActionItemTypeDisplayName(
            smartFormConfig.useCustomName,
            smartFormConfig.queryName,
            t("requests:requests.configured.shortName", {
              context: smartFormConfig.key
            })
          )}
          pillConfig={pillConfig}
          milestoneContent={milestoneContent()}
          approvalRejectFlag={
            <ApproveRejectQueryFlag
              query={query}
              queryResponses={manageQueryResponses?.queryResponses?.[query?.id]}
            />
          }
          internalOnly={query.internalOnly}
        />
        <RequestEntitiesBox
          title={t(`requests:requests.configured.fields.entities.label`, {
            context: query.queryType
          })}
          boxClassName="smart-form-page__entities"
          projectEntities={currentProject?.entities ?? []}
          entityIdsToDisplay={query?.entities ?? []}
          action={<Icon name="lan" />}
        />
      </>
    );
  };

  return smartFormConfig ? (
    <PageTemplate
      header={{
        title: getSmartFormPageHeader(
          query?.description,
          smartFormConfig.useCustomName,
          smartFormConfig.queryName,
          t("requests:requests.configured.name", {
            context: smartFormConfig.key
          })
        ),
        hasTitleTooltip: true,
        actions: headerActions(),
        sticky: true
      }}
      body={{
        primary: smartFormPageContent,
        secondary: smartFormRequestDetails(),
        secondaryWidth: "20vw"
      }}
      other={{
        smallPageSize: 1200,
        error: downloadError?.message || t(updateQueryError?.data?.message),
        project: currentProject
      }}
      modal={{
        open: modalOpen,
        content: getModalContent()
      }}
      classNames={{ page: "smart-form-page" }}
    />
  ) : (
    <></>
  );
};

export default SmartFormPage;

import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import profileLogo from "@shared/assets/images/defaultUserAvatar.png";
import { systemConstants } from "@shared/constants";
import { useGetSupportedMimesQuery } from "@shared/services/documentMimesService";

import Popup from "@shared-components/popup/Popup";

import Avatar from "@components/atoms/Avatar/Avatar";
import ImageEditor from "@components/molecules/ImageEditor";

import "./userProfileImage.scss";

const UserProfileImage = props => {
  const [profileImageVisibility, setProfileImageVisibility] = useState(false);
  const { data: supportedMimes } = useGetSupportedMimesQuery({
    type: systemConstants.mimeDocumentType.image
  });

  const { t } = useTranslation();
  const { watch, trigger } = useFormContext();

  const firstname = watch("firstname", "");
  const lastname = watch("lastname", "");

  const handlePopupOutsideClick = () => {
    setProfileImageVisibility(false);
  };

  const handleProfileImageSetClick = useCallback(
    base64Image => {
      props.profileImageSetClick(base64Image);
      setProfileImageVisibility(false);
      trigger(["email", "firstname", "lastname", "role"]);
    },
    [props, trigger]
  );

  const handleProfileImageClick = () => {
    setProfileImageVisibility(true);
  };

  const handleProfileImageRemoveClick = () => {
    props.profileImageSetClick(null);
    trigger(["email", "firstname", "lastname", "role"]);
  };

  const userProfileModal = useMemo(
    () => (
      <Popup
        visibility={profileImageVisibility}
        handleOutsideClick={false}
        width="60rem"
      >
        <ImageEditor
          image={props.avatar?.image || profileLogo}
          supportedMimes={supportedMimes}
          onSet={handleProfileImageSetClick}
          onCancel={handlePopupOutsideClick}
        />
      </Popup>
    ),
    [
      handleProfileImageSetClick,
      profileImageVisibility,
      props.avatar?.image,
      supportedMimes
    ]
  );

  useEffect(
    () => props.setUserProfileModal(userProfileModal),
    [props, props.setUserProfileModal, userProfileModal]
  );

  return (
    <>
      <div className="profile-image">
        <Avatar
          size={"large"}
          user={{
            name: props.name,
            firstname,
            lastname,
            avatar: props.avatar
          }}
        />
        <div className="profile-image__config">
          <span
            className="config-label config-label--change"
            onClick={handleProfileImageClick}
          >
            {t("common:ui.logo.field.change")}
          </span>
          {props.avatar ? (
            <span
              className="config-label config-label--remove"
              onClick={handleProfileImageRemoveClick}
            >
              {t("common:ui.logo.field.remove")}
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

UserProfileImage.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    avatar: PropTypes.shape({
      image: PropTypes.string
    })
  }),
  profileImageSetClick: PropTypes.func,
  setUserProfileModal: PropTypes.func
};

export default UserProfileImage;

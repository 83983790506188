import { useMemo } from "react";

import { useGetProjectMembersQuery } from "@shared/services/projectMemberService";

export const useProjectAvatarUser = (projectId: number) => {
  interface AvatarUserInfo {
    id: number;
    name?: string;
    firstname: string;
    lastname: string;
  }

  const { data: projectMembers, isLoading: isLoadingProjectMembers } =
    useGetProjectMembersQuery(projectId, {
      skip: !projectId
    });

  const getAvatarUser = useMemo(
    () => (user: AvatarUserInfo) => {
      const formattedUser = (avatarUser: AvatarUserInfo) => {
        if (!avatarUser.name) {
          return {
            ...avatarUser,
            name: `${avatarUser.firstname} ${avatarUser.lastname}`
          };
        }
        return avatarUser;
      };

      if (isLoadingProjectMembers) {
        return formattedUser(user);
      }
      const allMembers = [
        ...(projectMembers?.clientUsers ?? []),
        ...(projectMembers?.hostUsers ?? [])
      ];
      const member = allMembers?.find(member => member.id === user.id);
      return member ? formattedUser(member) : formattedUser(user);
    },
    [
      isLoadingProjectMembers,
      projectMembers?.clientUsers,
      projectMembers?.hostUsers
    ]
  );

  return { usersDataLoading: isLoadingProjectMembers, getAvatarUser };
};

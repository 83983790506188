import React, { useCallback, useMemo, useRef } from "react";

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useGetProjectActionItemsQuery } from "@shared/services/actionItemService";
import { useGetActionItemTypesQuery } from "@shared/services/actionItemTypesService";

import ions from "@ions";

import {
  QUERY_TYPE_ICON // formatQueryType,
} from "@app/helpers";
import { useDataTable } from "@app/hooks";

import { Inline } from "@fermions";

import { Icon, IconColor } from "@atoms/Icon";
import { Pill, PillSize } from "@atoms/Pill";

import DataTable from "@components/molecules/DataTable";
import Loading from "@components/molecules/Loading";

const CopyActionItemsTable = props => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { project, onSelectionChanged, targetProject, viewScope } = props;
  const { data: actionItems, isLoading: loadingActionItems } =
    useGetProjectActionItemsQuery(
      {
        projectId: project?.id,
        copyable: true,
        copyFrom: viewScope,
        targetProjectId: targetProject?.id
      },
      { skip: !project?.id, refetchOnMountOrArgChange: true }
    );
  const { data: actionItemTypes, isLoading: loadingActionItemTypes } =
    useGetActionItemTypesQuery(
      {
        engagementTypeId: targetProject?.engagement?.engagementTypeId,
        projectId: targetProject?.id
      },
      { skip: !targetProject?.engagement?.engagementTypeId }
    );

  const {
    createColumn,
    caseInsensitiveSortType,
    createColumnForDataTableCheckbox
  } = useDataTable(actionItems);

  const tableRef = useRef();
  const QUERY_TYPE_ICON_MAP = useMemo(
    () =>
      actionItemTypes?.reduce(
        (acc, { configuration: qt }) => ({
          ...acc,
          [qt.key]: QUERY_TYPE_ICON[qt.type]
        }),
        {}
      ) ?? {},
    [actionItemTypes]
  );

  const queryTypeCol = useCallback(
    ({ internalOnly, queryType }) => {
      return (
        <Inline gap="025">
          {QUERY_TYPE_ICON_MAP[queryType]}
          {internalOnly && (
            <Pill
              size={PillSize.SMALL}
              variant={ions.components?.atoms?.draft_pill?.pill_variant}
              fillStyle={ions.components?.atoms?.draft_pill?.pill_fill_style}
              label={t("requests:requests.internalOnly.label")}
            />
          )}
        </Inline>
      );
    },
    [t, QUERY_TYPE_ICON_MAP]
  );

  const data = useMemo(
    () =>
      actionItems?.map(
        ({ id, description, queryType, refNo, internalOnly }) => ({
          id,
          description,
          refNo,
          queryTypeIcon: queryTypeCol({ queryType, internalOnly }),
          internalOnly
        })
      ),
    [actionItems, queryTypeCol]
  );
  const columns = useMemo(() => {
    return [
      createColumnForDataTableCheckbox({
        accessor: "selectActionItem",
        width: 10
      }),
      createColumn({
        Header: t("requests:requests.refLabel"),
        accessor: "refNo",
        className: "ref",
        maxWidth: 50,
        fixedWidth: true,
        disableSortBy: false
      }),
      createColumn({
        Header: t("requests:requests.descriptionLabel"),
        accessor: "description",
        minWidth: 150,
        disableSortBy: false,
        sortType: caseInsensitiveSortType
      }),
      createColumn({
        Header: t("requests:requests.typeLabel"),
        accessor: "queryType",
        className: "query-type",
        width: 40,
        Cell: ({ cell }) =>
          cell.row.original.queryTypeIcon ? (
            <Icon
              color={IconColor.MUTED}
              name={cell.row.original.queryTypeIcon}
            />
          ) : (
            <></>
          )
      })
    ].filter(c => c);
  }, [
    createColumn,
    t,
    caseInsensitiveSortType,
    createColumnForDataTableCheckbox
  ]);

  const handleRowSelection = useCallback(
    selectedRows => {
      setValue("selectedActionItemsCount", selectedRows?.length ?? 0);
      onSelectionChanged?.(selectedRows);
    },
    [onSelectionChanged, setValue]
  );

  const loadingData = () => loadingActionItemTypes || loadingActionItems;

  const getActionItemsTable = () => {
    return actionItems?.length > 0 ? (
      <DataTable
        ref={tableRef}
        selectedRowsChangeHandler={handleRowSelection}
        className="copy-action-items-table"
        columns={columns}
        data={data}
      ></DataTable>
    ) : (
      <span>{t("requests:requests.ui.copyRequests.noRequestsToCopy")}</span>
    );
  };

  return <>{loadingData() ? <Loading /> : getActionItemsTable()}</>;
};

CopyActionItemsTable.defaultProps = {};

CopyActionItemsTable.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string
  }),
  onSelectionChanged: PropTypes.func,
  targetProject: PropTypes.shape({
    id: PropTypes.number.isRequired
  })
};

export default CopyActionItemsTable;

import React, { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import websheetUtilities from "@shared/helpers/websheetUtilities";

import { useCallbackRef } from "@app/hooks/useCallbackRef";

import { Text } from "@atoms/Text";

import ColumnSelectionWizard from "./ColumnSelectionWizard";

/**
 * @typedef {object} TemplateHeader
 * @property {string} name
 * @property {"string"|"number"} type
 * @property {boolean} required
 * @property {Array<string>} [alternativeNames]
 */

/**
 * @param {object} options
 * @param {Array<TemplateHeader>} options.templateHeaders
 * @returns
 */
export function useColumnSelectionWizard({ templateHeaders }) {
  const { t } = useTranslation();
  const [dropdownHeader, setDropdownHeader] = useState([]);
  const { ref, callbackRef } = useCallbackRef();

  const getStepDescription = useCallback(
    totalNumberOfSheets => {
      const renderRemainingHeaders = () => {
        const headers = templateHeaders
          .filter(d => !dropdownHeader.some(name => name === d.name))
          .map(th => websheetUtilities.getHeaderNameForSelection(th, t));
        const headersString = headers.length ? headers.join(", ") : "-";
        return <Text text={headersString} />;
      };
      const descriptionText = t(
        `${
          totalNumberOfSheets > 1
            ? "common:ui.websheet.actions.cleaningWizard.step.selectColumns.description.multiSheets"
            : "common:ui.websheet.actions.cleaningWizard.step.selectColumns.description.singleSheet"
        }`
      );

      return (
        <>
          <div>
            <Text text={descriptionText} />
          </div>
          <div className="wizard-websheet__remaining-headers">
            <Text
              text={t(
                "common:ui.websheet.actions.cleaningWizard.step.selectColumns.remaining"
              )}
            />
            {renderRemainingHeaders()}
          </div>
        </>
      );
    },
    [dropdownHeader, t, templateHeaders]
  );

  const getWizard = useCallback(
    commonWizardProps => {
      return (
        <ColumnSelectionWizard
          {...commonWizardProps}
          ref={callbackRef}
          templateHeaders={templateHeaders}
          setDropdownHeader={setDropdownHeader}
        />
      );
    },
    [callbackRef, templateHeaders]
  );

  const getModal = useCallback(() => {
    return ref?.getModal();
  }, [ref]);

  const stepProperties = useMemo(() => {
    return {
      isMandatory: true
    };
  }, []);

  const shouldEnterStep = useCallback(() => true, []);

  const canSubmit = useCallback(() => {
    return ref?.canSubmit();
  }, [ref]);

  const getDataForSubmission = useCallback(() => {
    return ref?.getDataForSubmission();
  }, [ref]);

  const cleanup = useCallback(() => {
    ref?.cleanup();
  }, [ref]);

  return {
    getStepDescription,
    getWizard,
    stepProperties,
    shouldEnterStep,
    getModal,
    canSubmit,
    getDataForSubmission,
    cleanup
  };
}

import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";

import { ContainerWithTooltip } from "@atoms/ContainerWithTooltip";

import "./Avatar.scss";

type AvatarSize = "x-small" | "small" | "medium" | "large";

type AvatarUser = {
  name: string;
  firstname: string;
  lastname: string;
  avatar: {
    image: string;
  };
};

type AnonymousUser = Pick<AvatarUser, "avatar"> & {
  isAnonymous: true;
};

type AvatarProps = {
  size?: AvatarSize;
  user: AvatarUser | AnonymousUser;
  className?: string;
  showHover?: boolean;
};

const Avatar = (props: AvatarProps) => {
  const { user, size, showHover, className } = props;
  const { t } = useTranslation();

  const getAvatarColorCode = (name: string) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash % 5) + 1;
  };

  const getUserName = useCallback(() => {
    const isAnonymous = (user as AnonymousUser)?.isAnonymous;
    if (isAnonymous) {
      return t("common:anonymousUser.name");
    }
    const avatarUser = user as AvatarUser;
    return `${avatarUser.firstname} ${avatarUser.lastname}`;
  }, [t, user]);

  const renderDefaultAvatar = useCallback(() => {
    const name = getUserName();
    const initials = name
      .split(" ")
      .map(n => n?.charAt(0) ?? "")
      .join("");

    const anonymousClassname = (user as AnonymousUser)?.isAnonymous
      ? "default-avatar--anonymous"
      : "";
    return (
      <div
        className={`default-avatar default-avatar--${size} default-avatar--variant-${getAvatarColorCode(name)} ${anonymousClassname}`}
        title={`Avatar of ${name}`}
      >
        {initials}
      </div>
    );
  }, [getUserName, size, user]);

  const renderImageAvatar = useCallback(() => {
    return (
      <img
        src={user.avatar.image}
        alt={`Avatar of ${getUserName()}`}
        className={`ot-avatar__image ot-avatar__image--${size}`}
      />
    );
  }, [getUserName, size, user?.avatar?.image]);

  return (
    <ContainerWithTooltip
      className={`ot-avatar ${className}`}
      tooltipContent={`${getUserName()}`}
      hideTooltip={!showHover}
    >
      {user.avatar?.image ? renderImageAvatar() : renderDefaultAvatar()}
    </ContainerWithTooltip>
  );
};

Avatar.defaultProps = {
  size: "small",
  showHover: false
};

export default Avatar;

import { invert, isNil, partition, range } from "lodash";

import websheetUtilities from "../websheetUtilities";
import rowHeaderSuggestion from "./suggestions/rowHeader";
import templateHeaderSuggestion from "./suggestions/templateHeader";
import unnecessaryRowsSuggestion from "./suggestions/unnecessaryRows";
import websheetWizardValidators from "./websheetWizardValidators";

const MAX_ROWS_TO_SEARCH = 20;

const SPLIT_ITEM_NUM_PER_SCREEN = 6;
const MAX_PREVIEW_SPLIT_ITEM_NUM = 20;

const FIXED_COLUMN_WIDTH = 280;

/**
 * @typedef {Object} WizardWebsheetWithTransforms
 * @property {Object} websheet - New Websheet object
 * @property {Object} [transforms]
 * @property {Object} [transforms.rows] Array corresponding to each row returned and its original rowIndex BEFORE the operation took place
 * @property {Object} [transforms.columns] Array corresponding to each column returned and its original columnIndex BEFORE the operation took place
 */

const getColWidthsForWizard = data => {
  const widths = websheetUtilities.getColWidths(data) ?? [];
  return widths.map(w => Math.max(w, FIXED_COLUMN_WIDTH));
};

/**
 * Removes from the sheet the data referenced by the column indexes
 * @param {*} sheetData Sheet containing the data to operate on
 * @param {*} columnsToRemove Index of columns to remove
 * @returns New SheetData object with the data removed
 */
const removeColumnsFromSheet = (sheetData, columnsToRemove) => {
  const pickColumns = (_, cIdx) => !columnsToRemove.includes(cIdx);
  const pickColumnsFromRows = row => row.filter(pickColumns);

  const newData = structuredClone({
    ...sheetData,
    columns: sheetData.columns.filter(pickColumns),
    data: sheetData.data.map(pickColumnsFromRows),
    formats: sheetData.formats.map(pickColumnsFromRows)
  });
  if (newData?.dataValidations?.formattedValidations) {
    newData.dataValidations.formattedValidations =
      newData.dataValidations.formattedValidations.map(pickColumnsFromRows);
  }

  return newData;
};

/**
 * Inserts data from another sheet at the specified columnIndex.
 * @param {*} sheetData Sheet containing the data to operate on.
 * @param {*} columnIndex Column index to insert the data at (starting from 0)
 * @param {*} sheetDataToInsert SheetData that will be inserted from the `columnIndex`
 * @returns New SheetData object with the data inserted
 */
const insertColumnsToSheet = (sheetData, columnIndex, sheetDataToInsert) => {
  const resultData = structuredClone(sheetData);
  const insertIntoRow = (insertIdx, dataToInsert) => (row, rowIdx) => {
    const toInsert = dataToInsert[rowIdx] ?? [];
    row.splice(insertIdx, 0, ...toInsert);
  };

  const columns = sheetDataToInsert.columns;
  resultData.columns.splice(columnIndex, 0, ...(columns ?? []));
  resultData.data.forEach(insertIntoRow(columnIndex, sheetDataToInsert.data));
  resultData.formats.forEach(
    insertIntoRow(columnIndex, sheetDataToInsert.formats)
  );
  if (
    resultData.dataValidations?.formattedValidations &&
    sheetDataToInsert.dataValidations?.formattedValidations
  ) {
    resultData.dataValidations.formattedValidations.forEach(
      insertIntoRow(
        columnIndex,
        sheetDataToInsert.dataValidations.formattedValidations
      )
    );
  }

  return resultData;
};

/**
 * Sorts headers for cleansing wizard by column index, with undefined
 * index at the end of list (undefined is not from original websheet - isBlankSelection)
 * @param {*} a Header
 * @param {*} b Header
 * @returns sorted headers, with undefined at the end
 */
const headerSortComparator = (a, b) => {
  if (a.columnIndex !== undefined && b.columnIndex !== undefined) {
    return a.columnIndex - b.columnIndex;
  }
  if (a.columnIndex === undefined && b.columnIndex === undefined) {
    return 0;
  }
  return a.columnIndex === undefined ? 1 : -1;
};

/**
 * Inserts blank column to the end of the sheet with a header
 * @param {*} sheet Sheet containing the data to operate on.
 * @param {*} header Header for the blank column
 * @returns New SheetData object with the data inserted
 */
const insertBlankColumnToSheet = (sheet, header) => {
  const newData = {
    columns: [{ width: 20 }],
    data: [[header]],
    formats: [[{}]],
    dataValidations: {
      formattedValidations: [[{}]]
    }
  };
  // After column header - insert blank item to each row
  range(sheet.rows?.length).forEach(() => {
    newData.data.push([null]);
    newData.formats.push([{}]);
    newData.dataValidations.formattedValidations.push([{}]);
  });

  const newSheet = insertColumnsToSheet(
    sheet,
    sheet.columns?.length ?? 0,
    newData
  );
  return newSheet;
};

/**
 * Inserts blank columns to the end of the sheet
 * @param {*} sheet Sheet containing the data to operate on.
 * @param {*} options.blankSelectionHeaders the headers to add
 * @returns New SheetData object with the data inserted
 */
const insertBlankColumnsToSheet = (sheet, { blankSelectionHeaders }) => {
  let updatedSheet = sheet;
  blankSelectionHeaders.forEach(header => {
    updatedSheet = insertBlankColumnToSheet(updatedSheet, header.name);
  });
  return updatedSheet;
};

/**
 * Inserts data from another sheet at the specified rowIndex.
 * @param {*} sheetData Sheet containing the data to operate on.
 * @param {*} rowIndex Row index to insert the data at (starting from 0)
 * @param {*} sheetDataToInsert SheetData that will be inserted at the 'rowIndex'
 * @returns New SheetData object with the data inserted
 */
const insertRowsToSheet = (
  sheetData,
  rowIndex,
  sheetDataToInsert,
  { includeReverseTransforms = false } = {}
) => {
  const resultData = structuredClone(sheetData);

  resultData.data.splice(rowIndex, 0, ...sheetDataToInsert.data);
  resultData.formats.splice(rowIndex, 0, ...sheetDataToInsert.formats);
  resultData.rows.splice(rowIndex, 0, ...(sheetDataToInsert.rows ?? []));
  if (
    resultData.dataValidations?.formattedValidations &&
    sheetDataToInsert.dataValidations?.formattedValidations
  ) {
    resultData.dataValidations.formattedValidations.splice(
      rowIndex,
      0,
      ...sheetDataToInsert.dataValidations.formattedValidations
    );
  }

  if (includeReverseTransforms) {
    const rowTransforms = resultData.data.map((_, rowIdx) => {
      if (rowIdx >= rowIndex + 1) {
        return rowIdx - 1;
      }
      return null;
    });
    return {
      sheetData: resultData,
      transforms: {
        rows: rowTransforms
      }
    };
  }

  return resultData;
};

/**
 * @typedef SheetData
 * @property {string} sheet
 * @property {Array<Array<string>>} data
 * @property {Array<Array<object>>} formats
 * @property {Array<object>} columns
 * @property {Object} dataValidations
 * @property {Array<object>} dataValidations.formattedValidations
 */

/**
 * Filters the data in the sheet, returning only data from the columns provided
 * @param {SheetData} sheetData Sheet containing the data to operate on
 * @param {Array<number>} columnsToSelect Index of columns to filter
 * @returns {SheetData} New SheetData object with the data filtered
 */
const filterSheetDataByColumns = (sheetData, columnsToSelect) => {
  const pickColumns = (_, cIdx) => columnsToSelect.includes(cIdx);
  const pickColumnsFromRows = row => row.filter(pickColumns);

  const newData = structuredClone({
    ...sheetData,
    columns: sheetData.columns.filter(pickColumns),
    data: sheetData.data.map(pickColumnsFromRows),
    formats: sheetData.formats.map(pickColumnsFromRows)
  });
  if (newData?.dataValidations?.formattedValidations) {
    newData.dataValidations.formattedValidations =
      newData.dataValidations.formattedValidations.map(pickColumnsFromRows);
  }

  return newData;
};

// NB: change this to return a websheet
const dataSubsetBySheetAndColumns = (
  websheetData,
  sheetName,
  columnsToSelect
) => {
  const sheetData = websheetData.find(
    sheetData => sheetData.sheet === sheetName
  );
  if (!sheetData) {
    return {};
  }

  return filterSheetDataByColumns(sheetData, columnsToSelect);
};

const filterAndRecordOriginalPositions = (dataToFilter, predicate) => {
  const transforms = {};
  const wrapWithRowIdx = (row, rowIdx) => ({ row, rowIdx });
  const unwrapRowIdx = ({ row }) => row;
  const recordTransforms = (row, currentRowIdx) => {
    const { rowIdx: originalRowIdx } = row;
    transforms[currentRowIdx] = originalRowIdx;
    return row;
  };

  const filteredData = dataToFilter
    .map(wrapWithRowIdx)
    .filter(predicate)
    .map(recordTransforms)
    .map(unwrapRowIdx);

  const asArray = Object.keys(transforms)
    .sort((a, b) => +a - +b)
    .map(currentRowIdx => transforms[currentRowIdx]);

  return {
    filteredData,
    originalPositions: asArray
  };
};

/**
 * Removes the selected rows from the sheet. No special consideration is taken for Header Rows
 * @param {*} websheetData Websheet data structure
 * @param {object} options additional options
 * @param {*} options.sheetName Name of sheet containing the data to operate on
 * @param {*} options.rowsToDelete Indexes of rows to remove (indexed from 0)
 * @param {*} [options.includeReverseTransforms] return type changes to {websheet, transforms} where transforms include a mapping of newRowIdx to oldRowIdx
 * @returns WizardWebsheetWithTransforms where websheet is a New Websheet object with the rows removed from the specified sheet.
 * NB: If the SheetName cannot be found the original websheet is returned as-is
 */
const removeRowsFromSheet = (
  websheetData,
  { sheetName, rowsToDelete = [], includeReverseTransforms }
) => {
  const sheetIdx = websheetData.findIndex(
    sheetData => sheetData.sheet === sheetName
  );
  if (sheetIdx === -1) {
    return {
      websheet: websheetData
    };
  }

  const rowsToDeleteLookup = new Set(rowsToDelete);
  const result = structuredClone(websheetData);
  const sheetData = result[sheetIdx];

  const excludeRowsToDelete = (_, rowIdx) => !rowsToDeleteLookup.has(rowIdx);

  const { filteredData, transforms } = (function filterData() {
    if (includeReverseTransforms) {
      const { filteredData, originalPositions } =
        filterAndRecordOriginalPositions(sheetData.data, excludeRowsToDelete);

      return {
        filteredData,
        transforms: {
          rows: originalPositions
        }
      };
    }

    return {
      filteredData: sheetData.data.filter(excludeRowsToDelete),
      transforms: null
    };
  })();

  result[sheetIdx] = {
    ...sheetData,
    data: filteredData,
    formats: sheetData.formats.filter(excludeRowsToDelete),
    rows: sheetData.rows?.filter(excludeRowsToDelete)
  };

  if (sheetData.dataValidations?.formattedValidations) {
    result[sheetIdx].dataValidations = {
      ...sheetData.dataValidations,
      formattedValidations:
        sheetData.dataValidations.formattedValidations?.filter(
          excludeRowsToDelete
        )
    };
  }

  if (includeReverseTransforms) {
    return {
      websheet: result,
      transforms
    };
  }

  return {
    websheet: result
  };
};

const unMergeCells = websheetData => {
  websheetData?.forEach(sheet => {
    Object.values(sheet.merges).forEach(({ model }) => {
      //sheet.merges index starts from 1, sheet.data index starts at 0, this aligns it
      const top = model.top - 1;
      const left = model.left - 1;
      for (let i = top; i < model.bottom; i++) {
        for (let j = left; j < model.right; j++) {
          //we want top-left value to stay
          if (i !== top || j !== left) {
            sheet.data[i]?.[j] && (sheet.data[i][j] = null);
          }
        }
      }
    });
    sheet.merges = {};
  });
};

const prepareDataForCleaning = (websheetData, preCleanFormData) => {
  const calculatedWebsheetData =
    websheetUtilities.getCalculatedSheets(websheetData);
  unMergeCells(calculatedWebsheetData);
  if (preCleanFormData?.isEntityInSeparateTabs === "Yes") {
    const processedData = updateSheetDataWithEntities(
      calculatedWebsheetData,
      preCleanFormData
    );
    return processedData;
  }
  return calculatedWebsheetData;
};

/**
 * @param {*} data Column data to operate on
 * @param {*} delimiter Delimitating character to split on
 * @returns New column data array with the item splitted against the delimiter (or null if no cells contain the delimiter)
 */
const getSplittedColumnData = (data, delimiter) => {
  //split values should retain its original type
  const convertToOriginalType = value => {
    if (value === "" || value === null) {
      return null;
    }
    return !isNaN(value) ? +value : value;
  };

  // converts [[d], ...] => [[l, r], ...]
  return data.map(([cellData]) => {
    const idx = cellData.indexOf(delimiter);
    if (idx === -1) {
      return [cellData, null].map(convertToOriginalType);
    }

    const [lhs, ...rhs] = cellData.split(delimiter);
    const left = lhs.trimEnd();
    const remainder = rhs?.join(delimiter)?.trimStart() ?? null;
    return [left, remainder].map(convertToOriginalType);
  });
};

/**
 * @param {*} data Data array used for previewing split result
 * @param {*} itemNumPerScreen Number of data items for previewing in a popup window
 * @returns Padded data array to fill the table in fixed height
 */
const padSplittedPreviewData = (data, itemNumPerScreen) => {
  const dataLength = data?.length ?? 0;
  if (dataLength >= itemNumPerScreen) {
    return data;
  }
  const paddingLength = itemNumPerScreen - dataLength;
  return data.concat(new Array(paddingLength).map(() => ({})));
};

/**
 * @param {*} sheetData Sheet containing the data to operate on
 * @param {*} columnIndex Column to apply the splitting on
 * @param {*} delimiter Delimitating character to split on
 * @param {object} [options]
 * @param {object} [options.includeReverseTransforms] if TRUE changed return type to { sheetData: , transforms: } to include column transformations
 * @returns New SheetData object with the column split against the delimiter (or null if no cells contain the delimiter)
 */
const splitColumnByDelimiter = (
  sheetData,
  columnIndex,
  delimiter,
  { includeReverseTransforms } = {}
) => {
  const relevantData = filterSheetDataByColumns(sheetData, [columnIndex]);
  relevantData.data = relevantData.data.map(([cellData]) => [
    (cellData ?? "").toString()
  ]);
  const hasNoDelimiterInEveryCell = relevantData.data.every(
    ([cellData]) => cellData.indexOf(delimiter) === -1
  );
  if (hasNoDelimiterInEveryCell) {
    return null;
  }
  const newData = getSplittedColumnData(relevantData.data, delimiter);

  // NB: structuredClone done here so the references are independent of each other
  const newColumns = [
    relevantData.columns,
    structuredClone(relevantData.columns)
  ].flat();
  const newFormats = relevantData.formats.map(([row]) => [
    row,
    structuredClone(row)
  ]);
  const newDataValidations = relevantData.dataValidations;
  if (newDataValidations?.formattedValidations) {
    newDataValidations.formattedValidations =
      relevantData.dataValidations.formattedValidations.map(([row]) => [
        row,
        structuredClone(row)
      ]);
  }

  // now need to insert it into the sheet
  const sheetWithColumnRemoved = removeColumnsFromSheet(sheetData, [
    columnIndex
  ]);

  const resultSheetData = insertColumnsToSheet(
    sheetWithColumnRemoved,
    columnIndex,
    {
      columns: newColumns,
      data: newData,
      formats: newFormats,
      dataValidations: newDataValidations
    }
  );

  if (includeReverseTransforms) {
    // this is simplistic; everything before columnIdx is 0, everything after +1
    // the new column also gets references the columnIndex so in this case two columns reference back to one
    const columnTransforms = resultSheetData.columns.map((_, colIdx) => {
      if (colIdx < columnIndex) {
        return colIdx;
      }
      if (colIdx <= columnIndex + 1) {
        return columnIndex;
      }
      return colIdx - 1;
    });

    return {
      sheetData: resultSheetData,
      transforms: {
        columns: columnTransforms
      }
    };
  }

  return resultSheetData;
};

const TEMPLATE_HEADER_FIELD_NAME_REGEX =
  /sheet-(?<sheetNameIndex>\d*)_col-(?<columnIndex>\d*)/;

const sheetAndColumnToTemplateHeaderFieldName = (sheetNameIndex, columnIndex) =>
  `sheet-${sheetNameIndex}_col-${columnIndex}`;

const sheetAndColumnFromTemplateHeaderFieldName = fieldName => {
  const {
    groups: { columnIndex, sheetNameIndex }
  } = TEMPLATE_HEADER_FIELD_NAME_REGEX.exec(fieldName);
  if (!sheetNameIndex || !columnIndex) {
    return null;
  }
  return { sheetNameIndex, columnIndex };
};

/**
 * @param {Array<object>} sheetsData array of original sheet data
 * @param {object} formsData object of submitted form data
 * @returns New array of sheet data object which filters non-selected tabs and rename sheet name to assigned entity
 */
const updateSheetDataWithEntities = (sheetsData, formsData) => {
  const invertedFormsData = Object.entries(formsData).reduce(
    (acc, [key, value]) => {
      if (value && typeof value === "object" && value.name) {
        acc[value.name] = key;
      }
      return acc;
    },
    {}
  );

  return sheetsData
    .filter(data => !isNil(invertedFormsData[data.sheet]))
    .map(data => ({
      ...data,
      sheet: invertedFormsData[data.sheet]
    }));
};

const insertOneInFormFields = (fields, currentSheetIdx, splitColumnIdx) => {
  let previousColumnIdx = null;
  let isNullGap = false;
  return Object.entries(fields).reduce((acc, [key, value], index, array) => {
    const { sheetNameIndex, columnIndex } =
      sheetAndColumnFromTemplateHeaderFieldName(key);
    const sheetIdx = +sheetNameIndex;
    const colIdx = +columnIndex;

    //shouldnt modify field values from other sheet
    if (sheetIdx !== currentSheetIdx) {
      acc[key] = value;
      return acc;
    }

    //adds a field after the split index
    if (colIdx === splitColumnIdx) {
      const newKey = sheetAndColumnToTemplateHeaderFieldName(
        sheetIdx,
        colIdx + 1
      );
      acc[newKey] = null;
    }
    if (
      (previousColumnIdx && colIdx - previousColumnIdx >= 2) ||
      !previousColumnIdx
    ) {
      isNullGap = true;
    } else {
      isNullGap = false;
    }

    //if field values after the split index should be pushed ahead by 1
    const targetCol = colIdx > splitColumnIdx ? colIdx + 1 : colIdx;

    // if there is a null head between the split column and the current column, empty the old field
    if (isNullGap && colIdx - splitColumnIdx >= 1) {
      const newKey = sheetAndColumnToTemplateHeaderFieldName(
        sheetIdx,
        columnIndex
      );
      acc[newKey] = null;
    }

    const newKey = sheetAndColumnToTemplateHeaderFieldName(sheetIdx, targetCol);
    acc[newKey] = value ?? null;
    previousColumnIdx = colIdx;
    const isLastElement = index === array.length - 1;

    if (isLastElement) {
      previousColumnIdx = null;
    }
    return acc;
  }, {});
};

/**
 * @typedef {Object} ValidatorResult
 * @property {import("./websheetWizardValidators").ValidationConfig} config
 * @property {boolean} result TRUE if the validator succeed, FALSE otherwise
 * @property {Array<import("./websheetWizardValidators").ValidationError>} [errors] If result is FALSE, this will be present with an entry for error information
 */

/**
 * @typedef {Object} WizardValidationResult
 * @property {boolean} isValid - Returns TRUE if all validation succeeds, FALSE otherwise
 * @property {Array<ValidatorResult>} validations
 */

/**
 * @param {*} webSheetData data after applying cleaning wizard before final step
 * @param {Array<import("./websheetWizardValidators").ValidationConfig>} validationConfigs Array of validations to be check against columns
 * @returns {WizardValidationResult}
 */
const validateWebsheet = (webSheetData, validationConfigs = []) => {
  const validationResults = validationConfigs
    .map(validationConfig => {
      const validatorName = validationConfig.check;
      const validator =
        websheetWizardValidators.validators[validatorName]?.(validationConfig);
      return {
        runValidation: validator,
        config: validationConfig
      };
    })
    .filter(v => v.runValidation)
    .map(({ runValidation, config }) => {
      const result = runValidation(webSheetData);
      return {
        config,
        result: result.isValid,
        errors: result.errors
      };
    });

  return {
    isValid: validationResults.every(v => v.result),
    validations: validationResults
  };
};

/**
 * creates a range of array of consecutive number from 0 to `length`, inclusive.
 *
 * @param {number} length - length of the output array.
 * @returns {number[]} array of consecutive num from 0 to `length`.
 */
function createRange(length) {
  return Array.from({ length }, (_, i) => i);
}

const getSplittedPreviewData = (currentSheet, splitColumnIndex, delimiter) => {
  const relevantData = filterSheetDataByColumns(currentSheet, [
    splitColumnIndex
  ]);
  relevantData.data = relevantData.data.map(([cellData]) => [
    (cellData ?? "").toString()
  ]);
  const result =
    getSplittedColumnData(relevantData.data, delimiter)
      ?.map(row => ({
        afterLeft: row[0],
        afterRight: row[1]
      }))
      .filter(c => !isNil(c.afterLeft) || !isNil(c.afterRight))
      .slice(0, MAX_PREVIEW_SPLIT_ITEM_NUM) ?? [];
  return padSplittedPreviewData(result, SPLIT_ITEM_NUM_PER_SCREEN);
};

const getBeforeSplittedPreviewData = (currentSheet, splitColumnIndex) => {
  const data =
    currentSheet?.data
      .map(row => ({ before: row[splitColumnIndex] }))
      .filter(c => c.before)
      .slice(0, MAX_PREVIEW_SPLIT_ITEM_NUM) ?? [];
  return padSplittedPreviewData(data, SPLIT_ITEM_NUM_PER_SCREEN);
};

const getCleanupData = ({ data, currentSheetName, hotTableComponent }) => {
  const hotData = hotTableComponent.current.hotInstance.getData();
  const colHeaders = hotTableComponent.current.hotInstance.getColHeader();
  //NB this logic doesnt work well with multiple sheets
  return data.map(sheetData => {
    if (sheetData.sheet === currentSheetName) {
      return {
        ...sheetData,
        data: [colHeaders].concat(websheetUtilities.unPopulateRows(hotData)) //inserting col header to first row of data before sending it back
      };
    }
    return sheetData;
  });
};

const checkArraysEquality = (a, b) => {
  if (a.length !== b.length) {
    return false;
  }
  const aSet = new Set(a);
  return b.every(i => aSet.has(i));
};

const emptyFormatsArray = items => [items.map(() => ({}))];

const getColumnSelectionData = (
  websheetData,
  { sheetName, headersWithData }
) => {
  const columnsToSelect = headersWithData.map(a => a.columnIndex);

  const filteredData = dataSubsetBySheetAndColumns(
    websheetData,
    sheetName,
    columnsToSelect
  );

  const [blankSelectionHeaders, selectedHeaders] = partition(
    headersWithData,
    "isBlankSelection"
  );

  //adds template header to top of the row
  const sheetWithHeaders = insertRowsToSheet(filteredData, 0, {
    data: [selectedHeaders.map(h => h.name)],
    formats: emptyFormatsArray(selectedHeaders)
  });

  const sheetWithHeadersAndBlankColumns = insertBlankColumnsToSheet(
    sheetWithHeaders,
    {
      blankSelectionHeaders
    }
  );

  const updatedHeadersWithData = [...selectedHeaders, ...blankSelectionHeaders];

  return [sheetWithHeadersAndBlankColumns, updatedHeadersWithData];
};

/**
 * @param websheet full websheet data
 * @param sheetName Name of sheet to keep
 * @param rowsToRemove Indexes of rows to remove from the sheet
 * @param columnsToPreserve Indexes of columns to keep in the sheet
 * @returns WizardWebsheetWithTransforms where websheet is a New Websheet object with the rows removed from the specified sheet.
 * NB: If the SheetName cannot be found the original websheet is returned as-is
 */
function spliceWebsheet(
  websheet,
  { sheetName, rowsToRemove, columnsToPreserve }
) {
  const uniqueColumnsToPreserve = [...new Set(columnsToPreserve)];
  const uniqueRowsToRemove = [...new Set(rowsToRemove)];

  const preservedColumnsData = dataSubsetBySheetAndColumns(
    websheet,
    sheetName,
    uniqueColumnsToPreserve
  );

  if (!preservedColumnsData || Object.keys(preservedColumnsData).length === 0) {
    return {};
  }

  const websheetWithTransforms = removeRowsFromSheet([preservedColumnsData], {
    sheetName,
    rowsToDelete: uniqueRowsToRemove
  });

  return websheetWithTransforms.websheet;
}

/**
 * Searches the Websheet for a sheet matching the sheetName or the sheetIndex supplied.
 * SheetName is searched first and if no matching sheet was found, will attempt to retrieve via sheetIndex
 * @param {*} websheet
 * @param {object} options
 * @param {string} [options.sheetName] Name of the sheet to search for.
 * @param {number} [options.sheetIndex] Index of the sheet to retrieve from websheet.
 * @returns SheetData from the websheet matching either the sheetName or sheetIndex. NULL if no match possible
 */
const getSheetByNameOrIndex = (
  websheet,
  { sheetName = null, sheetIndex = null } = {}
) => {
  if (sheetName) {
    const sheetByName = websheet.find(s => s.sheet === sheetName);
    if (sheetByName) {
      return sheetByName;
    }
  }

  if (sheetIndex >= 0) {
    const sheetByIndex = websheet[sheetIndex];
    if (sheetByIndex) {
      return sheetByIndex;
    }
  }

  return null;
};

/**
 *
 * @param {object} options
 * @param {"checkbox"|"radio"} options.type
 * @param {string} options.name
 * @param {string} options.value
 * @param {boolean} [options.isChecked]
 * @param {boolean} [options.isDisabled]
 * @returns
 */
const makeSelectableRowHeader = ({
  type,
  name,
  value,
  isChecked,
  isDisabled
}) => {
  const parentDiv = document.createElement("label");
  parentDiv.className = `row-header__container`;

  const input = document.createElement("input");
  input.className = `row-header__container__input`;
  input.type = type;
  input.name = name;
  input.value = value;
  input.checked = isChecked;
  input.disabled = isDisabled;
  parentDiv.appendChild(input);

  return parentDiv;
};

/**
 * This function is to compare the entities length and the tabs length.
 * If there are less tabs than entities, then return true.
 * else return false
 * @param {Array} entities - array of entities
 * @param {Array} tabs - array of tabs
 */

const hasLessTabsThanEntities = (tabs = [], entities = []) => {
  return tabs.length < entities.length;
};

/**
 * Given a list of candidateColumns names, what is the ordering of these columns required to match the ordering specified in referenceColumns

* Notes:
 *  - If a column in the candidateSheet exists in the referenceSheet, it will be mapped to the corresponding column index in the referenceSheet 
 *  - If a column in the candidateSheet does not exist in the referenceSheet, it will be mapped to the end of the referenceSheet
 *  - If a column in the referenceSheet does not exist in the candidateSheet, it will be ignored
 *  - If there are duplicate column names in the candidateSheet, they will all be mapped to the corresponding column in the referenceSheet
 *  - If there are duplicate column names in the referenceSheet, only the first instance will be considered for mapping
 * @param {Array[]} referenceColumns Array of column names to use as the reference
 * @param {Array[]} candidateColumns Array of column names we want to map from to match the reference
 * @returns {Object} Map of column indexes from the candidateSheet to a corresponding column indexes in the referenceSheet.
 */
const getColumnMapping = (referenceColumns, candidateColumns) => {
  const mapping = {};
  const excessColumns = [];

  candidateColumns.forEach((candidateColumn, candidateIdx) => {
    const referenceIdx = referenceColumns.indexOf(candidateColumn);
    if (referenceIdx !== -1) {
      mapping[candidateIdx] = referenceIdx;
    } else {
      excessColumns.push(candidateIdx);
    }
  });

  // we have excess columns in our candidate; they should get added to the end in the order they originally appeared
  excessColumns.forEach((candidateIdx, idx) => {
    mapping[candidateIdx] = referenceColumns.length + idx;
  });

  return mapping;
};

/**
 * NB: this differs from filterSheetDataByColumns in that columns returned are in the order specified by columnsToPick
 * @param {*} sheetData Sheet containing the data to operate on
 * @param {*} columnsToPick Ordered list of column indexes to pick from the sheetData
 * @returns New SheetData object with the columns in the order specified by columnsToPick
 */
const pickColumnsFromSheetData = (sheetData, columnsToPick) => {
  const resultData = columnsToPick.reduce((acc, columnIdxToPick, idx) => {
    // special case for first as it also setups the websheet structure for the accumulator
    if (acc === null) {
      return filterSheetDataByColumns(sheetData, [columnIdxToPick]);
    }

    const sheetWithSourceColumn = filterSheetDataByColumns(sheetData, [
      columnIdxToPick
    ]);

    return insertColumnsToSheet(acc, idx, sheetWithSourceColumn);
  }, null);

  return resultData;
};

const alignColumnsAndAppendRowsToSheet = (
  sourceSheetData,
  sheetDataToAppend,
  { removeIncomingHeaders }
) => {
  // assumption that first row is where the header information is!
  const referenceColumns = sourceSheetData.data[0].map(cellValue => cellValue);
  const candidateColumns = sheetDataToAppend.data[0].map(
    cellValue => cellValue
  );

  // align columns of incoming to match existing
  const columnMappings = getColumnMapping(referenceColumns, candidateColumns);
  if (
    Object.keys(invert(columnMappings)).length !==
    Object.keys(columnMappings).length
  ) {
    throw new Error("non-unique mappings exist");
  }

  const orderedColumnIndexesToPick = Object.entries(columnMappings)
    .sort(([, targetA], [, targetB]) => targetA - targetB)
    .map(([sourceIdx]) => +sourceIdx);
  const updatedSheet = pickColumnsFromSheetData(
    sheetDataToAppend,
    orderedColumnIndexesToPick
  );

  // remove header row from incoming
  const preparedSheetData = (() => {
    if (!removeIncomingHeaders) {
      return updatedSheet;
    }

    const {
      websheet: [sheetWithHeaderRemoved]
    } = removeRowsFromSheet([updatedSheet], {
      sheetName: sheetDataToAppend.sheet,
      rowsToDelete: [0]
    });
    return sheetWithHeaderRemoved;
  })();

  // append to bottom
  const updatedSheetData = insertRowsToSheet(
    sourceSheetData,
    sourceSheetData.data.length,
    preparedSheetData
  );

  return updatedSheetData;
};

/**
 * @typedef {Object} AggregateData
 * @property {Array<SheetData>} websheet
 * @property {Object} savedState
 */

/**
 * @param {*} incomingData Websheet with one sheet of data to append to existingAggregatedData
 * @param {AggregateData} existingAggregatedData
 * @returns {AggregateData} New AggregateData object with the incomingData appended to the existingAggregatedData
 */
const prepareDataForAggregate = (incomingData, existingAggregatedData) => {
  // if there's no aggregatedData then we don't need to do anything
  if (!existingAggregatedData?.websheet) {
    return incomingData;
  }

  const aggregateWebsheet = existingAggregatedData.websheet;

  const updatedSheetData = alignColumnsAndAppendRowsToSheet(
    aggregateWebsheet[0],
    incomingData.websheet[0],
    { removeIncomingHeaders: true }
  );

  // Handle whether we're appending to existing or starting a new list
  const aggregateSheets = existingAggregatedData.savedState.aggregateSheets
    ? [...existingAggregatedData.savedState.aggregateSheets]
    : [existingAggregatedData.savedState];
  aggregateSheets.push(incomingData.savedState);

  const newSavedState = {
    aggregateSheets
  };

  return {
    websheet: [updatedSheetData],
    savedState: newSavedState
  };
};

export default {
  prepareDataForCleaning,
  getCleanupData,
  emptyFormatsArray,
  getColumnSelectionData,
  updateSheetDataWithEntities,
  createRange,
  dataSubsetBySheetAndColumns,
  forTest: {
    insertColumnsAt: insertColumnsToSheet,
    removeColumnsFromSheetData: removeColumnsFromSheet,
    filterSheetDataByColumns,
    unMergeCells,
    insertBlankColumnToSheet,
    getColumnMapping,
    pickColumnsFromSheetData
  },
  getBeforeSplittedPreviewData,
  getColWidthsForWizard,
  insertOneInFormFields,
  headerSortComparator,
  insertBlankColumnsToSheet,
  insertRowsToSheet,
  checkArraysEquality,
  MAX_ROWS_TO_SEARCH,
  removeRowsFromSheet,
  sheetAndColumnFromTemplateHeaderFieldName,
  sheetAndColumnToTemplateHeaderFieldName,
  splitColumnByDelimiter,
  getSplittedPreviewData,
  spliceWebsheet,
  suggestRowHeader: rowHeaderSuggestion.suggest,
  suggestSheetAndHeadersForWebsheet: templateHeaderSuggestion?.suggest,
  suggestUnnecessaryRows: unnecessaryRowsSuggestion.suggest,
  validateWebsheet,
  getSheetByNameOrIndex,
  makeSelectableRowHeader,
  hasLessTabsThanEntities,
  alignColumnsAndAppendRowsToSheet,
  prepareDataForAggregate
};

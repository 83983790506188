import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { authActions, manageProfileActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";

export function useUserProfile() {
  const dispatch = useDispatch();
  const manageProfile = useSelector(state => state.manageProfile);
  const authentication = useSelector(state => state.authentication);
  const [userProfile, setUserProfile] = useState({
    mobileCountryCode: systemConstants.defaultCountryCode,
    businessCountryCode: systemConstants.defaultCountryCode
  });
  const [userProfileError, setUserProfileError] = useState();
  const [requireLogout, setRequireLogout] = useState(false);
  const [userFormConfig, setUserFormConfig] = useState();
  const [loaded, setLoaded] = useState();

  useEffect(() => {
    // call hostBrandApi to get the latest host details with formConfig
    if (!authentication.host?.host?.properties?.formsConfig) {
      dispatch(authActions.getHostWithBrandDetails());
    }
  }, [authentication.host?.host?.properties?.formsConfig, dispatch]);

  useEffect(() => {
    const updateProfile = manageProfile.user;
    setUserProfile(prevUserProfile => {
      return {
        ...prevUserProfile,
        ...updateProfile
      };
    });
  }, [manageProfile.user]);

  useEffect(() => {
    setLoaded(manageProfile.loaded);
  }, [manageProfile.loaded]);

  useEffect(() => {
    if (manageProfile.requireLogout) {
      setRequireLogout(manageProfile.requireLogout);
    }
  }, [manageProfile.requireLogout]);

  useEffect(() => {
    setUserProfileError(manageProfile.error);
  }, [manageProfile.error]);

  useEffect(
    () =>
      setUserFormConfig(
        authentication.host?.host?.properties?.formsConfig?.userProfileFields ??
          []
      ),
    [authentication.host?.host?.properties?.formsConfig?.userProfileFields]
  );

  const fetchUserProfile = useCallback(
    user => {
      if (user) {
        dispatch(manageProfileActions.getUserDetails(user));
      }
    },
    [dispatch]
  );

  const updateUserProfile = useCallback(
    newUserDetails => {
      dispatch(manageProfileActions.updateUser(newUserDetails));
    },
    [dispatch]
  );

  const onChangeUserProfile = useCallback(userProfile => {
    setUserProfile(prevUserProfile => {
      return {
        ...prevUserProfile,
        ...userProfile
      };
    });
  }, []);

  return {
    submitted: loaded,
    userFormConfig,
    userProfile,
    error: userProfileError,
    fetchUserProfile,
    updateUserProfile,
    onChangeUserProfile,
    requireLogout
  };
}

import React, { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import { utilities } from "@shared/helpers/utilities";

import { ContainerWithTooltip } from "@atoms/ContainerWithTooltip";
import { Icon, IconDesignStyle, IconFillStyle } from "@atoms/Icon";

import ProgressSpinner from "@components/atoms/ProgressSpinner";

import "./FileAttachmentItem.scss";

const documentStatus = systemConstants.project.document.status;
const attachedFileStates = systemConstants.addFiles.attachedFile.state;

const FileAttachmentItem = props => {
  const { file, onClick, action } = props;
  const { t } = useTranslation();

  const [fileSize, setFileSize] = useState("");
  const [stateDetails, setStateDetails] = useState({});

  const fileUploaded = !!props.file?.status;

  const filename = useMemo(() => {
    if (!file?.name) {
      return "";
    }

    const name = file.name;
    const revisionCount = file.revisionCount ?? 1;

    if (file.status === documentStatus.archived) {
      return `${name} (${t(
        "requests:requests.ui.smartForm.modal.document.archived.label"
      )})`;
    } else if (file.status === documentStatus.deleted) {
      return `${name} (${t(
        "requests:requests.ui.smartForm.modal.document.deleted.label"
      )})`;
    } else if (file.isRevision) {
      return `${t(
        "requests:requests.ui.smartForm.modal.document.revision.label"
      )} ${revisionCount} : ${file.originalName}`;
    } else {
      return name;
    }
  }, [file, t]);

  const fileType = filename.split(".").pop();
  const hoverText =
    fileType === "xls" &&
    t("requests:requests.ui.smartForm.modal.promptMessage.xls");

  useEffect(() => {
    if (
      props.file?.status !== documentStatus.archived &&
      props.file?.status !== documentStatus.deleted &&
      props.file?.size
    ) {
      setFileSize(props.file.size);
    }
  }, [props.file?.size, props.file?.status]);

  useEffect(() => {
    if (props.file.state) {
      const stateType = props.file.state;
      switch (stateType) {
        case attachedFileStates.selected:
          setStateDetails({
            state: attachedFileStates.selected
          });
          break;
        case attachedFileStates.uploading:
          setStateDetails({
            state: attachedFileStates.uploading,
            progress: props.file.uploadProgress,
            sizeRemaining:
              fileSize && props.file.uploadProgress > 0
                ? utilities.bytesToFileSizeString(
                    (props.file.uploadProgress / 100) * fileSize
                  )
                : 0,
            size: utilities.bytesToFileSizeString(fileSize || 0)
          });
          break;
        case attachedFileStates.uploaded:
          setStateDetails({
            state: attachedFileStates.uploaded
          });
          break;
        case attachedFileStates.uploadFailed:
          setStateDetails({
            state: attachedFileStates.uploadFailed,
            error: props.file.error || ""
          });
          break;
        case attachedFileStates.attached:
          setStateDetails({
            state: attachedFileStates.attached
          });
          break;
        default:
          break;
      }
    }
  }, [fileSize, props.file, props.file.state, props.file.uploadProgress]);

  useEffect(() => {
    const error = props.file.error;
    if (stateDetails?.state === attachedFileStates.uploading && error) {
      setStateDetails({
        state: attachedFileStates.uploadFailed,
        error
      });
    }
  }, [props.file.error, stateDetails?.state]);

  const getDownloadActions = () =>
    fileUploaded ? (
      <Icon
        name={
          props?.itemType === systemConstants.fileAttachmentItemType.websheet
            ? "open_in_new"
            : "download"
        }
        designStyle={IconDesignStyle.MATERIAL_ICONS}
        fillStyle={IconFillStyle.FILLED}
        className={`file-attachment-item__download-button`}
      />
    ) : (
      ""
    );

  const getSelectedActions = () => {
    return (
      !props.disabled && (
        <Icon
          name="close"
          onClick={props.onDelete}
          designStyle={IconDesignStyle.MATERIAL_ICONS}
          className={`file-attachment-item__close-button`}
        />
      )
    );
  };
  const getFailedActions = () => (
    <>
      <Icon name="error" designStyle={IconDesignStyle.MATERIAL_ICONS} />
      <Icon
        name="close"
        onClick={props.onDelete}
        designStyle={IconDesignStyle.MATERIAL_ICONS}
        className={`file-attachment-item__close-button`}
      />
    </>
  );

  const getUploadedActions = () => (
    <Icon
      className="file-attachment-item__uploaded-icon"
      name="done"
      designStyle={IconDesignStyle.MATERIAL_ICONS}
    />
  );

  const getUploadingActions = () => {
    return (
      <span className="file-attachment-item__uploading-info">
        {stateDetails.progress && (
          <span>
            <span>{stateDetails.sizeRemaining} of </span>
            <span>{stateDetails.size}</span>
          </span>
        )}
        <span>
          <ProgressSpinner percentage={stateDetails?.progress || 0} />
        </span>
      </span>
    );
  };

  const getActions = () => {
    switch (stateDetails.state || "") {
      case attachedFileStates.selected:
        return getSelectedActions();
      case attachedFileStates.uploading:
        return getUploadingActions();
      case attachedFileStates.uploaded:
        return getUploadedActions();
      case attachedFileStates.attached:
        return getDownloadActions();
      case attachedFileStates.uploadFailed:
        return getFailedActions();
      default:
        return "";
    }
  };

  const classAndModifiers = () => {
    const result = ["file-attachment-item"];
    if (props.disabled) {
      result.push(`file-attachment-item--disabled`);
    } else {
      result.push("file-attachment-item--enabled");
    }
    result.push(`file-attachment-item--${props.size}`);
    return result.join(" ");
  };

  return filename ? (
    <>
      <span
        className={classAndModifiers()}
        onClick={file?.locked ? undefined : onClick?.(file)}
      >
        <Icon name="description" />
        <div className="file-attachment-item__filename">{filename}</div>
        <ContainerWithTooltip
          hideTooltip={!props.showIconOnlyOnHover}
          tooltipContent={hoverText}
          className={`file-attachment-item__action ${
            props.showIconOnlyOnHover
              ? "file-attachment-item__action--on-hover"
              : ""
          }`}
        >
          {getActions()}
        </ContainerWithTooltip>
      </span>
      {fileUploaded && action && !file?.locked && (
        <div className="file-attachment-item__replace-button">
          {action(file)}
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

FileAttachmentItem.defaultProps = {
  size: "small",
  disabled: false,
  showIconOnlyOnHover: false
};

FileAttachmentItem.propTypes = {
  size: PropTypes.oneOf(["small", "medium"]),
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    filePathId: PropTypes.number,
    projectId: PropTypes.number,
    size: PropTypes.number, // Bytes
    status: PropTypes.string,
    uploadProgress: PropTypes.number, //[0,100]
    state: PropTypes.string,
    originalName: PropTypes.string,
    locked: PropTypes.bool
  }).isRequired,
  disabled: PropTypes.bool,
  showIconOnlyOnHover: PropTypes.bool,
  onClick: PropTypes.func,
  replaceFile: PropTypes.func,
  action: PropTypes.func,
  itemType: PropTypes.oneOf(["document", "websheet"])
};

export default FileAttachmentItem;

import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Loading from "@molecules/Loading/Loading";

import ToggleButtonGroup from "@components/atoms/ToggleButtonGroup/ToggleButtonGroup";
import InteractiveReportChart from "@components/molecules/InteractiveReportChart";
import DashboardBoxTemplate from "@components/templates/DashboardBoxTemplate";

import "./InsightsCard.scss";
import InsightsCardTable from "./InsightsCardTable";

function InsightsCard(props) {
  const {
    id,
    title,
    table,
    graph,
    columns,
    displayFormats,
    isInsightsFetching
  } = props;
  const [toggleButtons, setToggleButtons] = useState([]);
  const { t } = useTranslation();
  const toggleTypes = {
    graph: "GRAPH",
    table: "TABLE",
    list: "LIST"
  };

  const displayTypeButtons = useMemo(
    () => ({
      [toggleTypes.graph]: {
        type: toggleTypes.graph,
        label: t(
          "interactiveReport:interactiveReport.ui.analyticsPanel.graphToggle"
        )
      },
      [toggleTypes.table]: {
        type: toggleTypes.table,
        label: t(
          "interactiveReport:interactiveReport.ui.analyticsPanel.tableToggle"
        )
      }
    }),
    [t, toggleTypes.graph, toggleTypes.table]
  );

  useEffect(() => {
    const res = displayFormats
      ?.map(d => {
        if (d?.type && displayTypeButtons[d.type]) {
          return { ...d, ...displayTypeButtons[d.type] };
        } else return null;
      })
      .filter(d => d);
    setToggleButtons(res);
  }, [displayFormats, displayTypeButtons]);

  const [selectedType, setSelectedType] = useState();

  const onClickToggle = useCallback(type => setSelectedType(type), []);

  const displayLevel = useMemo(
    () =>
      toggleButtons?.find(t => t.type === toggleTypes.table)?.displayLevel ?? 0,
    [toggleButtons, toggleTypes.table]
  );

  const getContent = () => {
    if (isInsightsFetching) {
      return <Loading message={t("common:loading")} />;
    }

    const graphWithDataType = { ...graph, dataType: "number" };
    switch (selectedType) {
      case toggleTypes.graph:
        if (!graph?.chartValues) {
          return (
            <div className="ot-insights-card__empty-placeholder">
              {t("common:insights.empty.message")}
            </div>
          );
        }
        return (
          <div className="ot-insights-card__graph">
            <InteractiveReportChart
              chart={graphWithDataType}
              fixedHeight={300}
              pieRadius={"75%"}
              enableHidden={false}
              chartMargin={{}}
              isDrilldown={true}
            />
          </div>
        );
      case toggleTypes.table:
        if (!table?.length) {
          return (
            <div className="ot-insights-card__empty-placeholder">
              {t("common:insights.empty.message")}
            </div>
          );
        }
        return (
          <div className="ot-insights-card__table">
            <InsightsCardTable
              insightsCardId={id}
              data={table}
              columns={columns}
              displayLevel={displayLevel}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  const getToggleButtonGroupClass = useMemo(() => {
    return toggleButtons?.length > 1
      ? "ot-insights-card__toggle-btn-group--visible"
      : "ot-insights-card__toggle-btn-group--hidden";
  }, [toggleButtons?.length]);

  const setHeaderHeightStyleVariable = useCallback(height => {
    document.documentElement.style.setProperty(
      "--dashboard-card-template__header-height",
      `${height}px`
    );
  }, []);

  return (
    <DashboardBoxTemplate
      title={t(title)}
      boxClassName="ot-insights-card"
      getHeaderHeight={setHeaderHeightStyleVariable}
    >
      <div className="ot-insights-card__layout">
        {toggleButtons?.length > 0 && (
          <div
            className={[
              "ot-insights-card__toggle-btn-group",
              getToggleButtonGroupClass
            ].join(" ")}
          >
            <ToggleButtonGroup
              onClick={onClickToggle}
              toggleButtons={toggleButtons}
            />
          </div>
        )}
        {getContent()}
      </div>
    </DashboardBoxTemplate>
  );
}

InsightsCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  table: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  graph: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  }),
  displayFormats: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      graphType: PropTypes.string
    })
  ),
  isInsightsFetching: PropTypes.bool
};

export default InsightsCard;
